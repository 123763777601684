import { DatePipe, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChild,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { AuthService } from '@ih/services';
import { Device } from '@twilio/voice-sdk';
import { IPeerConnection } from '@twilio/voice-sdk/es5/twilio/call';
import { BridgeAccount } from 'libs/interfaces/src/lib/bridge-account.interface';
import { map } from 'rxjs';

@Component({
  selector: 'ih-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [MatIconModule, MatButtonModule, NgClass, ReactiveFormsModule, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class HomeComponent implements OnDestroy {
  @HostBinding('class.ih-home') hostClass = true;

  @ViewChild('log') logElRef: ElementRef<HTMLDivElement>;

  private http = inject(HttpClient);
  private auth = inject(AuthService<BridgeAccount>) as AuthService<BridgeAccount>;
  private router = inject(Router);

  randomJoke = this.http
    .get<string[]>('https://inspirehubweb.blob.core.windows.net/assets/common/jokes.json')
    .pipe(map((jokes) => jokes[Math.floor(Math.random() * jokes.length)]));

  private device: Device;

  logs = signal<{ time: Date; type: 'log' | 'error' | 'warn'; message: string }[]>([]);

  incoming = signal<IPeerConnection>(false);
  registered = signal(false);
  connection = signal(null);

  clientName = signal('');

  phoneForm = new FormGroup({
    number: new FormControl<string>('', Validators.required)
  });

  constructor() {
    if (this.auth.currentUser$.value?.roleInfo?.userRoleId < -1) {
      this.updateToken();
    } else {
      this.router.navigate(['/apps']);
    }
  }

  ngOnDestroy(): void {
    this.device?.removeAllListeners();
    this.device?.destroy();
  }

  phoneKeyPress(event: KeyboardEvent): void {
    if (this.connection()) {
      this.sendDigit(event.key, false);
    }
  }

  sendDigit(digit: string, updatePhoneNumber = true): void {
    if (!digit.match(/[0-9#*]/)) {
      return;
    }

    if (updatePhoneNumber) {
      this.phoneForm.setValue({ number: (this.phoneForm.value.number ?? '') + digit });
    }
    if (this.connection()) {
      this.connection().sendDigits(digit.toString());
    }
  }

  async call(): Promise<void> {
    if (this.connection()) {
      // hang up the current call
      this.connection().disconnect();
      return;
    }

    if (!this.phoneForm.valid) {
      this.log('Please enter a valid phone number');
      return;
    }

    const params: Device.ConnectOptions = { params: { To: this.phoneForm.value.number } };
    this.log('Calling ' + this.phoneForm.value.number + '...');
    const call = await this.device.connect(params);
    this.connection.set(call);
    call.on('disconnect', () => this.disconnectHandler());

    // clear the phone number
    this.phoneForm.reset();
  }

  reject(): void {
    this.incoming().reject();
    this.incoming.set(null);
  }

  private updateToken(): void {
    this.http.get<{ token: string; identity: string }>('/twilio/token').subscribe((data) => {
      this.log('Initializing...');
      this.device = new Device(data.token, { closeProtection: true });
      this.device.on(Device.EventName.Registered, () => this.readyHandler());
      this.device.on(Device.EventName.Error, (err) => this.errorHandler(err));
      this.device.on(Device.EventName.Unregistered, () => this.disconnectHandler());
      this.device.on(Device.EventName.Incoming, (conn) => this.incomingHandler(conn));
      this.device.on(Device.EventName.Destroyed, () => this.cancelHandler());
      this.device.on(Device.EventName.TokenWillExpire, () => this.offlineHandler());

      this.device.register();

      this.clientName.set(data.identity);
    });
  }

  private readyHandler(): void {
    this.registered.set(true);
    this.log('Ready!');
  }

  private offlineHandler(): void {
    this.log('Renewing token');
    this.updateToken();
  }

  private errorHandler(error): void {
    this.log('Error: ' + error.message);
  }

  private disconnectHandler(): void {
    this.registered.set(false);
    this.connection.set(null);
    this.phoneForm.reset();

    this.log('Call ended');
  }

  private incomingHandler(conn: IPeerConnection): void {
    this.incoming.set(conn);
    this.log('Incoming call from ' + conn.parameters.From);
  }

  private cancelHandler(): void {
    this.incoming.set(null);
    this.log('Call missed');
  }

  private log(message: string): void {
    if (!this.logElRef) {
      return;
    }

    this.logs.set([...this.logs(), { time: new Date(), type: 'log', message }]);
    setTimeout(() => this.scrollToBottom());
  }

  private scrollToBottom(): void {
    this.logElRef.nativeElement.children[this.logElRef.nativeElement.children.length - 1].scrollIntoView({
      behavior: 'smooth'
    });
  }
}
