import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Signal,
  computed,
  inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ConfirmDialogService } from '@ih/confirm';
import { DeviceInfoDialogService } from '@ih/dialogs';
import { ImageComponent } from '@ih/image';
import { BridgeConfig, BridgeMember, ConfirmOptions } from '@ih/interfaces';
import { ConfigService, LazySnackBarService } from '@ih/services';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { SideNavService } from './side-nav.service';
@Component({
  selector: 'ih-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,

    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,

    ImageComponent
  ]
})
export class AppComponent {
  @HostBinding('class.ih-root') hostClass = true;

  private updates = inject(SwUpdate);
  private confirmDialog = inject(ConfirmDialogService);
  private sideNavService = inject(SideNavService);
  private router = inject(Router);
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private cd = inject(ChangeDetectorRef);
  private deviceInfo = inject(DeviceInfoDialogService);
  public config = inject(ConfigService<BridgeConfig>) as ConfigService<BridgeConfig>;

  currentUser: Signal<BridgeMember> = toSignal(this.config.config$.pipe(map((config) => config?.currentUser)));

  updateAvailable$ = this.updates.versionUpdates.pipe(
    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
  );

  currentApp$ = this.sideNavService.currentApp$;

  builderUrl: string;

  isBridgeOfficer = computed(() => {
    const currentUser = this.currentUser();

    return currentUser?.roleInfo.userRoleId === -2;
  });

  version = toSignal(
    this.config.config$.pipe(
      map((config) =>
        config.environment.environment === 'Development'
          ? config.environment.branch
          : `${config.environment.version} [${config.environment.region}]`
      )
    )
  );

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const navEnd = event as NavigationEnd;
        if (!navEnd.url.includes('/apps/')) {
          this.sideNavService.setCurrentApp(null);
        }

        // check if the loader is still showing and if so, hide it
        const loader = document.getElementById('loader');
        if (loader) {
          loader.remove();
          clearInterval(window.messageInterval);
        }
      }
    });
  }

  toggleParent(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    Array.from(document.querySelectorAll('.sidebar-nav .sub-menu')).forEach((el) => {
      if (el !== (e.currentTarget as HTMLAnchorElement).parentElement) {
        el.classList.remove('open');
      } else {
        el.classList.contains('open') ? el.classList.remove('open') : el.classList.add('open');
      }
    });
  }

  toggleMenu(e: Event): void {
    e.stopPropagation();
    $('#wrapper').toggleClass('toggled');
  }

  async showUpdateDialog(event?: Event): Promise<void> {
    if (event) {
      event.preventDefault();
    }

    const dialog = await this.confirmDialog.open({
      title: 'Update now?',
      message: 'This will refresh your app to apply any new updates that are available.',
      confirmText: 'UPDATE'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  showDeviceInfo(): void {
    this.deviceInfo.open();
  }

  async toggleArchived(currentApp): Promise<Promise<void>> {
    if (currentApp.archived) {
      const dialog = await this.confirmAction({
        title: `Are you sure you want to RESTORE ${currentApp.name + ' (' + currentApp.campaignId + ')'}?`,
        confirmText: 'RESTORE APP',
        url: '/api/campaigns/' + currentApp.campaignId + '/archived',
        method: 'DELETE',
        successMessage: 'App has been successfully restored',
        campaignVar: 'archived',
        campaignVarValue: false
      });
      dialog.subscribe(() => {
        currentApp.archived = !currentApp.archived;
        this.cd.markForCheck();
        // tell the page to refresh if it needs to
        this.sideNavService.refreshApp$.next();
      });
    } else {
      const dialog = await this.confirmAction({
        title: `Are you sure you want to ARCHIVE ${currentApp.name + ' (' + currentApp.campaignId + ')'}?`,
        confirmText: 'ARCHIVE APP',
        url: '/api/campaigns/' + currentApp.campaignId + '/archived',
        method: 'PUT',
        successMessage: 'App has been successfully archived',
        campaignVar: 'archived',
        campaignVarValue: true
      });
      dialog.subscribe(() => {
        currentApp.archived = !currentApp.archived;
        this.cd.markForCheck();
        // tell the page to refresh if it needs to
        this.sideNavService.refreshApp$.next();
      });
    }
  }

  flush(campaignId: number): void {
    this.http
      .post(`/api/campaigns/${campaignId}/flush`, {})
      .pipe(
        catchError((err, caught) => {
          this.snackbar.open('Unable to flush app', 'TRY AGAIN').then((ref) => ref.onAction().subscribe(() => caught));
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Triggered a flush on the app');
      });
  }

  clearRedis(campaignId: number): void {
    this.http
      .delete(`/api/campaigns/${campaignId}/redis`, {})
      .pipe(
        catchError((err, caught) => {
          this.snackbar
            .open('Unable to clear redis', 'TRY AGAIN')
            .then((ref) => ref.onAction().subscribe(() => caught));
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Cleared redis');
      });
  }

  private async confirmAction(options: ConfirmOptions): Promise<Observable<any>> {
    const dialog = await this.confirmDialog.open({
      title: options.title,
      message: options.message,
      confirmText: options.confirmText
    });
    const confirm = dialog.afterClosed().pipe(
      switchMap((result) => {
        if (!result) {
          return of(null);
        }
        return this.http
          .request(options.method, options.url, {
            body: options.data
          })
          .pipe(
            catchError((err, caught) => {
              this.snackbar
                .open(options.failureMessage || 'Unable to complete request', 'TRY AGAIN')
                .then((ref) => ref.onAction().subscribe(() => caught));
              throw err;
            })
          );
      })
    );

    confirm.subscribe(() => {
      this.snackbar.open(options.successMessage);
    });
    return confirm;
  }
}
