"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @packageDocumentation
 * @internalapi
 */
// @ts-nocheck
// NOTE (csantos): This file was taken directly from twilio-video and has been renamed from JS to TS only.
// It needs to be re-written as part of the overall updating of the files to TS.
var events_1 = require("events");
var Backoff = /** @class */function (_super) {
  __extends(Backoff, _super);
  /**
   * Construct a {@link Backoff}.
   * @param {object} options
   * @property {number} min - Initial timeout in milliseconds [100]
   * @property {number} max - Max timeout [10000]
   * @property {boolean} jitter - Apply jitter [0]
   * @property {number} factor - Multiplication factor for Backoff operation [2]
   */
  function Backoff(options) {
    var _this = _super.call(this) || this;
    Object.defineProperties(_this, {
      _attempts: {
        value: 0,
        writable: true
      },
      _duration: {
        enumerable: false,
        get: function () {
          var ms = this._min * Math.pow(this._factor, this._attempts);
          if (this._jitter) {
            var rand = Math.random();
            var deviation = Math.floor(rand * this._jitter * ms);
            // tslint:disable-next-line
            ms = (Math.floor(rand * 10) & 1) === 0 ? ms - deviation : ms + deviation;
          }
          // tslint:disable-next-line
          return Math.min(ms, this._max) | 0;
        }
      },
      _factor: {
        value: options.factor || 2
      },
      _jitter: {
        value: options.jitter > 0 && options.jitter <= 1 ? options.jitter : 0
      },
      _max: {
        value: options.max || 10000
      },
      _min: {
        value: options.min || 100
      },
      _timeoutID: {
        value: null,
        writable: true
      }
    });
    return _this;
  }
  Backoff.prototype.backoff = function () {
    var _this = this;
    var duration = this._duration;
    if (this._timeoutID) {
      clearTimeout(this._timeoutID);
      this._timeoutID = null;
    }
    this.emit('backoff', this._attempts, duration);
    this._timeoutID = setTimeout(function () {
      _this.emit('ready', _this._attempts, duration);
      _this._attempts++;
    }, duration);
  };
  Backoff.prototype.reset = function () {
    this._attempts = 0;
    if (this._timeoutID) {
      clearTimeout(this._timeoutID);
      this._timeoutID = null;
    }
  };
  return Backoff;
}(events_1.EventEmitter);
exports.default = Backoff;
