import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Conversation, ConversationMessage } from '@ih/interfaces';
import { SignalRService } from '@ih/services';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private http = inject(HttpClient);
  private signalrService = inject(SignalRService);

  private conversations$ = new BehaviorSubject<Conversation[]>([]);
  private selectedConversationSubject = new BehaviorSubject<Conversation | null>(null);
  selectedConversation$ = this.selectedConversationSubject.asObservable();

  constructor() {
    this.signalrService.titleStream().subscribe((chunk) => {
      // find the conversation
      const conversation = this.selectedConversationSubject.value;

      if (chunk.title === '' && conversation) {
        conversation.title = '';
      }

      if (chunk.title === null) {
        // save the conversation
        if (conversation) {
          this.saveConversation(conversation).subscribe();
        }
        return;
      }

      if (conversation) {
        conversation.title += chunk.title;
      }
    });

    this.signalrService.chatStream().subscribe((message) => {
      if (!this.selectedConversationSubject.value) {
        return;
      }

      if (this.selectedConversationSubject.value.conversationId !== message.conversationId) {
        return;
      }

      // if the message content is null, it means this is the end of the message
      if (message.content === null) {
        // save the conversation
        this.saveConversation(this.selectedConversationSubject.value).subscribe();
        return;
      }

      // if the message is empty string, it means this is a new message
      if (message.content === '') {
        this.selectedConversationSubject.next({
          ...this.selectedConversationSubject.value,
          messages: [...this.selectedConversationSubject.value.messages, message]
        });
        return;
      }

      // add the message to the partial message
      // find the partial message
      const partialMessage = this.selectedConversationSubject.value.messages.find(
        (m) => m.conversationMessageId === message.conversationMessageId
      );
      if (partialMessage) {
        this.selectedConversationSubject.next({
          ...this.selectedConversationSubject.value,
          messages: [
            ...this.selectedConversationSubject.value.messages.filter(
              (m) => m.conversationMessageId !== message.conversationMessageId
            ),
            {
              ...partialMessage,
              content: partialMessage.content + message.content
            }
          ]
        });
      }
    });
  }

  getConversationList(): Observable<Conversation[]> {
    this.http
      .get<Conversation[]>('/api/openai/conversations')
      .pipe(
        tap((conversations) => {
          this.conversations$.next(conversations);
        })
      )
      .subscribe();

    return this.conversations$;
  }

  getConversation(id: string): Conversation | undefined {
    return this.conversations$.value.find((conversation) => conversation.conversationId === id);
  }

  selectConversation(id: string): void {
    const conversation = this.getConversation(id);
    if (conversation) {
      this.selectedConversationSubject.next(conversation);
    }
  }

  editMessage(conversationId: string, messageId: string, content: string): Observable<void> {
    const conversation = this.getConversation(conversationId);
    const message = this.getMessage(messageId);
    if (conversation && message) {
      message.content = content;
    }

    return of();
  }

  addMessageToConversation(message: ConversationMessage): Observable<void> {
    const conversation = this.selectedConversationSubject.value!;
    // if this is the first message we need to create the conversation
    const isFirstMessage = conversation.messages.length === 0;
    conversation.messages.push(message);
    // If this message is a child of another, add it to the parent's children array
    if (message.parentId) {
      const parentMessage = conversation.messages.find((m) => m.conversationMessageId === message.parentId);
      if (parentMessage && !parentMessage.children?.includes(message.conversationMessageId)) {
        parentMessage.children?.push(message.conversationMessageId);
      }
    }
    if (isFirstMessage) {
      this.conversations$.next([...this.conversations$.value, conversation]);

      return this.http.post<void>('/api/openai/conversations', conversation);
    }

    return this.http.put<void>(`/api/openai/conversations/${conversation.conversationId}`, conversation, {
      params: {
        generate: 'true'
      }
    });
  }

  getMessage(messageId: string): ConversationMessage | undefined {
    return this.selectedConversationSubject.value?.messages.find(
      (message) => message.conversationMessageId === messageId
    );
  }

  getChildrenOfMessage(conversationId: string, messageId: string): ConversationMessage[] {
    const conversation = this.getConversation(conversationId);
    const parentMessage = this.getMessage(messageId);
    if (conversation && parentMessage) {
      return (
        parentMessage.children
          ?.map((id) => conversation.messages.find((m) => m.conversationMessageId === id))
          .filter((message): message is ConversationMessage => message !== undefined) ?? []
      );
    }
    return [];
  }

  createConversation(): Observable<Conversation> {
    const newConversation: Conversation = {
      conversationId: crypto.randomUUID(),
      title: 'New chat',
      messages: []
    };
    this.selectedConversationSubject.next(newConversation);
    return of(newConversation);
  }

  regenerateResponse(conversation: Conversation): Observable<void> {
    // check if the last message is a bot message, if it is, remove it
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    if (lastMessage.role === 'assistant') {
      conversation.messages.pop();
    }

    return this.http.put<void>(`/api/openai/conversations/${conversation.conversationId}`, conversation, {
      params: {
        generate: 'true'
      }
    });
  }

  deleteConversation(conversationId: string): Observable<void> {
    return this.http.delete<void>(`/api/openai/conversations/${conversationId}`).pipe(
      tap(() => {
        this.conversations$.next(this.conversations$.value.filter((c) => c.conversationId !== conversationId));
      })
    );
  }

  saveConversation(conversation: Conversation): Observable<void> {
    // if the conversation is not in the list, add it
    if (!this.conversations$.value.find((c) => c.conversationId === conversation.conversationId)) {
      this.conversations$.next([conversation, ...this.conversations$.value]);
    }
    return this.http.put<void>(`/api/openai/conversations/${conversation.conversationId}`, conversation, {
      params: {
        generate: 'false'
      }
    });
  }
}
