import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDrawerMode, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Conversation } from '@ih/interfaces';
import { BehaviorSubject, map } from 'rxjs';
import { ChatService } from '../../chat.service';
import { ConversationComponent } from '../conversation/conversation.component';

@Component({
  selector: 'ih-conversation-list',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgForOf,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    ConversationComponent
  ],
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationListComponent implements AfterViewInit {
  private breakpointObserver = inject(BreakpointObserver);
  private chatService = inject(ChatService);

  @ViewChild('drawer') drawer!: MatSidenav;

  conversations$ = new BehaviorSubject<Conversation[]>([]);

  drawerMode$ = this.breakpointObserver
    .observe('(max-width: 959px)')
    .pipe(map((result) => (result.matches ? 'over' : 'side') as MatDrawerMode));

  ngAfterViewInit() {
    this.chatService.getConversationList().subscribe({
      next: (conversations) => {
        this.conversations$.next(conversations);
      },
      error: (error) => console.error(error)
    });

    this.chatService.createConversation();
  }

  selectConversation(conversation: Conversation) {
    this.chatService.selectConversation(conversation.conversationId);
  }

  createConversation(): void {
    this.chatService.createConversation();
  }

  toggleMenu(): void {
    this.drawer.toggle();
  }

  deleted(conversation: Conversation): void {
    this.chatService.deleteConversation(conversation.conversationId).subscribe();
    this.createConversation();
  }
}
