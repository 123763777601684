<section class="bridge-user-list">
  <header class="bridge-user-list-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="bridge-user-list-header-icon">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
            />
          </svg>
        </mat-icon>
        <div class="bridge-user-list-title fx-layout-row" *ngIf="showActive">
          Bridge users
          <div class="bridge-user-status-active">active</div>
        </div>
        <div class="bridge-user-list-title fx-layout-row" *ngIf="!showActive">
          Bridge users
          <div class="bridge-user-status-suspended">suspended</div>
        </div>
      </div>
      <div class="add-bridge-user-btn-div fx-layout-align-end-center">
        <button mat-icon-button aria-label="Open member options menu" [matMenuTriggerFor]="memberMenu">
          <mat-icon>
            <svg id="dots-vertical" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
              />
            </svg>
          </mat-icon>
        </button>

        <!--Member Menu-->
        <mat-menu #memberMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="showAddBridgeUser()">
            <mat-icon>
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
                />
              </svg>
            </mat-icon>
            <span>Add bridge user</span>
          </button>

          <button mat-menu-item (click)="toggleShowActive()" *ngIf="!showActive">
            <mat-icon>
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z"
                />
              </svg>
            </mat-icon>
            <span>Show active bridge users</span>
          </button>

          <button mat-menu-item (click)="toggleShowActive()" *ngIf="showActive">
            <mat-icon>
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13M10 14C5.58 14 2 15.79 2 18V20H11.5A6.5 6.5 0 0 1 11 17.5A6.5 6.5 0 0 1 11.95 14.14C11.32 14.06 10.68 14 10 14M17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5M14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z"
                />
              </svg>
            </mat-icon>
            <span>Show Suspended bridge users</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </header>

  <!-- Bridge user search -->
  <div class="bridge-user-list-container fx-layout-column">
    <div class="bridge-user-search-container fx-layout-row fx-layout-align-start-center">
      <mat-form-field class="fx-flex">
        <input matInput type="text" [formControl]="query" placeholder="Search bridge user..." />
        <button mat-icon-button matSuffix (click)="clearQuery()">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </mat-icon>
        </button>
      </mat-form-field>
      <button mat-icon-button (click)="refresh$.next()">
        <mat-icon>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z"
            />
          </svg>
        </mat-icon>
      </button>
    </div>

    <ng-container *ngIf="loading$ | async">
      <div class="loading-container fx-layout-row fx-layout-align-center-center">
        <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
      </div>
    </ng-container>

    <!-- Bridge user list -->
    <mat-accordion class="bridge-user-list-accordion">
      <mat-expansion-panel class="bridge-user-list-user-item" *ngFor="let user of users$ | async">
        <mat-expansion-panel-header
          class="bridge-user-list-user-item-header fx-layout-row fx-layout-align-start-center"
        >
          <mat-panel-title class="fx-layout-row fx-layout-align-start-center">
            <div class="bridge-user-img-container fx-layout-column">
              <ih-image class="bridge-user-img" [imageInfo]="user.image"></ih-image>
            </div>
            <div class="bridge-user-detail-row fx-layout-column">
              <div class="bridge-user-fullname">
                {{ user.fullName || 'Name unavailable' }}
              </div>

              <div class="bridge-user-email">
                {{ user.email || 'Email unavailable' }}
              </div>
            </div>
          </mat-panel-title>

          <!-- Role -->
          <mat-panel-description class="fx-layout-align-end-center">
            <span class="bridge-user-list-user-role">{{ user.userRoleName }}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- Expansion panel info -->
        <div class="fx-layout-column">
          <mat-list hidden>
            <div class="bridge-user-permissions-container fx-layout-column">
              <h3 class="subheader">PERMISSIONS</h3>
              <mat-list-item>
                <div class="fx-layout-column fx-flex">
                  <strong>Hub creation</strong>
                  <span>Allow bridge user to create Hubs in the bridge</span>
                </div>
                <mat-slide-toggle> </mat-slide-toggle>
              </mat-list-item>
              <mat-divider class="bridge-user-permissions-divider"></mat-divider>

              <mat-list-item>
                <div class="fx-layout-column fx-flex">
                  <strong>Member impersonation</strong>
                  <span>Allow bridge user to impersonate members of apps</span>
                </div>
                <mat-slide-toggle> </mat-slide-toggle>
              </mat-list-item>
            </div>
          </mat-list>

          <!-- Bridge user app list -->
          <mat-list>
            <div class="bridge-user-app-list-all-app-text fx-flex-initial" *ngIf="user.userRoleId == -2">
              Bridge Officers have access to all Apps
            </div>

            <div class="bridge-user-app-list-container fx-layout-column" *ngIf="user.userRoleId == -1">
              <h3 class="bridge-user-app-list-title subheader fx-layout-row fx-layout-align-center-center">
                <span class="fx-flex">HUBS</span>
                <button class="bridge-user-add-app-btn" mat-icon-button (click)="showAddApp(user.userId)">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M17,13H13V17H11V13H7V11H11V7H13V11H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
                      />
                    </svg>
                  </mat-icon>
                </button>
              </h3>
              <div
                class="bridge-user-app-list-item fx-layout-row fx-layout-align-start-center"
                *ngFor="let app of user.apps"
              >
                <div class="bridge-user-app-list-img-container fx-layout-column">
                  <img class="bridge-user-app-list-img" [src]="app.iconUrl" />
                </div>
                <div class="bridge-user-detail-row fx-layout-column fx-flex">
                  <div class="bridge-user-fullname">{{ app.name }}</div>

                  <div class="bridge-user-email" *ngIf="app.owner">
                    Owner: {{ app.owner.fullname }} &middot; {{ app.owner.email }}
                  </div>
                  <div class="bridge-user-email" *ngIf="!app.owner">Owner: <em>not set</em></div>
                </div>

                <!-- App specific Impersonate toggle -->
                <div class="bridge-user-impersonate-btn-div fx-layout-align-end-center" hidden>
                  <button class="bridge-user-impersonate-btn" mat-icon-button>
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M17.06 13C15.2 13 13.64 14.33 13.24 16.1C12.29 15.69 11.42 15.8 10.76 16.09C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17C3 19.21 4.77 21 6.94 21C9 21 10.68 19.38 10.84 17.32C11.18 17.08 12.07 16.63 13.16 17.34C13.34 19.39 15 21 17.06 21C19.23 21 21 19.21 21 17C21 14.79 19.23 13 17.06 13M6.94 19.86C5.38 19.86 4.13 18.58 4.13 17S5.39 14.14 6.94 14.14C8.5 14.14 9.75 15.42 9.75 17S8.5 19.86 6.94 19.86M17.06 19.86C15.5 19.86 14.25 18.58 14.25 17S15.5 14.14 17.06 14.14C18.62 14.14 19.88 15.42 19.88 17S18.61 19.86 17.06 19.86M22 10.5H2V12H22V10.5M15.53 2.63C15.31 2.14 14.75 1.88 14.22 2.05L12 2.79L9.77 2.05L9.72 2.04C9.19 1.89 8.63 2.17 8.43 2.68L6 9H18L15.56 2.68L15.53 2.63Z"
                        />
                      </svg>
                    </mat-icon>
                  </button>
                </div>

                <!-- Delete app access -->
                <div class="bridge-user-remove-app-btn-div fx-layout-align-end-center">
                  <button
                    class="bridge-user-remove-app-btn"
                    (click)="removeApp(user.userId, app.campaignId, app.name)"
                    mat-icon-button
                  >
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                        />
                      </svg>
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-list>
        </div>
        <mat-action-row>
          <button
            class="bridge-user-edit-btn"
            mat-flat-button
            color="primary"
            (click)="showBridgeUserEditor($event, user.userId)"
          >
            Edit
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
