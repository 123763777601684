<section class="assign-bridge-user-list">
  <header class="assign-bridge-user-list-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="assign-bridge-user-list-icon">
          <svg viewBox="0 0 24 24" style="width: 30px; height: 30px">
            <path
              fill="currentColor"
              d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M8 10H5V7H3V10H0V12H3V15H5V12H8Z"
            />
          </svg>
        </mat-icon>
        <div class="assign-bridge-user-list-title">Assign bridge users</div>
      </div>
    </div>
  </header>

  <div class="assign-bridge-user-list-container fx-layout-column">
    <ih-filter-chips
      class="assign-bridge-user-list-filter-chips"
      [campaignId]="campaignId"
      ihMemberFilterChips
      (filterChanged)="filterChanged($event)"
    ></ih-filter-chips>
    <mat-divider></mat-divider>

    <div class="loading-shade fx-flex" *ngIf="isLoadingResults">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>
    </div>

    <mat-accordion class="assign-bridge-user-list-accordion">
      <mat-expansion-panel class="user-list-user-item" *ngFor="let user of users$ | async">
        <mat-expansion-panel-header class="fx-layout-row fx-layout-align-start-center">
          <mat-panel-title class="fx-layout-row fx-layout-align-start-center">
            <div class="user-img-container fx-layout-column">
              <ih-image class="user-img" [imageInfo]="user.image"></ih-image>
            </div>
            <div class="user-detail-row fx-layout-column">
              <div class="user-fullname">
                {{ user.fullName || 'Name unavailable' }}
              </div>

              <div class="user-email">
                {{ user.email || 'Email unavailable' }}
              </div>
            </div>
          </mat-panel-title>

          <mat-panel-description class="fx-layout-align-end-center">
            <span class="user-list-user-role">{{ user.userRoleName }}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-divider></mat-divider>
    <mat-paginator
      [hidden]="resultsLength <= 100"
      [pageSizeOptions]="[100, 250, 500]"
      [length]="resultsLength"
      [pageSize]="100"
    >
    </mat-paginator>

    <div
      class="no-members-match-container fx-layout-align-center-center fx-flex"
      *ngIf="resultsLength === 0 && !isLoadingResults"
    >
      <div class="no-members-match fx-layout-align-center-center">
        <mat-icon>
          <svg class="no-members-match-icon" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M16.5,12A2.5,2.5 0 0,0 19,9.5A2.5,2.5 0 0,0 16.5,7A2.5,2.5 0 0,0 14,9.5A2.5,2.5 0 0,0 16.5,12M9,11A3,3 0 0,0 12,8A3,3 0 0,0 9,5A3,3 0 0,0 6,8A3,3 0 0,0 9,11M16.5,14C14.67,14 11,14.92 11,16.75V19H22V16.75C22,14.92 18.33,14 16.5,14M9,13C6.67,13 2,14.17 2,16.5V19H9V16.75C9,15.9 9.33,14.41 11.37,13.28C10.5,13.1 9.66,13 9,13Z"
            />
          </svg>
        </mat-icon>
        <span class="no-members-match-label">No bridge users match your current selection</span>
      </div>
    </div>
  </div>
</section>
