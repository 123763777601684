"use strict";

/**
 * This file is generated on build. To make changes, see /templates/constants.ts
 */
/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SOUNDS_BASE_URL = exports.RELEASE_VERSION = exports.PACKAGE_NAME = exports.ECHO_TEST_DURATION = exports.COWBELL_AUDIO_URL = void 0;
var PACKAGE_NAME = '@twilio/voice-sdk';
exports.PACKAGE_NAME = PACKAGE_NAME;
var RELEASE_VERSION = '2.12.0';
exports.RELEASE_VERSION = RELEASE_VERSION;
var SOUNDS_BASE_URL = 'https://sdk.twilio.com/js/client/sounds/releases/1.0.0';
exports.SOUNDS_BASE_URL = SOUNDS_BASE_URL;
var COWBELL_AUDIO_URL = SOUNDS_BASE_URL + "/cowbell.mp3?cache=" + RELEASE_VERSION;
exports.COWBELL_AUDIO_URL = COWBELL_AUDIO_URL;
var ECHO_TEST_DURATION = 20000;
exports.ECHO_TEST_DURATION = ECHO_TEST_DURATION;
