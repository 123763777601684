import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type AddBridgeUserDialogComponent } from './add-bridge-user-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AddBridgeUserDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(): Promise<MatDialogRef<AddBridgeUserDialogComponent>> {
    const addBridgeUserDialogComponent = await import('./add-bridge-user-dialog.component').then(
      (m) => m.AddBridgeUserDialogComponent
    );
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(addBridgeUserDialogComponent, {
      panelClass: ['add-bridge-user-dialog', 'dialog-no-padding', 'dialog-fullscreen'],
      maxWidth: undefined,
      minHeight: 'unset'
    });
  }
}
