import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, SlicePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ImageComponent } from '@ih/image';
import { LazySnackBarService } from '@ih/services';
import { FilterQueryService } from 'libs/services/filter-query.service';
import { catchError, distinctUntilChanged, map, merge, of, Subject, switchMap, tap, throttleTime } from 'rxjs';
import { HubUser } from '../hub-user.interface';

@Component({
  selector: 'ih-hub-users',
  standalone: true,
  imports: [
    AsyncPipe,
    SlicePipe,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    ScrollingModule,

    ImageComponent
  ],
  templateUrl: './hub-users.component.html',
  styleUrl: './hub-users.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubUsersComponent implements OnInit {
  private readonly http = inject(HttpClient);
  private readonly snackbar = inject(LazySnackBarService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly filterQuery = inject(FilterQueryService);

  query = new FormControl('');
  skipIndex = new FormControl(0);
  showSpam = new FormControl(false);
  hasBackground = new FormControl(true);
  hasImage = new FormControl(true);

  loadMore$ = new Subject<void>();

  loading = signal(false);

  noMore = false;

  @ViewChild(CdkVirtualScrollViewport)
  set scrollEvent(scrollRef: CdkVirtualScrollViewport) {
    if (scrollRef) {
      scrollRef
        .elementScrolled()
        .pipe(throttleTime(200), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          if (this.loading() || this.noMore) return; // Don't trigger if already loading

          const totalHeight = scrollRef.measureRenderedContentSize();
          const scrollOffset = scrollRef.measureScrollOffset('top');
          const bottomOffset = scrollRef.measureScrollOffset('bottom');

          // Calculate the height of the last 100 items (assuming each item is roughly the same height)
          const itemHeight = totalHeight / this.users().length; // average height per item
          const lastBatchHeight = itemHeight * 100; // height of last 100 items
          const halfLastBatchHeight = lastBatchHeight / 2; // half of the last batch height

          if (
            totalHeight > 0 &&
            scrollOffset !== 0 &&
            bottomOffset < halfLastBatchHeight // trigger when we're halfway through the last batch
          ) {
            this.onScroll();
          }
        });
    }
  }

  users = signal<HubUser[]>([]);

  constructor() {
    merge(
      this.query.valueChanges.pipe(map(() => true)),
      this.skipIndex.valueChanges.pipe(map(() => true)),
      this.showSpam.valueChanges.pipe(map(() => true)),
      this.hasBackground.valueChanges.pipe(map(() => true)),
      this.hasImage.valueChanges.pipe(map(() => true)),
      this.loadMore$.pipe(map(() => false))
    )
      .pipe(
        throttleTime(500, undefined, { leading: false, trailing: true }),
        tap((shouldReset) => {
          if (shouldReset) {
            this.resetList();
          }
        }),
        map(() => {
          this.loading.set(true);
          const params = {
            q: this.query.value,
            skip: this.skipIndex.value.toString(),
            showSpam: this.showSpam.value.toString(),
            hasBackgroundImage: this.hasBackground.value.toString(),
            hasImage: this.hasImage.value.toString()
          };
          this.filterQuery.updateQueryParams(params);

          return params;
        }),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        switchMap(() =>
          this.http.get<HubUser[]>('/api/campaignUsers', {
            params: {
              q: this.query.value,
              hasImage: this.hasImage.value,
              hasBackgroundImage: this.hasBackground.value,
              showSpam: this.showSpam.value,
              skip: this.users().length + this.skipIndex.value,
              limit: 100
            }
          })
        ),
        tap(() => this.loading.set(false)),
        catchError((err, caught) => {
          console.error(err);

          this.snackbar.open('Unable to load users', 'TRY AGAIN').then((ref) => ref.onAction().subscribe(() => caught));

          return of([]);
        }),
        takeUntilDestroyed()
      )
      .subscribe((users) => {
        this.noMore = users.length < 100;
        if (!users.length) {
          return;
        }

        this.users.update((prev) => [...prev, ...users]);
      });
  }

  async ngOnInit(): Promise<void> {
    this.loading.set(true);

    const params = await this.filterQuery.getQueryParams();

    this.query.setValue(params.q || '');
    this.skipIndex.setValue(parseInt(params.skip) || 0);
    this.showSpam.setValue(params.showSpam === 'true');
    this.hasBackground.setValue(params.hasBackgroundImage === 'true');
    this.hasImage.setValue(params.hasImage === 'true');
  }

  onScroll() {
    if (!this.noMore && !this.loading()) {
      this.loading.set(true);
      this.loadMore$.next();
    }
  }

  toggleSpamUser(user: HubUser): void {
    if (user.isSpam) {
      // update user spam flag in user list
      this.users.update((prev) =>
        prev.map((u) => (u.campaignUserId === user.campaignUserId ? { ...u, isSpam: false } : u))
      );
      this.http
        .delete(`/api/campaignUsers/${user.campaignUserId}/spam`)
        .pipe(
          catchError((err) => {
            console.error(err);
            // revert user spam flag in user list
            this.users.update((prev) =>
              prev.map((u) => (u.campaignUserId === user.campaignUserId ? { ...u, isSpam: true } : u))
            );
            this.snackbar
              .open('Unable to unspam user', 'TRY AGAIN')
              .then((ref) => ref.onAction().subscribe(() => this.toggleSpamUser(user)));
            return of({});
          })
        )
        .subscribe();
    } else {
      // update user spam flag in user list
      this.users.update((prev) =>
        prev.map((u) => (u.campaignUserId === user.campaignUserId ? { ...u, isSpam: true } : u))
      );
      this.http
        .post(`/api/campaignUsers/${user.campaignUserId}/spam`, {})
        .pipe(
          catchError((err) => {
            console.error(err);
            // revert user spam flag in user list
            this.users.update((prev) =>
              prev.map((u) => (u.campaignUserId === user.campaignUserId ? { ...u, isSpam: false } : u))
            );
            this.snackbar
              .open('Unable to spam user', 'TRY AGAIN')
              .then((ref) => ref.onAction().subscribe(() => this.toggleSpamUser(user)));
            return of({});
          })
        )
        .subscribe();
    }
  }

  clearSearch() {
    this.query.setValue('');
    this.resetList();
  }

  resetList(): void {
    this.users.set([]);
    this.noMore = false;
  }
}
