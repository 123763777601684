"use strict";

/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Deferred Promise
 */
var Deferred = /** @class */function () {
  /**
   * @constructor
   */
  function Deferred() {
    var _this = this;
    this._promise = new Promise(function (resolve, reject) {
      _this._resolve = resolve;
      _this._reject = reject;
    });
  }
  Object.defineProperty(Deferred.prototype, "promise", {
    /**
     * @returns The {@link Deferred} Promise
     */
    get: function () {
      return this._promise;
    },
    enumerable: false,
    configurable: true
  });
  /**
   * Rejects this promise
   */
  Deferred.prototype.reject = function (reason) {
    this._reject(reason);
  };
  /**
   * Resolves this promise
   */
  Deferred.prototype.resolve = function (value) {
    this._resolve(value);
  };
  return Deferred;
}();
exports.default = Deferred;
