import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  inject,
  signal
} from '@angular/core';
import {
  AsyncValidatorFn,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogService } from '@ih/confirm';
import { RESERVED_SLUGS_REGEX, SLUG_MAX_LENGTH } from '@ih/constants';
import { InputDialogService, OptionPickerDialogService } from '@ih/dialogs';
import { ImageComponent } from '@ih/image';
import { BridgeCampaign, ConfirmOptions, OptionPickerItem } from '@ih/interfaces';
import { LazySnackBarService } from '@ih/services';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { OwnerTransferDialogService } from '../owner-transfer-dialog/owner-transfer-dialog.service';
import { SideNavService } from '../side-nav.service';

@Component({
  selector: 'ih-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,
    NgStyle,

    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    ReactiveFormsModule,

    ImageComponent
  ]
})
export class AppSettingsComponent implements OnInit {
  @HostBinding('class.ih-app-settings') hostClass = true;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private confirmDialog = inject(ConfirmDialogService);
  private inputDialog = inject(InputDialogService);
  private sideNavService = inject(SideNavService);
  private ownerTransferDialog = inject(OwnerTransferDialogService);
  private optionPickerDialog = inject(OptionPickerDialogService);
  private cd = inject(ChangeDetectorRef);

  loadingStripeProduct = signal<boolean>(false);
  stripeProduct = signal<string | null>(null);

  campaignId = this.route.snapshot.params.campaignId;

  campaign$ = this.sideNavService.refreshApp$.pipe(
    startWith({}),
    switchMap(() => this.http.get<BridgeCampaign>('/api/campaigns/' + this.campaignId)),
    tap<BridgeCampaign>((data) => {
      this.campaign = data;

      if (this.campaign.stripeProductId) {
        this.updateStripeProduct();
      }
    })
  ) as Observable<BridgeCampaign>;

  campaign: BridgeCampaign;

  bridgeUploadForm = new UntypedFormGroup({
    bridgeUploadLimit: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.min(1),
      Validators.max(100)
    ])
  });

  videoUploadForm = new UntypedFormGroup({
    videoUploadLimit: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.min(1),
      Validators.max(100)
    ])
  });

  settings = window.campaign;

  constructor() {}

  ngOnInit(): void {
    this.sideNavService.setCurrentApp(this.campaignId);
  }

  private updateStripeProduct(): void {
    this.loadingStripeProduct.set(true);
    this.http
      .get<{
        name: string;
        description: string;
        active: boolean;
      }>('/api/stripe/bridgeProducts/' + this.campaign.stripeProductId, {
        params: {
          useSandbox: this.campaign.useSandbox.toString()
        }
      })
      .pipe(
        catchError(() => {
          this.stripeProduct.set('invalid product');
          this.loadingStripeProduct.set(false);
          return of(null);
        })
      )
      .subscribe((stripeProduct) => {
        // if the product is not active, append (inactive) to the name
        if (!stripeProduct.active) {
          stripeProduct.name = stripeProduct.name + ' (inactive)';
        }
        const name = stripeProduct.name;
        this.stripeProduct.set(name);
        this.loadingStripeProduct.set(false);
      });
  }

  slugInUse(): AsyncValidatorFn {
    return (control: FormControl<string>): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      const value = control.value.trim();

      // cannot be longer than SLUG_MAX_LENGTH chars
      if (value.length > SLUG_MAX_LENGTH) {
        this.cd.markForCheck();
        return of({ maxLength: true });
      }

      if (
        // check for reserved slugs checking against a list of regexes
        RESERVED_SLUGS_REGEX.some((slugRegex) => slugRegex.test(value))
      ) {
        this.cd.markForCheck();
        return of({ reserved: true });
      }

      // make sure the slug only contains numbers, letters, and hyphens
      // should not start or end with a hyphen
      // should not contain consecutive hyphens
      if (!/^[a-z0-9]+(-[a-z0-9]+)*$/.test(value)) {
        this.cd.markForCheck();
        return of({ invalid: true });
      }

      return timer(500).pipe(
        switchMap(() =>
          this.http.get('/api/campaigns/slug/valid?slug=' + value).pipe(map((data) => (!data ? { inUse: true } : null)))
        ),
        tap(() => {
          control.markAsTouched();
          this.cd.markForCheck();
        })
      );
    };
  }

  async changeUrl(campaign): Promise<void> {
    const dialog = await this.inputDialog.open({
      title: 'Change app slug?',
      inputLabel: undefined,
      value: campaign.slug,
      validators: [Validators.required, Validators.minLength(3)],
      asyncValidators: [this.slugInUse()]
    });

    dialog.afterClosed().subscribe((newSlug) => {
      if (newSlug) {
        this.http
          .put('/api/campaigns/' + this.campaignId + '/slug/' + newSlug, {})
          .pipe(
            catchError((err, caught) => {
              switch (err.status) {
                case 409:
                  this.snackbar.open('Cannot rename app, another app with that URL already exists');
                  break;
                default:
                  this.snackbar.open('Something went wrong');
                  break;
              }

              throw err;
            })
          )
          .subscribe(() => {
            this.campaign.slug = newSlug;
            this.snackbar.open(campaign.title + ' has been updated');
          });
      }
    });
  }

  async changeOrgName(campaign): Promise<void> {
    const dialog = await this.inputDialog.open({
      title: 'Change HUB name?',
      inputLabel: undefined,
      value: campaign.orgName
    });
    dialog.afterClosed().subscribe((newName) => {
      if (newName) {
        this.http.put('/api/campaigns/' + this.campaignId + '/name/' + newName, {}).subscribe((data) => {
          campaign.orgName = newName;
          campaign.title = newName;
          if (data && data !== 'success') {
            this.snackbar.open('There was a problem updating name');
          } else {
            this.snackbar.open(campaign.orgName + ' has been updated');
          }
        });
      }
    });
  }

  async changeAffiliateCode(campaign: BridgeCampaign): Promise<void> {
    const dialog = await this.inputDialog.open({
      title: 'Change affiliate code?',
      inputLabel: undefined,
      value: campaign.affiliateCode
    });
    dialog.afterClosed().subscribe((affiliateCode) => {
      if (affiliateCode) {
        this.http.put('/api/campaigns/' + this.campaignId + '/affiliateCode/' + affiliateCode, {}).subscribe((data) => {
          campaign.affiliateCode = affiliateCode;
          if (data && data !== 'success') {
            this.snackbar.open('There was a problem updating affiliate code');
          } else {
            this.snackbar.open(campaign.orgName + ' has been updated');
          }
          this.cd.markForCheck();
        });
      }
    });
  }

  async changeEngagedNationId(campaign: BridgeCampaign): Promise<void> {
    const dialog = await this.inputDialog.open({
      title: 'Change Engaged Nation API connection id?',
      inputLabel: undefined,
      value: campaign.engagedNationId
    });
    dialog.afterClosed().subscribe((engagedNationId) => {
      if (engagedNationId) {
        this.http
          .put('/api/campaigns/' + this.campaignId + '/engagedNationId/' + engagedNationId, {})
          .subscribe((data) => {
            campaign.engagedNationId = engagedNationId;
            if (data && data !== 'success') {
              this.snackbar.open('There was a problem updating Engaged Nation API connection id');
            } else {
              this.snackbar.open(campaign.orgName + ' has been updated');
            }
            this.cd.markForCheck();
          });
      }
    });
  }

  ssoChanged(event: MatSelectChange): void {
    this.http
      .put('/api/campaigns/' + this.campaignId + '/customSsoType/' + event.source.value, {})
      .subscribe((data) => {
        this.snackbar.open('SSO has been updated');
        this.cd.markForCheck();
      });
  }

  async removeStripeProduct(): Promise<void> {
    const dialog = await this.confirmDialog.open({
      title: 'Are you sure you want to remove the stripe product?',
      confirmText: 'REMOVE STRIPE PRODUCT',
      message: 'This will convert the hub to a free app and remove all paid features.',
      confirmColor: 'warn'
    });
    dialog.afterClosed().subscribe((confirm) => {
      if (!confirm) {
        return;
      }

      this.http.delete('/api/campaigns/' + this.campaignId + '/stripeProductId').subscribe(() => {
        this.campaign.stripeProductId = null;
        this.snackbar.open('Stripe product has been removed');
        this.cd.markForCheck();
        this.stripeProduct.set(null);
      });
    });
  }

  async changeStripeProduct(campaign: BridgeCampaign): Promise<void> {
    this.http
      .get<{ productId: string; name: string; description: string }[]>('/api/stripe/bridgeProducts', {
        params: {
          useSandbox: this.campaign.useSandbox.toString()
        }
      })
      .subscribe(async (products) => {
        const options: OptionPickerItem<string>[] = products
          .filter((product) => product.name && ['trial', 'free'].indexOf(product.productId) === -1)
          .map((product) => ({ value: product.productId, name: product.name }));
        // add 'none' option
        options.unshift({ value: '-1', name: 'None' });
        const dialog = await this.optionPickerDialog.open({
          title: 'Change stripe product?',
          options,
          value: {
            value: campaign.stripeProductId ?? '-1',
            name: campaign.stripeProductId ? this.stripeProduct() : 'None'
          },
          multiple: false
        });
        dialog.afterClosed().subscribe((selectedProduct) => {
          if (selectedProduct)
            if (selectedProduct.value === '-1') {
              // if selectedProduct is -1, we need to remove the stripe product
              this.removeStripeProduct();
            } else {
              this.http
                .put('/api/campaigns/' + this.campaignId + '/stripeProductId/' + selectedProduct.value, {})
                .subscribe((data) => {
                  campaign.stripeProductId = selectedProduct.value;

                  // update the stripe product name
                  this.updateStripeProduct();

                  if (data && data !== 'success') {
                    this.snackbar.open('There was a problem updating stripe product');
                  } else {
                    this.snackbar.open(campaign.orgName + ' has been updated');
                  }
                  this.cd.markForCheck();
                });
            }
        });
      });
  }

  setPostFlag(flag: string): void {
    const flagProp = `post${flag}Enabled`;
    const flagEnabled = this.campaign[flagProp];
    const flagName = flag
      .split(/(?=[A-Z])/)
      .join(' ')
      .toLowerCase();
    this.confirmAction({
      title: `Are you sure you want to ${flagEnabled ? 'enable' : 'disable'} post ${flagName}?`,
      confirmText: `${flagEnabled ? 'ENABLE' : 'DISABLE'} ${flagName.toUpperCase()}`,
      url: '/api/campaigns/' + this.campaign.campaign_id + '/post' + flag,
      data: flagEnabled,
      method: 'PUT',
      successMessage: flagName + ' is now ' + (flagEnabled ? 'enabled' : 'disabled'),
      campaignVar: flagProp,
      campaignVarValue: flagEnabled
    });
  }

  setMenuFlag(flag: string, label?: string): void {
    const flagProp = `menu${flag}Enabled`;
    const flagEnabled = this.campaign[flagProp];
    const flagName =
      label ??
      flag
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase();
    this.confirmAction({
      title: `Are you sure you want to ${flagEnabled ? 'enable' : 'disable'} menu ${flagName}?`,
      confirmText: `${flagEnabled ? 'ENABLE' : 'DISABLE'} ${flagName.toUpperCase()}`,
      url: '/api/campaigns/' + this.campaign.campaign_id + '/menu' + flag,
      data: flagEnabled,
      method: 'PUT',
      successMessage: flagName + ' is now ' + (flagEnabled ? 'enabled' : 'disabled'),
      campaignVar: flagProp,
      campaignVarValue: flagEnabled
    });
  }

  async showTransferOwner(campaign): Promise<void> {
    const dialog = await this.ownerTransferDialog.open(campaign);
    dialog.afterClosed().subscribe((admin) => {
      if (!admin) {
        // cancelled
        return;
      }

      this.http
        .put('/api/campaigns/' + this.campaignId + '/owner/' + admin.campaignUserId, {})
        .pipe(
          catchError((err, caught) => {
            this.snackbar
              .open('There was a problem transferring ownership', 'TRY AGAIN')
              .then((ref) => ref.onAction().subscribe(() => caught));

            throw err;
          })
        )
        .subscribe(() => {
          this.campaign.owner = {
            first_name: admin.firstName,
            last_name: admin.lastName,
            email: admin.email,
            fullName: admin.firstName + ' ' + admin.lastName
          };
          this.cd.markForCheck();
          this.snackbar.open('Ownership has been transferred to ' + admin.firstName);
        });
    });
  }

  saveFileSize(): void {
    this.http
      .put('api/campaigns/' + this.campaign.campaign_id + '/bridgeUploadLimit/' + this.campaign.bridgeUploadLimit, null)
      .subscribe(
        () => {
          this.snackbar.open('Settings have successfully saved');
        },
        (resp) => {
          // ignore cancelled
          if (resp.xhrStatus === 'abort') {
            return;
          }

          this.snackbar.open('Unable to save settings');
        }
      );
  }

  saveVideoSize(): void {
    this.http
      .put('api/campaigns/' + this.campaign.campaign_id + '/videoUploadLimit/' + this.campaign.videoUploadLimit, null)
      .subscribe(
        () => {
          this.snackbar.open('Settings have successfully saved');
        },
        (resp) => {
          // ignore cancelled
          if (resp.xhrStatus === 'abort') {
            return;
          }

          this.snackbar.open('Unable to save settings');
        }
      );
  }

  setSms(): void {
    if (this.campaign.smsEnabled) {
      this.confirmAction({
        title: 'Are you sure you want to enable SMS?',
        message: 'There are billing implications to this',
        confirmText: 'ENABLE SMS',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/smsEnabled',
        method: 'PUT',
        successMessage: this.campaign.title + ' has SMS enabled',
        campaignVar: 'smsEnabled',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to disable SMS?',
        message: 'All user SMS settings will be set to disabled and cannot be recovered.',
        confirmText: 'DISABLE SMS',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/smsEnabled',
        method: 'DELETE',
        successMessage: this.campaign.title + ' has SMS disabled',
        campaignVar: 'smsEnabled',
        campaignVarValue: false
      });
    }
  }

  setPrivate(): void {
    if (this.campaign.private) {
      this.confirmAction({
        title: 'Are you sure you want to make the app private?',
        confirmText: 'MAKE PRIVATE',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/private',
        method: 'PUT',
        successMessage: this.campaign.title + ' is now private',
        campaignVar: 'private',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to make the app public?',
        confirmText: 'MAKE PUBLIC',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/private',
        method: 'DELETE',
        successMessage: this.campaign.title + ' is now public',
        campaignVar: 'private',
        campaignVarValue: false
      });
    }
  }

  setAllowFacebook(): void {
    if (this.campaign.allowFacebookLogin) {
      this.confirmAction({
        title: 'Are you sure you want to allow Facebook login?',
        confirmText: 'ALLOW FACEBOOK LOGIN',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/allowFacebookLogin',
        method: 'PUT',
        successMessage: this.campaign.title + ' now allows Facebook login',
        campaignVar: 'allowFacebookLogin',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to disable Facebook login?',
        confirmText: 'DISABLE FACEBOOK LOGIN',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/allowFacebookLogin',
        method: 'DELETE',
        successMessage: this.campaign.title + ' does not allow Facebook login',
        campaignVar: 'allowFacebookLogin',
        campaignVarValue: false
      });
    }
  }

  setAllowGoogle(): void {
    if (this.campaign.allowGoogleLogin) {
      this.confirmAction({
        title: 'Are you sure you want to allow Google login?',
        confirmText: 'ALLOW GOOGLE LOGIN',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/allowGoogleLogin',
        method: 'PUT',
        successMessage: this.campaign.title + ' now allows Google login',
        campaignVar: 'allowGoogleLogin',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to allow Google login?',
        confirmText: 'DISABLE GOOGLE LOGIN',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/allowGoogleLogin',
        method: 'DELETE',
        successMessage: this.campaign.title + ' does not allow Google login',
        campaignVar: 'allowGoogleLogin',
        campaignVarValue: false
      });
    }
  }

  setAllowLinkedIn(): void {
    if (this.campaign.allowLinkedInLogin) {
      this.confirmAction({
        title: 'Are you sure you want to allow LinkedIn login?',
        confirmText: 'ALLOW LINKEDIN LOGIN',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/allowLinkedInLogin',
        method: 'PUT',
        successMessage: this.campaign.title + ' now allows LinkedIn login',
        campaignVar: 'allowLinkedInLogin',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to allow LinkedIn login?',
        confirmText: 'DISABLE LINKEDIN LOGIN',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/allowLinkedInLogin',
        method: 'DELETE',
        successMessage: this.campaign.title + ' does not allow LinkedIn login',
        campaignVar: 'allowLinkedInLogin',
        campaignVarValue: false
      });
    }
  }

  setTestApp(): void {
    if (!this.campaign.testApp) {
      this.confirmAction({
        title: 'Are you sure you want to convert this campaign into a client app?',
        confirmText: 'MAKE CLIENT APP',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/testApp',
        method: 'DELETE',
        successMessage: this.campaign.title + ' is now CLIENT APP',
        campaignVar: 'testApp',
        campaignVarValue: false
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to convert this campaign into a test app?',
        confirmText: 'MAKE TEST APP',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/testApp',
        method: 'PUT',
        successMessage: this.campaign.title + ' is now a TEST APP',
        campaignVar: 'testApp',
        campaignVarValue: true
      });
    }
  }

  setDemo(): void {
    if (!this.campaign.demo) {
      this.confirmAction({
        title: 'Are you sure you want to convert this campaign into a client app?',
        message: 'The demo app banner will be removed.',
        confirmText: 'MAKE LIVE',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/demo',
        method: 'DELETE',
        successMessage: this.campaign.title + ' is now LIVE',
        campaignVar: 'demo',
        campaignVarValue: false
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to convert this campaign into a demo?',
        confirmText: 'MAKE DEMO APP',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/demo',
        method: 'PUT',
        successMessage: this.campaign.title + ' is now a DEMO APP',
        campaignVar: 'demo',
        campaignVarValue: true
      });
    }
  }

  setTemplate(): void {
    if (!this.campaign.isTemplate) {
      this.confirmAction({
        title: 'Are you sure you want to convert this HUB into a normal app?',
        message: 'This HUB will no longer be a template app',
        confirmText: 'MAKE LIVE',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/template',
        method: 'DELETE',
        successMessage: this.campaign.title + ' is now LIVE',
        campaignVar: 'isTemplate',
        campaignVarValue: false
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to convert this HUB into a template?',
        confirmText: 'MAKE TEMPLATE HUB',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/template',
        method: 'PUT',
        successMessage: this.campaign.title + ' is now a TEMPLATE HUB',
        campaignVar: 'isTemplate',
        campaignVarValue: true
      });
    }
  }

  setDisableRegistration(): void {
    if (this.campaign.disableRegistration) {
      this.confirmAction({
        title: 'Are you sure you want to disable registration on this HUB?',
        message: 'This HUB will no longer allow registrations',
        confirmText: 'Lock Registration',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/disableRegistration',
        method: 'PUT',
        successMessage: 'Registration is disabled on ' + this.campaign.title,
        campaignVar: 'disableRegistration',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want to enable registration on this HUB?',
        confirmText: 'Unlock Registrations',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/disableRegistration',
        method: 'DELETE',
        successMessage: 'Registration is enabled on ' + this.campaign.title,
        campaignVar: 'disableRegistration',
        campaignVarValue: false
      });
    }
  }

  setSandbox(): void {
    if (this.campaign.useSandbox) {
      this.confirmAction({
        title: 'Are you sure you want to force this app to use the Stripe sandbox?',
        confirmText: 'USE SANDBOX',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/useSandbox',
        method: 'PUT',
        successMessage: this.campaign.title + ' is now using the sandbox',
        campaignVar: 'useSandbox',
        campaignVarValue: true
      });
    } else {
      this.confirmAction({
        title: 'Are you sure you want switch this app to use Stripe live mode?',
        confirmText: 'USE LIVE MODE',
        url: '/api/campaigns/' + this.campaign.campaign_id + '/useSandbox',
        method: 'DELETE',
        successMessage: this.campaign.title + ' is now in live mode',
        campaignVar: 'useSandbox',
        campaignVarValue: false
      });
    }
  }

  setActive(): void {
    if (!this.campaign.active) {
      this.confirmAction({
        title: `Are you sure you want to deactivate ${this.campaign.title}?`,
        confirmText: 'DEACTIVATE APP',
        url: '/api/campaigns/disable/' + this.campaign.campaign_id,
        method: 'PUT',
        successMessage: this.campaign.title + ' is now differently abled',
        campaignVar: 'active',
        campaignVarValue: false
      });
    } else {
      this.http.request('PUT', '/api/campaigns/enable/' + this.campaignId).subscribe(() => {
        this.snackbar.open("It's up and running! I hope you're happy...");
        this.campaign.active = true; // for the ng-if
      });
    }
  }

  private isDeleting = signal(false);

  async onDelete(): Promise<void> {
    if (this.isDeleting()) return;
    const dialog = await this.confirmDialog.open({
      title: 'Delete HUB',
      message:
        'Are you sure you want to delete this HUB? This action cannot be undone.<br><br>All channels, posts, users, and data will be permanently deleted.',
      confirmText: 'DELETE',
      cancelText: 'CANCEL',
      confirmColor: 'warn'
    });

    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.isDeleting.set(true);
        this.http
          .delete(`/api/campaigns/${this.campaign.campaign_id}/redis`, {})
          .pipe(
            catchError((err) => {
              this.isDeleting.set(false);
              this.snackbar.open('Unable to clear redis cache', 'Close', { duration: 3000 });
              return throwError(() => err);
            }),
            switchMap(() => {
              return this.http.delete(`/api/campaigns/${this.campaign.campaign_id}`).pipe(
                catchError((err) => {
                  this.snackbar.open('Error deleting the HUB.', 'Close', { duration: 3000 });
                  return throwError(() => err);
                })
              );
            })
          )
          .subscribe({
            next: () => {
              this.isDeleting.set(false);
              this.snackbar.open(
                `HUB ${this.campaign.orgName} has been deleted and redis cleared successfully.`,
                'Close',
                {
                  duration: 3000
                }
              );
              this.router.navigate(['/campaigns']);
            },
            error: (error) => {
              this.isDeleting.set(false);
              console.error('Error:', error);
            }
          });
      } else {
        console.log('Hub delete canceled');
      }
    });
  }

  private async confirmAction(options: ConfirmOptions): Promise<void> {
    const dialog = await this.confirmDialog.open({
      title: options.title,
      message: options.message,
      confirmText: options.confirmText
    });
    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        if (options.campaignVar) {
          this.campaign[options.campaignVar] = options.campaignVarOriginalValue ?? !options.campaignVarValue;
          this.cd.markForCheck();
        }
        return;
      }

      this.http
        .request(options.method, options.url, {
          body: options.data,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .subscribe(() => {
          this.snackbar.open(options.successMessage);
          if (options.campaignVar) {
            this.campaign[options.campaignVar] = options.campaignVarValue;
          }
        });
    });
  }
}
