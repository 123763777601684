<div class="phone-container" ng-if="$ctrl.currentUser.roleInfo.userRoleId < -1">
  <div class="block-header">
    <h2 class="text-center" id="client-name"></h2>
  </div>

  <form [formGroup]="phoneForm" (ngSubmit)="call()">
    <div class="pad">
      <div class="dial-pad">
        <div #log id="log">
          @for (logItem of logs(); track logItem.time) {
            <div class="log" [ngClass]="logItem.type">
              <span class="log-time">[{{ logItem.time | date: 'shortTime' }}]&nbsp;</span>
              <span class="log-message">{{ logItem.message }}</span>
            </div>
          }
        </div>
        <div class="phone-input-container">
          <input
            class="form-control"
            formControlName="number"
            type="tel"
            placeholder="Enter a phone #"
            autocomplete="off"
            (keypress)="phoneKeyPress($event)"
          />
        </div>
        <div class="digits">
          <button mat-icon-button type="button" class="dig pound number-dig" (click)="sendDigit('1')">1</button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('2')">
            2
            <div class="sub-dig">ABC</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('3')">
            3
            <div class="sub-dig">DEF</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('4')">
            4
            <div class="sub-dig">GHI</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('5')">
            5
            <div class="sub-dig">JKL</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('6')">
            6
            <div class="sub-dig">MNO</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('7')">
            7
            <div class="sub-dig">PQRS</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('8')">
            8
            <div class="sub-dig">TUV</div>
          </button>
          <button mat-icon-button type="button" class="dig number-dig" (click)="sendDigit('9')">
            9
            <div class="sub-dig">WXYZ</div>
          </button>
          <button type="button" mat-icon-button class="dig number-dig astrisk" (click)="sendDigit('*')">*</button>
          <button type="button" mat-icon-button class="dig number-dig pound" (click)="sendDigit('0')">0</button>
          <button type="button" mat-icon-button class="dig number-dig pound" (click)="sendDigit('#')">#</button>
        </div>

        <button mat-icon-button class="call action-dig" type="submit" [class.in-call]="connection()">
          <div class="call-change"><span></span></div>
          <mat-icon class="call-icon">
            <svg id="phone" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg>
          </mat-icon>
        </button>

        @if (incoming()) {
          <button mat-flat-button type="button" (click)="reject()">Reject</button>
        }
      </div>
    </div>
  </form>
</div>
<div class="welcome-container">
  <span class="joke-box" ng-bind-html="$ctrl.randomJoke | toTrustedHtml"></span>
</div>
