<section class="app-tracking-code">
  <header class="app-tracking-code-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="app-tracking-code-icon">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
            />
          </svg>
        </mat-icon>
        <div class="app-tracking-code-title">Tracking code</div>
      </div>
      <div class="save-btn-div fx-layout-align-end-center">
        <button
          class="save-btn"
          mat-raised-button
          color="primary"
          (click)="saveSettings()"
          [disabled]="savingSettings$ | async"
        >
          Save
        </button>
      </div>
    </div>
  </header>

  <div class="app-tracking-code-form-container">
    <form [formGroup]="bridgeSettingsForm" novalidate *ngIf="campaign">
      <div class="fx-layout-column fx-layout-align-start-stretch">
        <!--Tracking Code Section-->
        <div class="app-tracking-code section-block fx-layout-column fx-layout-align-start-stretch">
          <div class="app-tracking-code-textarea">
            <textarea
              id="jsEditor"
              formControlName="bridgeFooterCode"
              placeholder="Paste your tracking code scripts here..."
              data-lpignore="true"
            >
            </textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
