<ng-container *ngIf="selectedConversation$ | async as selectedConversation">
  <mat-toolbar color="primary" class="fx-layout-row">
    <button class="toggle-menu-button" mat-icon-button (click)="toggleMenu.emit()">
      <mat-icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>list-box-outline</title>
          <path
            d="M11 15H17V17H11V15M9 7H7V9H9V7M11 13H17V11H11V13M11 9H17V7H11V9M9 11H7V13H9V11M21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5M19 5H5V19H19V5M9 15H7V17H9V15Z"
          />
        </svg>
      </mat-icon>
    </button>

    <span class="fx-flex">{{ selectedConversation.title }}</span>
    <button mat-icon-button (click)="deleted.emit(selectedConversation)">
      <mat-icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>trash-can-outline</title>
          <path
            d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
          />
        </svg>
      </mat-icon>
    </button>
  </mat-toolbar>
  <div #messagesContainer class="messages-container">
    <div class="no-messages-message" *ngIf="selectedConversation.messages.length === 0">
      <span class="message-text">No messages yet</span>
    </div>
    <div
      *ngFor="let message of selectedConversation.messages"
      class="message"
      [ngClass]="{ 'user-message': message.role === 'user', 'bot-message': message.role === 'assistant' }"
    >
      <span class="user-avatar">
        <svg *ngIf="message.role == 'user'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>account-outline</title>
          <path
            d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"
          />
        </svg>
        <svg *ngIf="message.role == 'assistant'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>cog-outline</title>
          <path
            d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z"
          />
        </svg>
      </span>
      <span class="message-text fx-flex" [innerHTML]="message.content | lbToBr | safe"></span>
      <button mat-icon-button *ngIf="false && message.role === 'user'" (click)="startEditMessage(message)">
        <mat-icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>text-box-edit-outline</title>
            <path
              d="M10 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V10.33C20.7 10.21 20.37 10.14 20.04 10.14C19.67 10.14 19.32 10.22 19 10.37V5H5V19H10.11L10 19.11V21M7 9H17V7H7V9M7 17H12.11L14 15.12V15H7V17M7 13H16.12L17 12.12V11H7V13M21.7 13.58L20.42 12.3C20.21 12.09 19.86 12.09 19.65 12.3L18.65 13.3L20.7 15.35L21.7 14.35C21.91 14.14 21.91 13.79 21.7 13.58M12 22H14.06L20.11 15.93L18.06 13.88L12 19.94V22Z"
            />
          </svg>
        </mat-icon>
      </button>
      <div *ngIf="editingMessage === message">
        <textarea [formControl]="editMessage"></textarea>
        <button mat-button (click)="submitEditMessage()">Submit Edit</button>
        <button mat-button (click)="cancelEditMessage()">Cancel</button>
      </div>
    </div>
    <div id="scrollAnchor"></div>
  </div>
  <div class="input-container">
    <ih-animated-save-button
      *ngIf="(selectedConversation?.messages)!.length > 1"
      class="regenerate-button"
      mat-stroked-button
      color="accent"
      [saving]="(regeneratingResponse$ | async)!"
      (click)="regenerateResponse()"
    >
      Regenerate
      <ng-container savingText>Regenerating</ng-container>
    </ih-animated-save-button>
    <form [formGroup]="newMessageForm" (ngSubmit)="submit()">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="new-message-form-field fx-flex">
        <textarea matInput cdkTextareaAutosize formControlName="text" (keydown)="onKeyDown($event)"></textarea>
        <button matSuffix mat-icon-button color="primary" [disabled]="!newMessageForm.value.text">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>send</title>
            <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
          </svg>
        </button>
      </mat-form-field>
    </form>
  </div>
</ng-container>
