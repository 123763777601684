import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AngularjsLazyLoaderService } from '../services/angularjs-lazy-loader.service';

@Component({
  selector: 'app-angular-js',
  template: '<div ng-controller="ApplicationController"><div ng-view></div></div>'
})
export class AngularjsComponent implements OnInit, OnDestroy {
  constructor(
    private lazyLoader: AngularjsLazyLoaderService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.lazyLoader.load(this.elRef.nativeElement);
  }

  ngOnDestroy() {
    this.lazyLoader.destroy();
  }
}
