<section id="wrapper" *ngIf="config.config$ | async as config">
  <section class="top-and-side-nav">
    <div class="navbar navbar-default navbar-fixed-top builder-header fx-layout-row fx-layout-align-start-center">
      @if (currentUser()) {
        <button id="menu-toggle" type="button" class="navbar-toggle" (click)="toggleMenu($event)">
          <div class="line-wrap">
            <div class="line top"></div>
            <div class="line center"></div>
            <div class="line bottom"></div>
          </div>
        </button>
      }
      <a class="navbar-brand" routerLink="/">
        <div>HUB Bridge</div>
      </a>
      <span class="fx-flex"></span>
      @if (currentUser()) {
        <button
          mat-icon-button
          class="update-button"
          color="primary"
          (click)="showUpdateDialog()"
          *ngIf="updateAvailable$ | async"
          aria-label="update app"
        >
          <mat-icon aria-label="update icon">
            <svg viewBox="0 0 24 24">
              <path
                d="M21,10.12H14.22L16.96,7.3C14.23,4.6 9.81,4.5 7.08,7.2C4.35,9.91 4.35,14.28 7.08,17C9.81,19.7 14.23,19.7 16.96,17C18.32,15.65 19,14.08 19,12.1H21C21,14.08 20.12,16.65 18.36,18.39C14.85,21.87 9.15,21.87 5.64,18.39C2.14,14.92 2.11,9.28 5.62,5.81C9.13,2.34 14.76,2.34 18.27,5.81L21,3V10.12M12.5,8V12.25L16,14.33L15.28,15.54L11,13V8H12.5Z"
              />
            </svg>
          </mat-icon>
        </button>
        <a id="iconBtnLogout" mat-icon-button color="primary" routerLink="/account/logout">
          <mat-icon>
            <svg id="logout-variant" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
              />
            </svg>
          </mat-icon>
        </a>
      }
    </div>
    @if (currentUser(); as currentUser) {
      <div id="sidebar-wrapper" class="fx-layout-column">
        <ul class="sidebar-nav fx-flex">
          <li class="sidebar-nav-profile fx-layout-row">
            <div class="pull-left sidebar-nav-image-wrapper fx-flex">
              <ih-image class="img-circle" [imageInfo]="currentUser.image"></ih-image>
            </div>
            <div class="pull-left sidebar-nav-info-wrapper">
              <div class="sidebar-nav-name" title="{{ currentUser.firstName + ' ' + currentUser.lastName }} ">
                {{ currentUser.firstName + ' ' + currentUser.lastName }}
                {{ currentUser.email }}
              </div>
              <div class="sidebar-nav-role" title="{{ currentUser.roleInfo.name }}">
                {{ currentUser.roleInfo.name }}
              </div>
            </div>
            <div class="clearfix"></div>
          </li>

          <li>
            <a href="" (click)="showUpdateDialog($event)" *ngIf="updateAvailable$ | async">
              <mat-icon aria-label="update icon">
                <svg viewBox="0 0 24 24">
                  <path
                    d="M21,10.12H14.22L16.96,7.3C14.23,4.6 9.81,4.5 7.08,7.2C4.35,9.91 4.35,14.28 7.08,17C9.81,19.7 14.23,19.7 16.96,17C18.32,15.65 19,14.08 19,12.1H21C21,14.08 20.12,16.65 18.36,18.39C14.85,21.87 9.15,21.87 5.64,18.39C2.14,14.92 2.11,9.28 5.62,5.81C9.13,2.34 14.76,2.34 18.27,5.81L21,3V10.12M12.5,8V12.25L16,14.33L15.28,15.54L11,13V8H12.5Z"
                  />
                </svg>
              </mat-icon>
              <span>Update available</span>
            </a>
          </li>

          <li class="sidenav-item sub-menu">
            <a href="" (click)="toggleParent($event)">
              <mat-icon>
                <svg id="account-box" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M6,17C6,15 10,13.9 12,13.9C14,13.9 18,15 18,17V18H6M15,9A3,3 0 0,1 12,12A3,3 0 0,1 9,9A3,3 0 0,1 12,6A3,3 0 0,1 15,9M3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5C3.89,3 3,3.9 3,5Z"
                  />
                </svg>
              </mat-icon>
              <span class="fx-layout-align-start-center fx-flex">
                <span>My profile&nbsp;&nbsp;</span>
              </span>

              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                </svg>
              </mat-icon>
            </a>
            <ul class="sub">
              <!--Security settings-->
              <li class="sidenav-item">
                <a
                  class="profile-nav-list-item nav-text-color"
                  routerLink="/account/security"
                  routerLinkActive="active"
                  matRipple
                  trackClick="Security settings clicked"
                  >Security settings</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a routerLink="/apps" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z"
                  />
                </svg>
              </mat-icon>
              <span>HUBs</span>
            </a>
          </li>

          <li>
            <a routerLink="/hubUsers" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <mat-icon svgIcon="account-box-multiple"></mat-icon>
              <span>HUB users</span>
            </a>
          </li>

          <ng-container *ngIf="currentApp$ | async as currentApp">
            <!-- HUB NAME -->
            <div class="sidebar-nav-hub-name">
              <span class="fx-flex">{{ currentApp.name }}</span>

              <button mat-icon-button aria-label="Open settings menu" [matMenuTriggerFor]="menu">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                    />
                  </svg>
                </mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="clearRedis(currentApp.campaignId)">
                  <mat-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <title>bulldozer</title>
                      <path
                        d="M4,4A1,1 0 0,0 3,5V10C2.54,10 2.14,10.31 2.03,10.76V13.97H2.29C2.65,13.37 3.3,13 4,13H13C13.7,13 14.35,13.37 14.71,13.97H16.03L16,11V11A1,1 0 0,0 15,10H13V8A1,1 0 0,0 12,7A1,1 0 0,0 11,8V10H9V5A1,1 0 0,0 8,4H4M5,6H7V10L7,11H5V6M17,11V19H22V18L19,17L18,11H17M4,15A2,2 0 0,0 2,17A2,2 0 0,0 4,19H13A2,2 0 0,0 15,17A2,2 0 0,0 13,15H4Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Clear redis</span>
                </button>

                <button mat-menu-item (click)="flush(currentApp.campaignId)">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M9,22H17V19.5C19.41,17.87 21,15.12 21,12V4A2,2 0 0,0 19,2H15C13.89,2 13,2.9 13,4V12H3C3,15.09 5,18 9,19.5V22M5.29,14H18.71C18.14,15.91 16.77,17.5 15,18.33V20H11V18.33C9,18 5.86,15.91 5.29,14M15,4H19V12H15V4M16,5V8H18V5H16Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Flush app</span>
                </button>

                <button mat-menu-item (click)="toggleArchived(currentApp)">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path fill="currentColor" d="M3 3H21V7H3V3M4 21V8H20V21H4M14 14V11H10V14H7L12 19L17 14H14Z" />
                    </svg>
                  </mat-icon>
                  <span *ngIf="currentApp.archived">Restore</span>
                  <span *ngIf="!currentApp.archived">Archive</span>
                </button>
              </mat-menu>
            </div>

            <div class="sidebar-nav-hub-container">
              <!-- HUB SETTINGS -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'settings']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Settings</span>
                </a>
              </li>
              <!-- HUB MEMBERS -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'members']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Members</span>
                </a>
              </li>
              <!-- HUB CHANNELS -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'channels']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5.41,21L6.12,17H2.12L2.47,15H6.47L7.53,9H3.53L3.88,7H7.88L8.59,3H10.59L9.88,7H15.88L16.59,3H18.59L17.88,7H21.88L21.53,9H17.53L16.47,15H20.47L20.12,17H16.12L15.41,21H13.41L14.12,17H8.12L7.41,21H5.41M9.53,9L8.47,15H14.47L15.53,9H9.53Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Channels</span>
                </a>
              </li>
              <!-- HUB MENU -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'menu']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5,9.5L7.5,14H2.5L5,9.5M3,4H7V8H3V4M5,20A2,2 0 0,0 7,18A2,2 0 0,0 5,16A2,2 0 0,0 3,18A2,2 0 0,0 5,20M9,5V7H21V5H9M9,19H21V17H9V19M9,13H21V11H9V13Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Menu editor</span>
                </a>
              </li>
              <!-- HUB CUSTOM CSS -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'customCss']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M8,3A2,2 0 0,0 6,5V9A2,2 0 0,1 4,11H3V13H4A2,2 0 0,1 6,15V19A2,2 0 0,0 8,21H10V19H8V14A2,2 0 0,0 6,12A2,2 0 0,0 8,10V5H10V3M16,3A2,2 0 0,1 18,5V9A2,2 0 0,0 20,11H21V13H20A2,2 0 0,0 18,15V19A2,2 0 0,1 16,21H14V19H16V14A2,2 0 0,1 18,12A2,2 0 0,1 16,10V5H14V3H16Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Custom CSS</span>
                </a>
              </li>
              <!-- HUB FOOTER CODE -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'trackingCode']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Footer code</span>
                </a>
              </li>
              <!-- HUB FROALA OPTIONS -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'froalaOptions']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path fill="currentColor" d="M10,11A4,4 0 0,1 6,7A4,4 0 0,1 10,3H18V5H16V21H14V5H12V21H10V11Z" />
                    </svg>
                  </mat-icon>
                  <span>Froala options</span>
                </a>
              </li>
              <!-- HUB CUSTOM EULA -->
              <li>
                <a [routerLink]="['apps', currentApp.campaignId, 'eula']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Custom EULA</span>
                </a>
              </li>
              <!-- HUB SMS -->
              <li *ngIf="config.environment.instance === 'US'">
                <a [routerLink]="['apps', currentApp.campaignId, 'sms']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M13 11H11V5H13M13 15H11V13H13M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>SMS Log</span>
                </a>
              </li>
              <!-- ASSIGN BRIDGE USERS -->
              <li *ngIf="isBridgeOfficer()">
                <a [routerLink]="['apps', currentApp.campaignId, 'assignBridgeUsers']" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M8 10H5V7H3V10H0V12H3V15H5V12H8Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Assign bridge users</span>
                </a>
              </li>
            </div>

            <mat-divider class="sidebar-nav-divider"></mat-divider>
          </ng-container>

          <li *ngIf="isBridgeOfficer()">
            <a routerLink="/users" routerLinkActive="active">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z"
                  />
                </svg>
              </mat-icon>
              <span>Bridge users</span>
            </a>
          </li>

          <li *ngIf="isBridgeOfficer()">
            <a routerLink="/updates" routerLinkActive="active">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M7.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,18A1.5,1.5 0 0,0 9,16.5A1.5,1.5 0 0,0 7.5,15M6,10V12A6,6 0 0,1 12,18H14A8,8 0 0,0 6,10M6,6V8A10,10 0 0,1 16,18H18A12,12 0 0,0 6,6Z"
                  />
                </svg>
              </mat-icon>
              <span>Builder updates</span>
            </a>
          </li>

          <li class="sub-menu" *ngIf="isBridgeOfficer()">
            <a href="" (click)="toggleParent($event)">
              <mat-icon matTooltip="BEHOLD!">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M11,12H8.82C9.62,12.5 10.35,13.07 11,13.68V12M7,11C7.27,5.88 9.37,2 12,2C14.66,2 16.77,5.94 17,11.12C18.5,10.43 20.17,10 22,10C16.25,12.57 18.25,22 12,22C6,22 7.93,12.57 2,10C3.82,10 5.5,10.4 7,11M11,11V9H8.24L8.03,11H11M11,8V6H9.05C8.8,6.6 8.6,7.27 8.43,8H11M11,5V3.3C10.45,3.63 9.95,4.22 9.5,5H11M12,3V5H13V6H12V8H14V9H12V11H15V12H12V14H14V15H12.23C13.42,16.45 14.15,18 14.32,19.23C15.31,17.56 15.96,14.84 16,11.76C15.94,7 14.13,3 12,3Z"
                  />
                </svg>
              </mat-icon>
              <span class="fx-layout-align-start-center fx-flex">
                <span>IHUB Fun Stuff&nbsp;&nbsp;</span>
              </span>
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                </svg>
              </mat-icon>
            </a>

            <ul class="navmenu-nav sub">
              <li>
                <a routerLink="/reactions" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5,14L8.5,9.5L11,12.5L14.5,8L19,14M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Reactions</span>
                </a>
              </li>

              <li>
                <a routerLink="/responses" routerLinkActive="active">
                  <mat-icon>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Responses</span>
                </a>
              </li>

              <li>
                <a routerLink="/conversations" routerLinkActive="active">
                  <mat-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <title>message-flash-outline</title>
                      <path
                        d="M4 17.2V4H20V10H22V4C22 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V22L6 18H15V16H5.2L4 17.2M22.5 16H20.3L22 12H17V18H19V23L22.5 16Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Conversations</span>
                </a>
              </li>

              <li>
                <a routerLink="/dalle" routerLinkActive="active">
                  <mat-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <title>image-auto-adjust</title>
                      <path
                        d="M19 10V19H5V5H14V3H5C3.92 3 3 3.9 3 5V19C3 20.1 3.92 21 5 21H19C20.12 21 21 20.1 21 19V10H19M17 10L17.94 7.94L20 7L17.94 6.06L17 4L16.06 6.06L14 7L16.06 7.94L17 10M13.25 10.75L12 8L10.75 10.75L8 12L10.75 13.25L12 16L13.25 13.25L16 12L13.25 10.75Z"
                      />
                    </svg>
                  </mat-icon>
                  <span>Generate image</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="isBridgeOfficer()">
            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </mat-icon>
              <span>Phone</span>
            </a>
          </li>

          <li *ngIf="isBridgeOfficer()">
            <a routerLink="/searchCustomizations" routerLinkActive="active">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </mat-icon>
              <span>Edit Customizations</span>
            </a>
          </li>

          <li>
            <a routerLink="/account/logout" routerLinkActive="active">
              <mat-icon>
                <svg id="logout-variant" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
                  />
                </svg>
              </mat-icon>
              <span>Logout</span>
            </a>
          </li>
        </ul>
        <button class="sidenav-item footer version-info" (click)="showDeviceInfo()">
          {{ version() }}
        </button>
      </div>
    }
    <!--header end-->
  </section>

  <section id="main-content">
    <div id="pageTop"></div>
    <router-outlet></router-outlet>
    <div ng-view ng-controller="ApplicationController"></div>
  </section>
</section>
