import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmDialogService } from '@ih/confirm';
import { ImageComponent } from '@ih/image';
import { LazySnackBarService } from '@ih/services';
import { BehaviorSubject, Subject, merge, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { AddBridgeUserDialogService } from '../add-bridge-user-dialog/add-bridge-user-dialog.service';
import { BridgeUserAddAppDialogService } from '../bridge-user-add-app-dialog/bridge-user-add-app-dialog.service';
import { BridgeUserEditorDialogService } from '../bridge-user-editor/bridge-user-editor-dialog.service';

@Component({
  selector: 'ih-bridge-user-list',
  templateUrl: './bridge-user-list.component.html',
  styleUrls: ['./bridge-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,

    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    ReactiveFormsModule,

    ImageComponent
  ]
})
export class BridgeUserListComponent implements OnDestroy {
  @HostBinding('class.ih-bridge-user-list') hostClass = true;

  query = new UntypedFormControl('');

  showActive = true;
  refresh$ = new Subject<void>();
  loading$ = new BehaviorSubject<boolean>(false);

  userId = 3;

  users$ = merge(this.query.valueChanges.pipe(distinctUntilChanged(), debounceTime(600)), this.refresh$).pipe(
    startWith({}),
    tap(() => this.loading$.next(true)),
    switchMap(() =>
      this.http.get<any[]>('/api/users', {
        params: {
          q: this.query.value,
          active: this.showActive
        }
      })
    ),
    tap(() => this.loading$.next(false)),
    catchError((err, caught) => {
      console.error(err);

      this.snackbar.open('Unable to load users', 'TRY AGAIN').then((ref) => ref.onAction().subscribe(() => caught));

      return of([]);
    })
  );

  private destroy$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private snackbar: LazySnackBarService,
    private bridgeUserAddAppService: BridgeUserAddAppDialogService,
    private confirmDialog: ConfirmDialogService,
    private bridgeUserEditorDialog: BridgeUserEditorDialogService,
    private addBridgeUserDialog: AddBridgeUserDialogService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  clearQuery(): void {
    this.query.setValue('');
  }

  toggleShowActive(): void {
    this.showActive = !this.showActive;
    this.refresh$.next();
  }

  async showBridgeUserEditor(event: Event, id: number): Promise<void> {
    // prevent the row from toggling
    event.stopPropagation();

    const dialog = await this.bridgeUserEditorDialog.open(id);
    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh$.next();
      }
    });
  }

  async showAddBridgeUser(): Promise<void> {
    const dialog = await this.addBridgeUserDialog.open();
    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh$.next();
      }
    });
  }

  async showAddApp(userId: number): Promise<void> {
    const dialog = await this.bridgeUserAddAppService.open(userId);
    dialog.afterClosed().subscribe((saved) => {
      if (saved) {
        this.refresh$.next();
      }
    });
  }

  async removeApp(userId: number, campaignId: number, name: string): Promise<void> {
    const dialog = await this.confirmDialog.open({
      title: 'Remove App?',
      message: `Are you sure you want to remove ${name}?`,
      confirmText: 'REMOVE'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.http.delete(`/api/users/${userId}/apps/${campaignId}`).subscribe(() => {
          this.snackbar.open('Removed app');
          this.refresh$.next();
        });
      }
    });
  }
}
