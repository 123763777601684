"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @packageDocumentation
 * @module Voice
 */
var constants_1 = require("./constants");
var errors_1 = require("./errors");
var log_1 = require("./log");
var DEFAULT_TEST_SOUND_URL = constants_1.SOUNDS_BASE_URL + "/outgoing.mp3";
/**
 * A smart collection containing a Set of active output devices.
 * @publicapi
 */
var OutputDeviceCollection = /** @class */function () {
  /**
   * @private
   */
  function OutputDeviceCollection(_name, _availableDevices, _beforeChange, _isSupported) {
    this._name = _name;
    this._availableDevices = _availableDevices;
    this._beforeChange = _beforeChange;
    this._isSupported = _isSupported;
    /**
     * The currently active output devices.
     */
    this._activeDevices = new Set();
    /**
     * An instance of Logger to use.
     */
    this._log = new log_1.default('OutputDeviceCollection');
  }
  /**
   * Delete a device from the collection. If no devices remain, the 'default'
   * device will be added as the sole device. If no `default` device exists,
   * the first available device will be used.
   * @param device - The device to delete from the collection
   * @returns whether the device was present before it was deleted
   */
  OutputDeviceCollection.prototype.delete = function (device) {
    this._log.debug('.delete', device);
    var wasDeleted = !!this._activeDevices.delete(device);
    var defaultDevice = this._availableDevices.get('default') || Array.from(this._availableDevices.values())[0];
    if (!this._activeDevices.size && defaultDevice) {
      this._activeDevices.add(defaultDevice);
    }
    // Call _beforeChange so that the implementation can react when a device is
    // removed or lost.
    var deviceIds = Array.from(this._activeDevices.values()).map(function (deviceInfo) {
      return deviceInfo.deviceId;
    });
    this._beforeChange(this._name, deviceIds);
    return !!wasDeleted;
  };
  /**
   * Get the current set of devices.
   */
  OutputDeviceCollection.prototype.get = function () {
    return this._activeDevices;
  };
  /**
   * Replace the current set of devices with a new set of devices.
   * @param deviceIdOrIds - An ID or array of IDs of devices to replace the existing devices with.
   * @returns Rejects if this feature is not supported, any of the supplied IDs are not found,
   * or no IDs are passed.
   */
  OutputDeviceCollection.prototype.set = function (deviceIdOrIds) {
    var _this = this;
    this._log.debug('.set', deviceIdOrIds);
    if (!this._isSupported) {
      return Promise.reject(new errors_1.NotSupportedError('This browser does not support audio output selection'));
    }
    var deviceIds = Array.isArray(deviceIdOrIds) ? deviceIdOrIds : [deviceIdOrIds];
    if (!deviceIds.length) {
      return Promise.reject(new errors_1.InvalidArgumentError('Must specify at least one device to set'));
    }
    var missingIds = [];
    var devices = deviceIds.map(function (id) {
      var device = _this._availableDevices.get(id);
      if (!device) {
        missingIds.push(id);
      }
      return device;
    });
    if (missingIds.length) {
      return Promise.reject(new errors_1.InvalidArgumentError("Devices not found: " + missingIds.join(', ')));
    }
    return new Promise(function (resolve) {
      resolve(_this._beforeChange(_this._name, deviceIds));
    }).then(function () {
      _this._activeDevices.clear();
      devices.forEach(_this._activeDevices.add, _this._activeDevices);
    });
  };
  /**
   * Test the devices by playing audio through them.
   * @param [soundUrl] - An optional URL. If none is specified, we will
   *   play a default test tone.
   * @returns Resolves with the result of the underlying HTMLAudioElements' play() calls.
   */
  OutputDeviceCollection.prototype.test = function (soundUrl) {
    if (soundUrl === void 0) {
      soundUrl = DEFAULT_TEST_SOUND_URL;
    }
    if (!this._isSupported) {
      return Promise.reject(new errors_1.NotSupportedError('This browser does not support audio output selection'));
    }
    if (!this._activeDevices.size) {
      return Promise.reject(new errors_1.InvalidStateError('No active output devices to test'));
    }
    return Promise.all(Array.from(this._activeDevices).map(function (device) {
      var el;
      // (rrowland) We need to wait for the oncanplay event because of a regression introduced
      // in Chrome M72: https://bugs.chromium.org/p/chromium/issues/detail?id=930876
      return new Promise(function (resolve) {
        el = new Audio(soundUrl);
        el.oncanplay = resolve;
      }).then(function () {
        return el.setSinkId(device.deviceId).then(function () {
          return el.play();
        });
      });
    }));
  };
  return OutputDeviceCollection;
}();
exports.default = OutputDeviceCollection;
