import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { LazySnackBarService, SignalRService } from '@ih/services';
import { catchError } from 'rxjs';

@Component({
  selector: 'ih-dall-e-prompt-generator',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    AnimatedSaveButtonComponent
  ],

  templateUrl: './dall-e-prompt-generator.component.html',
  styleUrls: ['./dall-e-prompt-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DallEPromptGeneratorComponent {
  private signalrService = inject(SignalRService);
  private snackbar = inject(LazySnackBarService);
  private http = inject(HttpClient);

  private defaultPrompt = `I want you to act as a prompt generator for DALL-E 2's artificial intelligence image generation program. Your job is to provide detailed and creative descriptions that will inspire unique and interesting images from the AI based on text I will provide. Keep in mind that the AI is capable of understanding a wide range of language and can interpret abstract concepts, so feel free to be as imaginative and descriptive as possible.

For example, you could describe a scene from a futuristic city, or a surreal landscape filled with strange creatures. The more detailed and imaginative your description, the more interesting the resulting image will be. You need to include what kind of visual style you have in mind, describe every little element that should appear in the image,  colors, and if there is a subject in the image, the emotion they are displaying, art direction like camera angles and lighting
as appropriate. Be as simple as concise as possible.

The prompt absolutely cannot exceed 400 characters. If the prompt does exceed 400 characters you must shorten it.

1. Take the text provided and summarize it.
2. Using the summarized text and generate a prompt for an appropriate image to accompany the summary.

User: A planet opens up and a huge gorilla starts throwing barrels at the spaceship. It dodges a few but one hits it and it explodes. The gorilla thumps its chest and 'Game Over' flashes on the screen. The spaceship and gorilla isn't real and Fry is playing an arcade game called 'Monkey Fracas Jr'. He wears a red jacket and has orange hair with two distinct forks at the front. A boy stands next to him. The game is against the wall of a pizzeria called Panucci's Pizza.

Prompt: Inside Panucci's Pizza, Fry, in his red jacket and distinctive forked orange hair, is engrossed in 'Monkey Fracas Jr'. The arcade game displays a vivid scene: a massive gorilla, emerging from a planet, hurls barrels at a spaceship. Amid dodges, one barrel hits, causing a fiery explosion. The triumphant gorilla thumps its chest under neon 'Game Over' signs. A curious boy stands by Fry, both reflected in the glass arcade screen.

Generate a prompt for the following:

[[Replace this with text you want to generate a prompt for here]]

`;

  promptSource = new FormControl(this.defaultPrompt, [Validators.required, Validators.minLength(1)]);
  prompt = new FormControl('', [Validators.required, Validators.minLength(1)]);

  promptImage = signal<string | null>(null);

  generatingPrompt = signal(false);
  generatingImage = signal(false);

  private conversationId: string | null = null;

  constructor() {
    this.signalrService.chatStream().subscribe((message) => {
      if (this.conversationId !== message.conversationId) {
        return;
      }

      // if the message is empty string, it means this is a new message
      if (message.content === '') {
        this.prompt.setValue('');
        return;
      }

      // if the prompt will start with Prompt or : then ignore the message
      const prompt = this.prompt.value + message.content;
      if (prompt.startsWith('Prompt') || prompt.startsWith(':') || message.content === null) {
        return;
      }

      this.prompt.setValue(prompt);
    });
  }

  generatePrompt(): void {
    this.conversationId = crypto.randomUUID();

    this.generatingPrompt.set(true);

    this.http
      .post<string>(
        '/api/openai/completions',
        {
          conversationId: this.conversationId,
          prompt: this.promptSource.value
        },
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      )
      .pipe(
        catchError((error) => {
          this.generatingPrompt.set(false);
          this.snackbar.open('Error generating prompt');
          throw error;
        })
      )
      .subscribe((conversationId) => {
        this.generatingPrompt.set(false);
      });
  }

  generateImage() {
    this.generatingImage.set(true);
    this.http
      .post<string>('/api/openai/imageCompletions', JSON.stringify(this.prompt.value), {
        headers: {
          'content-type': 'application/json'
        },
        responseType: 'text' as any
      })
      .pipe(
        catchError((error) => {
          this.generatingImage.set(false);
          this.snackbar.open('Error generating image');
          throw error;
        })
      )
      .subscribe((prompt) => {
        this.promptImage.set(prompt);
        this.generatingImage.set(false);
      });
  }
}
