import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { CreateAppDialogComponent } from './create-app-dialog.component';

@NgModule({ imports: [CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatCardModule,
        ScrollingModule,
        AnimatedSaveButtonComponent,
        CreateAppDialogComponent], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CreateAppDialogModule {}
