<section class="app-settings" *ngIf="campaign$ | async as campaign">
  <header class="app-settings-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="app-settings-icon">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
            />
          </svg>
        </mat-icon>
        <div class="app-settings-title">Settings</div>
      </div>
    </div>
  </header>

  <div class="app-settings-form-container">
    <mat-list>
      <!--HUB Information settings-->
      <div class="app-information-group section-block">
        <div class="subheader">HUB INFORMATION</div>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong>HUB Logo</strong>
            <div
              class="app-logo fx-layout-row fx-layout-align-center-center"
              style="height: 56px; width: 232px"
              [ngStyle]="{ 'background-color': campaign.primaryColor }"
            >
              <ih-image
                [imageInfo]="campaign.logoImage"
                [fallback]="campaign.logo_url"
                [aspectRatio]="!campaign.logoImage?.cropData ? 40 / 216 : undefined"
                style="width: 216px; height: 40px; padding: 0"
                alt="hub logo"
              />
            </div>
          </div>
          <div *ngIf="!campaign.active" class="app-delete-button" style="display: flex; align-items: center">
            <button mat-raised-button color="warn" (click)="onDelete()">Delete</button>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> Owner </strong>
            <mat-form-field class="app-information-owner-field">
              <input matInput [value]="campaign.owner.fullName" readonly />
              <button mat-icon-button matSuffix (click)="showTransferOwner(campaign)">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                  </svg>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> HUB URL </strong>
            <mat-form-field class="app-information-hub-url-field">
              <input
                matInput
                value="{{ 'https://' + campaign.slug + settings.environment.urlPrefix + '.' + settings.domains.app }}"
                readonly
              />
              <button mat-icon-button matSuffix (click)="changeUrl(campaign)">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                  </svg>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> HUB Name </strong>
            <mat-form-field class="app-information-hub-name-field">
              <input matInput [value]="campaign.orgName" readonly />
              <button mat-icon-button matSuffix (click)="changeOrgName(campaign)">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                  </svg>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> Affiliate code </strong>
            <mat-form-field class="app-information-affiliate-code-field">
              <input matInput [value]="campaign.affiliateCode ?? 'not set'" readonly />
              <button mat-icon-button matSuffix (click)="changeAffiliateCode(campaign)">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                  </svg>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> Custom SSO type </strong>
            <mat-form-field class="app-information-custom-sso-tyoe-field">
              <mat-select [value]="campaign.customSsoType" (selectionChange)="ssoChanged($event)">
                <mat-option value="INTERNAL">Internal (default)</mat-option>
                <mat-option value="EN_REACH">Engaged nation reach platform</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> Engaged nation API connection id </strong>
            <mat-form-field class="app-information-affiliate-code-field">
              <input matInput [value]="campaign.engagedNationId ?? 'not set'" readonly />
              <button mat-icon-button matSuffix (click)="changeEngagedNationId(campaign)">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                  </svg>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="app-information-row fx-layout-row">
          <div class="fx-layout-column fx-flex">
            <strong> Stripe product </strong>
            <mat-form-field class="app-information-affiliate-code-field">
              <input
                matInput
                [value]="stripeProduct() ?? (loadingStripeProduct() ? 'loading...' : 'not set')"
                readonly
              />
              <button mat-icon-button matSuffix (click)="changeStripeProduct(campaign)">
                <mat-icon>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                  </svg>
                </mat-icon>
              </button>
              @if (stripeProduct()) {
                <button mat-icon-button matSuffix (click)="removeStripeProduct()">
                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              }
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--HUB Status-->
      <div class="advanced-settings-group section-block">
        <div class="subheader">HUB STATUS</div>

        <mat-list-item
          class="app-settings-list-item"
          [class.disabled]="campaign.archived"
          [matTooltip]="campaign.archived ? 'Archived campaigns must be restored first' : ''"
        >
          <div class="app-settings-list-item-label fx-layout-column fx-flex">
            <strong>Activate HUB</strong>
            <span>
              When enabled the HUB will be set to ACTIVE and be available - Disable to turn all HUB access off and take
              it offline
            </span>
          </div>
          <mat-slide-toggle
            [disabled]="campaign.archived"
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.active"
            (change)="setActive()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Demo mode</strong>
            <span> When this is enabled the HUB will be in demo mode </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.demo"
            (change)="setDemo()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Template app</strong>
            <span> When this is enabled the HUB will marked as a template </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.isTemplate"
            (change)="setTemplate()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Disable Registration</strong>
            <span> When this is enabled, Registration on the hub will be disabled </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.disableRegistration"
            (change)="setDisableRegistration()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Use sandbox</strong>
            <span> When this is enabled the HUB will use the Stripe sandbox</span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.useSandbox"
            (change)="setSandbox()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Private HUB</strong>
            <span>
              Enable this to set this HUB to be private, users will have to be added by the HUB administrators
            </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.private"
            (change)="setPrivate()"
          >
          </mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Test HUB</strong>
            <span> Enable this to mark this App available for Testing - Disable to set as client app </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.testApp"
            (change)="setTestApp()"
          >
          </mat-slide-toggle>
        </mat-list-item>
      </div>

      <!--3rd Party Authentication-->
      <div class="advanced-settings-group section-block">
        <div class="subheader">3RD PARTY AUTHENTICATION</div>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Facebook</strong>
            <span> Allow members to login with Facebook </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.allowFacebookLogin"
            (change)="setAllowFacebook()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Google</strong>
            <span> Allow members to login with Google </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.allowGoogleLogin"
            (change)="setAllowGoogle()"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>LinkedIn</strong>
            <span> Allow members to login with LinkedIn </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.allowLinkedInLogin"
            (change)="setAllowLinkedIn()"
          ></mat-slide-toggle>
        </mat-list-item>
      </div>

      <!--Modules and Paid options-->
      <div class="advanced-settings-group section-block">
        <div class="subheader">MODULES AND PAID OPTIONS</div>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>SMS</strong>
            <span> Enable the SMS option for this HUB </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.smsEnabled"
            (change)="setSms()"
          >
          </mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Call To Action Module</strong>
            <span> Allow members to create calls to action on post </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.postCallToActionEnabled"
            (change)="setPostFlag('CallToAction')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Location Module</strong>
            <span> Allow members to set a location for a post </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.postLocationEnabled"
            (change)="setPostFlag('Location')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Photos module</strong>
            <span> Allow members to add photos to posts </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.postPhotosEnabled"
            (change)="setPostFlag('Photos')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Surveys Module</strong>
            <span> Allow members to add surveys to posts </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.postSurveysEnabled"
            (change)="setPostFlag('Surveys')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Tasks Module</strong>
            <span> Allow members to add tasks to posts </span>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.postTasksEnabled"
            (change)="setPostFlag('Tasks')"
          ></mat-slide-toggle>
        </mat-list-item>
      </div>

      <!--HUB Administration menu options-->
      <div class="advanced-settings-group section-block">
        <div class="subheader">HUB ADMINISTRATION MENU OPTIONS</div>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Advanced options</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuAdvancedOptionsEnabled"
            (change)="setMenuFlag('AdvancedOptions')"
          >
          </mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Url redirects</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuUrlRedirectsEnabled"
            (change)="setMenuFlag('UrlRedirects')"
          >
          </mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Home customization</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuHomeCustomizationEnabled"
            (change)="setMenuFlag('HomeCustomization')"
          >
          </mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Look and feel</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuLookAndFeelEnabled"
            (change)="setMenuFlag('LookAndFeel')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Members management</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuMembersEnabled"
            (change)="setMenuFlag('Members', 'members management')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Pages editor</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuPagesEnabled"
            (change)="setMenuFlag('Pages', 'pages editor')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Post Labels</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuPostTypesEnabled"
            (change)="setMenuFlag('PostTypes', 'post types')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Sliders editor</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuSlidersEnabled"
            (change)="setMenuFlag('Sliders', 'sliders editor')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Custom code</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuCustomCodeEnabled"
            (change)="setMenuFlag('CustomCode', 'custom code editor')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Welcome message editor</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuWelcomeMessageEnabled"
            (change)="setMenuFlag('WelcomeMessage', 'welcome message editor')"
          ></mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>

        <mat-list-item class="app-settings-list-item">
          <div class="fx-layout-column fx-flex">
            <strong>Menu editor</strong>
          </div>
          <mat-slide-toggle
            class="app-settings-toggle"
            labelPosition="before"
            [(ngModel)]="campaign.menuMenuEditEnabled"
            (change)="setMenuFlag('MenuEdit', 'menu editor')"
          ></mat-slide-toggle>
        </mat-list-item>
      </div>

      <!--Upload Size Restrictions-->
      <div class="advanced-settings-group section-block">
        <div class="subheader">UPLOAD SIZE RESTRICTIONS</div>
        <form name="bridgeUploadForm" novalidate>
          <mat-list-item class="app-settings-list-item fx-layout-column fx-layout-align-start">
            <div class="fx-layout-column fx-flex">
              <strong>File Upload Size</strong>
              <span> Maximum file upload size allowed (max. 100MB)</span>
              <div
                class="advanced-options-error"
                *ngIf="bridgeUploadForm.get('bridgeUploadLimit').getError('required')"
              >
                Value is required
              </div>
              <div class="advanced-options-error" *ngIf="bridgeUploadForm.get('bridgeUploadLimit').getError('min')">
                Value must be between 1 and 100
              </div>
              <div class="advanced-options-error" *ngIf="bridgeUploadForm.get('bridgeUploadLimit').getError('max')">
                Value must be between 1 and 100
              </div>
              <div
                class="advanced-options-error"
                *ngIf="
                  bridgeUploadForm.get('bridgeUploadLimit').getError('number') ||
                  bridgeUploadForm.get('bridgeUploadLimit').getError('pattern')
                "
              >
                Please enter a valid number
              </div>
            </div>

            <mat-form-field class="app-settings-file-size-field" id="fileSizeContainer">
              <input
                matInput
                name="uploadLimit"
                type="number"
                [(ngModel)]="campaign.bridgeUploadLimit"
                autocomplete="off"
                (change)="saveFileSize()"
                [formControl]="$any(bridgeUploadForm.get('bridgeUploadLimit'))"
              />
            </mat-form-field>
            <span><strong>MB</strong></span>
          </mat-list-item>
          <mat-divider></mat-divider>

          <mat-list-item class="app-settings-list-item fx-layout-column fx-layout-align-start">
            <div class="fx-layout-column fx-flex">
              <strong>Video Upload Size</strong>
              <span> Maximum video upload size allowed (max. 100MB) </span>
              <div class="advanced-options-error" *ngIf="videoUploadForm.get('videoUploadLimit').getError('required')">
                Value is required
              </div>
              <div class="advanced-options-error" *ngIf="videoUploadForm.get('videoUploadLimit').getError('min')">
                Value must be between 1 and 100
              </div>
              <div class="advanced-options-error" *ngIf="videoUploadForm.get('videoUploadLimit').getError('max')">
                Value must be between 1 and 100
              </div>
              <div
                class="advanced-options-error"
                *ngIf="
                  videoUploadForm.get('videoUploadLimit').getError('number') ||
                  videoUploadForm.get('videoUploadLimit').getError('pattern')
                "
              >
                Please enter a valid number
              </div>
            </div>

            <mat-form-field class="app-settings-video-size-field" id="videoSizeContainer">
              <input
                matInput
                name="videoUploadLimit"
                type="number"
                pattern="^[0-9]+$"
                required
                min="1"
                max="100"
                [(ngModel)]="campaign.videoUploadLimit"
                autocomplete="off"
                (change)="saveVideoSize()"
                [formControl]="$any(videoUploadForm.get('videoUploadLimit'))"
              />
            </mat-form-field>
            <span><strong>MB</strong></span>
          </mat-list-item>
        </form>
      </div>
    </mat-list>
  </div>
</section>
