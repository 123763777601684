"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRTCIceCandidateStatsReport = exports.getRTCStats = void 0;
/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
// @ts-nocheck
// tslint:disable no-empty
var errors_1 = require("../errors");
var mockrtcstatsreport_1 = require("./mockrtcstatsreport");
var ERROR_PEER_CONNECTION_NULL = 'PeerConnection is null';
var ERROR_WEB_RTC_UNSUPPORTED = 'WebRTC statistics are unsupported';
/**
 * Helper function to find a specific stat from a report.
 * Some environment provide the stats report as a map (regular browsers)
 * but some provide stats report as an array (citrix vdi)
 * @private
 */
function findStatById(report, id) {
  if (typeof report.get === 'function') {
    return report.get(id);
  }
  return report.find(function (s) {
    return s.id === id;
  });
}
/**
 * Generate WebRTC statistics report for the given {@link PeerConnection}
 * @param {PeerConnection} peerConnection - Target connection.
 * @return {Promise<RTCStatsReport>} WebRTC RTCStatsReport object
 */
function getRTCStatsReport(peerConnection) {
  if (!peerConnection) {
    return Promise.reject(new errors_1.InvalidArgumentError(ERROR_PEER_CONNECTION_NULL));
  }
  if (typeof peerConnection.getStats !== 'function') {
    return Promise.reject(new errors_1.NotSupportedError(ERROR_WEB_RTC_UNSUPPORTED));
  }
  var promise;
  try {
    promise = peerConnection.getStats();
  } catch (e) {
    promise = new Promise(function (resolve) {
      return peerConnection.getStats(resolve);
    }).then(mockrtcstatsreport_1.default.fromRTCStatsResponse);
  }
  return promise;
}
/**
 * @typedef {Object} StatsOptions
 * Used for testing to inject and extract methods.
 * @property {function} [createRTCSample] - Method for parsing an RTCStatsReport
 */
/**
 * Collects any WebRTC statistics for the given {@link PeerConnection}
 * @param {PeerConnection} peerConnection - Target connection.
 * @param {StatsOptions} options - List of custom options.
 * @return {Promise<RTCSample>} Universally-formatted version of RTC stats.
 */
function getRTCStats(peerConnection, options) {
  options = Object.assign({
    createRTCSample: createRTCSample
  }, options);
  return getRTCStatsReport(peerConnection).then(options.createRTCSample);
}
exports.getRTCStats = getRTCStats;
/**
 * Generate WebRTC stats report containing relevant information about ICE candidates for the given {@link PeerConnection}
 * @param {PeerConnection} peerConnection - Target connection.
 * @return {Promise<RTCIceCandidateStatsReport>} RTCIceCandidateStatsReport object
 */
function getRTCIceCandidateStatsReport(peerConnection) {
  return getRTCStatsReport(peerConnection).then(function (report) {
    // Find the relevant information needed to determine selected candidates later
    var _a = Array.from(report.values()).reduce(function (rval, stat) {
        ['candidatePairs', 'localCandidates', 'remoteCandidates'].forEach(function (prop) {
          if (!rval[prop]) {
            rval[prop] = [];
          }
        });
        switch (stat.type) {
          case 'candidate-pair':
            rval.candidatePairs.push(stat);
            break;
          case 'local-candidate':
            rval.localCandidates.push(stat);
            break;
          case 'remote-candidate':
            rval.remoteCandidates.push(stat);
            break;
          case 'transport':
            // This transport is the one being used if selectedCandidatePairId is populated
            if (stat.selectedCandidatePairId) {
              rval.transport = stat;
            }
            break;
        }
        return rval;
      }, {}),
      candidatePairs = _a.candidatePairs,
      localCandidates = _a.localCandidates,
      remoteCandidates = _a.remoteCandidates,
      transport = _a.transport;
    // This is a report containing information about the selected candidates, such as IDs
    // This is coming from WebRTC stats directly and doesn't contain the actual ICE Candidates info
    var selectedCandidatePairReport = candidatePairs.find(function (pair) {
      // Firefox
      return pair.selected ||
      // Spec-compliant way
      transport && pair.id === transport.selectedCandidatePairId;
    });
    var selectedIceCandidatePairStats;
    if (selectedCandidatePairReport) {
      selectedIceCandidatePairStats = {
        localCandidate: localCandidates.find(function (candidate) {
          return candidate.id === selectedCandidatePairReport.localCandidateId;
        }),
        remoteCandidate: remoteCandidates.find(function (candidate) {
          return candidate.id === selectedCandidatePairReport.remoteCandidateId;
        })
      };
    }
    // Build the return object
    return {
      iceCandidateStats: __spreadArrays(localCandidates, remoteCandidates),
      selectedIceCandidatePairStats: selectedIceCandidatePairStats
    };
  });
}
exports.getRTCIceCandidateStatsReport = getRTCIceCandidateStatsReport;
/**
 * @typedef {Object} RTCSample - A sample containing relevant WebRTC stats information.
 * @property {Number} [timestamp]
 * @property {String} [codecName] - MimeType name of the codec being used by the outbound audio stream
 * @property {Number} [rtt] - Round trip time
 * @property {Number} [jitter]
 * @property {Number} [packetsSent]
 * @property {Number} [packetsLost]
 * @property {Number} [packetsReceived]
 * @property {Number} [bytesReceived]
 * @property {Number} [bytesSent]
 * @property {Number} [localAddress]
 * @property {Number} [remoteAddress]
 */
function RTCSample() {}
/**
 * Create an RTCSample object from an RTCStatsReport
 * @private
 * @param {RTCStatsReport} statsReport
 * @returns {RTCSample}
 */
function createRTCSample(statsReport) {
  var activeTransportId = null;
  var sample = new RTCSample();
  var fallbackTimestamp;
  Array.from(statsReport.values()).forEach(function (stats) {
    // Skip isRemote tracks which will be phased out completely and break in FF66.
    if (stats.isRemote) {
      return;
    }
    // Firefox hack -- Older firefox doesn't have dashes in type names
    var type = stats.type.replace('-', '');
    fallbackTimestamp = fallbackTimestamp || stats.timestamp;
    // (rrowland) As I understand it, this is supposed to come in on remote-inbound-rtp but it's
    // currently coming in on remote-outbound-rtp, so I'm leaving this outside the switch until
    // the appropriate place to look is cleared up.
    if (stats.remoteId) {
      var remote = findStatById(statsReport, stats.remoteId);
      if (remote && remote.roundTripTime) {
        sample.rtt = remote.roundTripTime * 1000;
      }
    }
    switch (type) {
      case 'inboundrtp':
        sample.timestamp = sample.timestamp || stats.timestamp;
        sample.jitter = stats.jitter * 1000;
        sample.packetsLost = stats.packetsLost;
        sample.packetsReceived = stats.packetsReceived;
        sample.bytesReceived = stats.bytesReceived;
        break;
      case 'outboundrtp':
        sample.timestamp = stats.timestamp;
        sample.packetsSent = stats.packetsSent;
        sample.bytesSent = stats.bytesSent;
        if (stats.codecId) {
          var codec = findStatById(statsReport, stats.codecId);
          sample.codecName = codec ? codec.mimeType && codec.mimeType.match(/(.*\/)?(.*)/)[2] : stats.codecId;
        }
        break;
      case 'transport':
        activeTransportId = stats.id;
        break;
    }
  });
  if (!sample.timestamp) {
    sample.timestamp = fallbackTimestamp;
  }
  var activeTransport = findStatById(statsReport, activeTransportId);
  if (!activeTransport) {
    return sample;
  }
  var selectedCandidatePair = findStatById(statsReport, activeTransport.selectedCandidatePairId);
  if (!selectedCandidatePair) {
    return sample;
  }
  var localCandidate = findStatById(statsReport, selectedCandidatePair.localCandidateId);
  var remoteCandidate = findStatById(statsReport, selectedCandidatePair.remoteCandidateId);
  if (!sample.rtt) {
    sample.rtt = selectedCandidatePair && selectedCandidatePair.currentRoundTripTime * 1000;
  }
  Object.assign(sample, {
    // ip is deprecated. use address first then ip if on older versions of browser
    localAddress: localCandidate && (localCandidate.address || localCandidate.ip),
    remoteAddress: remoteCandidate && (remoteCandidate.address || remoteCandidate.ip)
  });
  return sample;
}
