<mat-form-field appearance="outline" subscriptSizing="dynamic">
  <textarea matInput cdkTextareaAutosize [formControl]="promptSource"></textarea>
</mat-form-field>
<div class="prompt-actions fx-layout-row fx-layout-align-end">
  <ih-animated-save-button mat-flat-button color="accent" [saving]="generatingPrompt()" (click)="generatePrompt()">
    Generate prompt
    <ng-container savingText>Generating prompt</ng-container>
  </ih-animated-save-button>
</div>
<mat-form-field appearance="outline" subscriptSizing="dynamic">
  <textarea matInput cdkTextareaAutosize [formControl]="prompt"></textarea>
</mat-form-field>
<div class="image-actions fx-layout-row fx-layout-align-end">
  <ih-animated-save-button
    mat-flat-button
    color="primary"
    [saving]="generatingImage()"
    (click)="generateImage()"
    [disabled]="!prompt.value"
    [title]="prompt.value ? 'Generate image' : 'Enter prompt first'"
  >
    Generate image
    <ng-container savingText>Generating image</ng-container>
  </ih-animated-save-button>
</div>
<div class="generated-image-container">
  <img [src]="promptImage()" />
</div>
