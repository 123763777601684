import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type BridgeUserAddAppDialogComponent } from './bridge-user-add-app-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class BridgeUserAddAppDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  async open(userId: number): Promise<MatDialogRef<BridgeUserAddAppDialogComponent, any>> {
    const bridgeUserAddAppDialogComponent = await import('./bridge-user-add-app-dialog.component').then(
      (m) => m.BridgeUserAddAppDialogComponent
    );
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(bridgeUserAddAppDialogComponent, {
      id: 'bridgeUserAddApp',
      data: userId,
      closeOnNavigation: true,
      panelClass: ['bridge-user-add-app-dialog', 'dialog-no-padding'],
      maxWidth: '90%',
      minHeight: 'auto',
      minWidth: '280px',
      width: '560px'
    });
  }
}
