import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SafePipe } from '@ih/safe-pipe';

import { BehaviorSubject } from 'rxjs';

declare const CodeMirror: any;

@Component({
  selector: 'ih-customization-editor',
  templateUrl: './customization-editor.component.html',
  styleUrls: ['./customization-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, MatButtonModule, MatIconModule, SafePipe]
})
export class CustomizationEditorComponent implements OnDestroy {
  @HostBinding('class.ih-customization-editor') hostClass = true;

  @Input() excerpt: string;
  @Input() value: string;
  @Input() mode = 'mixedhtml';
  @Input() placeholder: string;

  @Output() onSave = new EventEmitter<string>();

  codeVisible$ = new BehaviorSubject<boolean>(false);

  private codeMirrorRef: any;

  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {
    if (this.codeMirrorRef) {
      this.codeMirrorRef.getWrapperElement().remove();
      this.codeMirrorRef = null;
    }
  }

  showEditor(): void {
    // show the textarea
    this.codeVisible$.next(true);

    // setup codemirror
    // need to wait for dom to render before creating codemirror
    requestAnimationFrame(() => {
      // if it's a channel, we need the channel id to get the element, otherwise we can get it by the property type and campaign id
      const element = this.el.nativeElement.getElementsByClassName('code-mirror-text-area')[0];
      // making a change to the DOM directly, need to do it in requestAnimationFrame
      this.codeMirrorRef = CodeMirror.fromTextArea(element, {
        extraKeys: { 'Ctrl-Space': 'autocomplete' },
        lineNumbers: true,
        lineWrapping: true,
        autoCloseBrackets: true,
        mode: this.mode
      });
      this.codeMirrorRef.setSize('100%', '100%');
      this.codeMirrorRef.setValue(this.value || '');
      this.codeMirrorRef.refresh();
    });
  }

  hideEditor(): void {
    this.codeMirrorRef.getWrapperElement().remove();
    this.codeMirrorRef = null;
    this.codeVisible$.next(false);
    // code mirror can be very tall, so we need to set the window scroll position to the top of the element
    requestAnimationFrame(() => {
      // subtract the top nav (62px) and the page header (56px) to the scroll position
      document.scrollingElement.scrollTop =
        document.scrollingElement.scrollTop +
        (this.el.nativeElement as HTMLElement).getBoundingClientRect().top -
        62 -
        56;
    });
  }

  update(): void {
    // fire event to update the customization
    this.onSave.emit(this.codeMirrorRef.getValue());

    // hide the code editor
    this.hideEditor();
  }
}
