"use strict";

/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var events_1 = require("events");
var errors_1 = require("./errors");
var mos_1 = require("./rtc/mos");
var stats_1 = require("./rtc/stats");
var util_1 = require("./util");
// How many samples we use when testing metric thresholds
var SAMPLE_COUNT_METRICS = 5;
// How many samples that need to cross the threshold to
// raise or clear a warning.
var SAMPLE_COUNT_CLEAR = 0;
var SAMPLE_COUNT_RAISE = 3;
var SAMPLE_INTERVAL = 1000;
var WARNING_TIMEOUT = 5 * 1000;
var DEFAULT_THRESHOLDS = {
  audioInputLevel: {
    minStandardDeviation: 327.67,
    sampleCount: 10
  },
  audioOutputLevel: {
    minStandardDeviation: 327.67,
    sampleCount: 10
  },
  bytesReceived: {
    clearCount: 2,
    min: 1,
    raiseCount: 3,
    sampleCount: 3
  },
  bytesSent: {
    clearCount: 2,
    min: 1,
    raiseCount: 3,
    sampleCount: 3
  },
  jitter: {
    max: 30
  },
  mos: {
    min: 3
  },
  packetsLostFraction: [{
    max: 1
  }, {
    clearValue: 1,
    maxAverage: 3,
    sampleCount: 7
  }],
  rtt: {
    max: 400
  }
};
/**
 * Count the number of values that cross the max threshold.
 * @private
 * @param max - The max allowable value.
 * @param values - The values to iterate over.
 * @returns The amount of values in which the stat crossed the threshold.
 */
function countHigh(max, values) {
  return values.reduce(function (highCount, value) {
    return highCount += value > max ? 1 : 0;
  }, 0);
}
/**
 * Count the number of values that cross the min threshold.
 * @private
 * @param min - The minimum allowable value.
 * @param values - The values to iterate over.
 * @returns The amount of values in which the stat crossed the threshold.
 */
function countLow(min, values) {
  return values.reduce(function (lowCount, value) {
    return lowCount += value < min ? 1 : 0;
  }, 0);
}
/**
 * Calculate the standard deviation from a list of numbers.
 * @private
 * @param values The list of numbers to calculate the standard deviation from.
 * @returns The standard deviation of a list of numbers.
 */
function calculateStandardDeviation(values) {
  if (values.length <= 0) {
    return null;
  }
  var valueAverage = values.reduce(function (partialSum, value) {
    return partialSum + value;
  }, 0) / values.length;
  var diffSquared = values.map(function (value) {
    return Math.pow(value - valueAverage, 2);
  });
  var stdDev = Math.sqrt(diffSquared.reduce(function (partialSum, value) {
    return partialSum + value;
  }, 0) / diffSquared.length);
  return stdDev;
}
/**
 * Flatten a set of numerical sample sets into a single array of samples.
 * @param sampleSets
 */
function flattenSamples(sampleSets) {
  return sampleSets.reduce(function (flat, current) {
    return __spreadArrays(flat, current);
  }, []);
}
/**
 * {@link StatsMonitor} polls a peerConnection via PeerConnection.getStats
 * and emits warnings when stats cross the specified threshold values.
 */
var StatsMonitor = /** @class */function (_super) {
  __extends(StatsMonitor, _super);
  /**
   * @constructor
   * @param [options] - Optional settings
   */
  function StatsMonitor(options) {
    var _this = _super.call(this) || this;
    /**
     * A map of warnings with their raised time
     */
    _this._activeWarnings = new Map();
    /**
     * A map of stats with the number of exceeded thresholds
     */
    _this._currentStreaks = new Map();
    /**
     * Keeps track of input volumes in the last second
     */
    _this._inputVolumes = [];
    /**
     * Keeps track of output volumes in the last second
     */
    _this._outputVolumes = [];
    /**
     * Sample buffer. Saves most recent samples
     */
    _this._sampleBuffer = [];
    /**
     * Keeps track of supplemental sample values.
     *
     * Currently used for constant audio detection. Contains an array of volume
     * samples for each sample interval.
     */
    _this._supplementalSampleBuffers = {
      audioInputLevel: [],
      audioOutputLevel: []
    };
    /**
     * Whether warnings should be enabled
     */
    _this._warningsEnabled = true;
    options = options || {};
    _this._getRTCStats = options.getRTCStats || stats_1.getRTCStats;
    _this._mos = options.Mos || mos_1.default;
    _this._peerConnection = options.peerConnection;
    _this._thresholds = __assign(__assign({}, DEFAULT_THRESHOLDS), options.thresholds);
    var thresholdSampleCounts = Object.values(_this._thresholds).map(function (threshold) {
      return threshold.sampleCount;
    }).filter(function (sampleCount) {
      return !!sampleCount;
    });
    _this._maxSampleCount = Math.max.apply(Math, __spreadArrays([SAMPLE_COUNT_METRICS], thresholdSampleCounts));
    if (_this._peerConnection) {
      _this.enable(_this._peerConnection);
    }
    return _this;
  }
  /**
   * Called when a volume sample is available
   * @param inputVolume - Input volume level from 0 to 32767
   * @param outputVolume - Output volume level from 0 to 32767
   */
  StatsMonitor.prototype.addVolumes = function (inputVolume, outputVolume) {
    this._inputVolumes.push(inputVolume);
    this._outputVolumes.push(outputVolume);
  };
  /**
   * Stop sampling RTC statistics for this {@link StatsMonitor}.
   * @returns The current {@link StatsMonitor}.
   */
  StatsMonitor.prototype.disable = function () {
    if (this._sampleInterval) {
      clearInterval(this._sampleInterval);
      delete this._sampleInterval;
    }
    return this;
  };
  /**
   * Disable warnings for this {@link StatsMonitor}.
   * @returns The current {@link StatsMonitor}.
   */
  StatsMonitor.prototype.disableWarnings = function () {
    if (this._warningsEnabled) {
      this._activeWarnings.clear();
    }
    this._warningsEnabled = false;
    return this;
  };
  /**
   * Start sampling RTC statistics for this {@link StatsMonitor}.
   * @param peerConnection - A PeerConnection to monitor.
   * @returns The current {@link StatsMonitor}.
   */
  StatsMonitor.prototype.enable = function (peerConnection) {
    if (peerConnection) {
      if (this._peerConnection && peerConnection !== this._peerConnection) {
        throw new errors_1.InvalidArgumentError('Attempted to replace an existing PeerConnection in StatsMonitor.enable');
      }
      this._peerConnection = peerConnection;
    }
    if (!this._peerConnection) {
      throw new errors_1.InvalidArgumentError('Can not enable StatsMonitor without a PeerConnection');
    }
    this._sampleInterval = this._sampleInterval || setInterval(this._fetchSample.bind(this), SAMPLE_INTERVAL);
    return this;
  };
  /**
   * Enable warnings for this {@link StatsMonitor}.
   * @returns The current {@link StatsMonitor}.
   */
  StatsMonitor.prototype.enableWarnings = function () {
    this._warningsEnabled = true;
    return this;
  };
  /**
   * Check if there is an active warning for a specific stat and threshold
   * @param statName - The name of the stat to check
   * @param thresholdName - The name of the threshold to check
   * @returns Whether there is an active warning for a specific stat and threshold
   */
  StatsMonitor.prototype.hasActiveWarning = function (statName, thresholdName) {
    var warningId = statName + ":" + thresholdName;
    return !!this._activeWarnings.get(warningId);
  };
  /**
   * Add a sample to our sample buffer and remove the oldest if we are over the limit.
   * @param sample - Sample to add
   */
  StatsMonitor.prototype._addSample = function (sample) {
    var samples = this._sampleBuffer;
    samples.push(sample);
    // We store 1 extra sample so that we always have (current, previous)
    // available for all {sampleBufferSize} threshold validations.
    if (samples.length > this._maxSampleCount) {
      samples.splice(0, samples.length - this._maxSampleCount);
    }
  };
  /**
   * Clear an active warning.
   * @param statName - The name of the stat to clear.
   * @param thresholdName - The name of the threshold to clear
   * @param [data] - Any relevant sample data.
   */
  StatsMonitor.prototype._clearWarning = function (statName, thresholdName, data) {
    var warningId = statName + ":" + thresholdName;
    var activeWarning = this._activeWarnings.get(warningId);
    if (!activeWarning || Date.now() - activeWarning.timeRaised < WARNING_TIMEOUT) {
      return;
    }
    this._activeWarnings.delete(warningId);
    this.emit('warning-cleared', __assign(__assign({}, data), {
      name: statName,
      threshold: {
        name: thresholdName,
        value: this._thresholds[statName][thresholdName]
      }
    }));
  };
  /**
   * Create a sample object from a stats object using the previous sample, if available.
   * @param stats - Stats retrieved from getStatistics
   * @param [previousSample=null] - The previous sample to use to calculate deltas.
   * @returns A universally-formatted version of RTC stats.
   */
  StatsMonitor.prototype._createSample = function (stats, previousSample) {
    var previousBytesSent = previousSample && previousSample.totals.bytesSent || 0;
    var previousBytesReceived = previousSample && previousSample.totals.bytesReceived || 0;
    var previousPacketsSent = previousSample && previousSample.totals.packetsSent || 0;
    var previousPacketsReceived = previousSample && previousSample.totals.packetsReceived || 0;
    var previousPacketsLost = previousSample && previousSample.totals.packetsLost || 0;
    var currentBytesSent = stats.bytesSent - previousBytesSent;
    var currentBytesReceived = stats.bytesReceived - previousBytesReceived;
    var currentPacketsSent = stats.packetsSent - previousPacketsSent;
    var currentPacketsReceived = stats.packetsReceived - previousPacketsReceived;
    var currentPacketsLost = stats.packetsLost - previousPacketsLost;
    var currentInboundPackets = currentPacketsReceived + currentPacketsLost;
    var currentPacketsLostFraction = currentInboundPackets > 0 ? currentPacketsLost / currentInboundPackets * 100 : 0;
    var totalInboundPackets = stats.packetsReceived + stats.packetsLost;
    var totalPacketsLostFraction = totalInboundPackets > 0 ? stats.packetsLost / totalInboundPackets * 100 : 100;
    var rttValue = typeof stats.rtt === 'number' || !previousSample ? stats.rtt : previousSample.rtt;
    var audioInputLevelValues = this._inputVolumes.splice(0);
    this._supplementalSampleBuffers.audioInputLevel.push(audioInputLevelValues);
    var audioOutputLevelValues = this._outputVolumes.splice(0);
    this._supplementalSampleBuffers.audioOutputLevel.push(audioOutputLevelValues);
    return {
      audioInputLevel: Math.round(util_1.average(audioInputLevelValues)),
      audioOutputLevel: Math.round(util_1.average(audioOutputLevelValues)),
      bytesReceived: currentBytesReceived,
      bytesSent: currentBytesSent,
      codecName: stats.codecName,
      jitter: stats.jitter,
      mos: this._mos.calculate(rttValue, stats.jitter, previousSample && currentPacketsLostFraction),
      packetsLost: currentPacketsLost,
      packetsLostFraction: currentPacketsLostFraction,
      packetsReceived: currentPacketsReceived,
      packetsSent: currentPacketsSent,
      rtt: rttValue,
      timestamp: stats.timestamp,
      totals: {
        bytesReceived: stats.bytesReceived,
        bytesSent: stats.bytesSent,
        packetsLost: stats.packetsLost,
        packetsLostFraction: totalPacketsLostFraction,
        packetsReceived: stats.packetsReceived,
        packetsSent: stats.packetsSent
      }
    };
  };
  /**
   * Get stats from the PeerConnection and add it to our list of samples.
   */
  StatsMonitor.prototype._fetchSample = function () {
    var _this = this;
    this._getSample().then(function (sample) {
      _this._addSample(sample);
      _this._raiseWarnings();
      _this.emit('sample', sample);
    }).catch(function (error) {
      _this.disable();
      // We only bubble up any errors coming from pc.getStats()
      // No need to attach a twilioError
      _this.emit('error', error);
    });
  };
  /**
   * Get stats from the PeerConnection.
   * @returns A universally-formatted version of RTC stats.
   */
  StatsMonitor.prototype._getSample = function () {
    var _this = this;
    return this._getRTCStats(this._peerConnection).then(function (stats) {
      var previousSample = null;
      if (_this._sampleBuffer.length) {
        previousSample = _this._sampleBuffer[_this._sampleBuffer.length - 1];
      }
      return _this._createSample(stats, previousSample);
    });
  };
  /**
   * Raise a warning and log its raised time.
   * @param statName - The name of the stat to raise.
   * @param thresholdName - The name of the threshold to raise
   * @param [data] - Any relevant sample data.
   */
  StatsMonitor.prototype._raiseWarning = function (statName, thresholdName, data) {
    var warningId = statName + ":" + thresholdName;
    if (this._activeWarnings.has(warningId)) {
      return;
    }
    this._activeWarnings.set(warningId, {
      timeRaised: Date.now()
    });
    var thresholds = this._thresholds[statName];
    var thresholdValue;
    if (Array.isArray(thresholds)) {
      var foundThreshold = thresholds.find(function (threshold) {
        return thresholdName in threshold;
      });
      if (foundThreshold) {
        thresholdValue = foundThreshold[thresholdName];
      }
    } else {
      thresholdValue = this._thresholds[statName][thresholdName];
    }
    this.emit('warning', __assign(__assign({}, data), {
      name: statName,
      threshold: {
        name: thresholdName,
        value: thresholdValue
      }
    }));
  };
  /**
   * Apply our thresholds to our array of RTCStat samples.
   */
  StatsMonitor.prototype._raiseWarnings = function () {
    var _this = this;
    if (!this._warningsEnabled) {
      return;
    }
    Object.keys(this._thresholds).forEach(function (name) {
      return _this._raiseWarningsForStat(name);
    });
  };
  /**
   * Apply thresholds for a given stat name to our array of
   * RTCStat samples and raise or clear any associated warnings.
   * @param statName - Name of the stat to compare.
   */
  StatsMonitor.prototype._raiseWarningsForStat = function (statName) {
    var _this = this;
    var limits = Array.isArray(this._thresholds[statName]) ? this._thresholds[statName] : [this._thresholds[statName]];
    limits.forEach(function (limit) {
      var samples = _this._sampleBuffer;
      var clearCount = limit.clearCount || SAMPLE_COUNT_CLEAR;
      var raiseCount = limit.raiseCount || SAMPLE_COUNT_RAISE;
      var sampleCount = limit.sampleCount || _this._maxSampleCount;
      var relevantSamples = samples.slice(-sampleCount);
      var values = relevantSamples.map(function (sample) {
        return sample[statName];
      });
      // (rrowland) If we have a bad or missing value in the set, we don't
      // have enough information to throw or clear a warning. Bail out.
      var containsNull = values.some(function (value) {
        return typeof value === 'undefined' || value === null;
      });
      if (containsNull) {
        return;
      }
      var count;
      if (typeof limit.max === 'number') {
        count = countHigh(limit.max, values);
        if (count >= raiseCount) {
          _this._raiseWarning(statName, 'max', {
            values: values,
            samples: relevantSamples
          });
        } else if (count <= clearCount) {
          _this._clearWarning(statName, 'max', {
            values: values,
            samples: relevantSamples
          });
        }
      }
      if (typeof limit.min === 'number') {
        count = countLow(limit.min, values);
        if (count >= raiseCount) {
          _this._raiseWarning(statName, 'min', {
            values: values,
            samples: relevantSamples
          });
        } else if (count <= clearCount) {
          _this._clearWarning(statName, 'min', {
            values: values,
            samples: relevantSamples
          });
        }
      }
      if (typeof limit.maxDuration === 'number' && samples.length > 1) {
        relevantSamples = samples.slice(-2);
        var prevValue = relevantSamples[0][statName];
        var curValue = relevantSamples[1][statName];
        var prevStreak = _this._currentStreaks.get(statName) || 0;
        var streak = prevValue === curValue ? prevStreak + 1 : 0;
        _this._currentStreaks.set(statName, streak);
        if (streak >= limit.maxDuration) {
          _this._raiseWarning(statName, 'maxDuration', {
            value: streak
          });
        } else if (streak === 0) {
          _this._clearWarning(statName, 'maxDuration', {
            value: prevStreak
          });
        }
      }
      if (typeof limit.minStandardDeviation === 'number') {
        var sampleSets = _this._supplementalSampleBuffers[statName];
        if (!sampleSets || sampleSets.length < limit.sampleCount) {
          return;
        }
        if (sampleSets.length > limit.sampleCount) {
          sampleSets.splice(0, sampleSets.length - limit.sampleCount);
        }
        var flatSamples = flattenSamples(sampleSets.slice(-sampleCount));
        var stdDev = calculateStandardDeviation(flatSamples);
        if (typeof stdDev !== 'number') {
          return;
        }
        if (stdDev < limit.minStandardDeviation) {
          _this._raiseWarning(statName, 'minStandardDeviation', {
            value: stdDev
          });
        } else {
          _this._clearWarning(statName, 'minStandardDeviation', {
            value: stdDev
          });
        }
      }
      [['maxAverage', function (x, y) {
        return x > y;
      }], ['minAverage', function (x, y) {
        return x < y;
      }]].forEach(function (_a) {
        var thresholdName = _a[0],
          comparator = _a[1];
        if (typeof limit[thresholdName] === 'number' && values.length >= sampleCount) {
          var avg = util_1.average(values);
          if (comparator(avg, limit[thresholdName])) {
            _this._raiseWarning(statName, thresholdName, {
              values: values,
              samples: relevantSamples
            });
          } else if (!comparator(avg, limit.clearValue || limit[thresholdName])) {
            _this._clearWarning(statName, thresholdName, {
              values: values,
              samples: relevantSamples
            });
          }
        }
      });
    });
  };
  return StatsMonitor;
}(events_1.EventEmitter);
exports.default = StatsMonitor;
