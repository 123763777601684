import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlSegment } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpgradeModule } from '@angular/upgrade/static';
import { ConversationListComponent, DallEPromptGeneratorComponent } from '@ih/ai';
import { ConfirmDialogComponent } from '@ih/confirm';
import { ENVIRONMENT_PRODUCT } from '@ih/constants';
import {
  InputDialogComponent,
  OptionPickerDialogComponent,
  SaveChangesDialogComponent,
  SaveChangesDialogService
} from '@ih/dialogs';
import { Products } from '@ih/enums';
import { FilterChipsComponent, MemberFilterChipsDirective } from '@ih/filter-chips';
import { FroalaComponent } from '@ih/froala';
import { ImageComponent } from '@ih/image';
import { Account, BridgeConfig } from '@ih/interfaces';
import { ToSrcSetPipe } from '@ih/pipes';
import { SafePipe } from '@ih/safe-pipe';
import {
  AuthGuard,
  AuthService,
  CheckForUpdateService,
  ConfigService,
  ErrorHandlerService,
  FirebaseModule,
  MonitoringService,
  SignalRService,
  StorageModule
} from '@ih/services';
import { BaseUrlInterceptor } from '@ih/shared';
import { IconRegistryService } from 'libs/services/src/lib/icon-registry.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { of } from 'rxjs';
import { AddBridgeUserDialogModule } from './add-bridge-user-dialog/add-bridge-user-dialog.module';
import { AngularjsComponent } from './angularjs/angularjs.component';
import { AppCustomCssComponent } from './app-custom-css/app-custom-css.component';
import { AppEulaComponent } from './app-eula/app-eula.component';
import { AppFroalaOptionsComponent } from './app-froala-options/app-froala-options.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { AppTrackingCodeComponent } from './app-tracking-code/app-tracking-code.component';
import { AppComponent } from './app.component';
import { AssignBridgeUserListComponent } from './assign-bridge-user-list/assign-bridge-user-list.component';
import { BridgeUserAddAppDialogModule } from './bridge-user-add-app-dialog/bridge-user-add-app-dialog.module';
import { BridgeUserEditorDialogModule } from './bridge-user-editor/bridge-user-editor-dialog.module';
import { BridgeUserListComponent } from './bridge-user-list/bridge-user-list.component';
import { CreateAppDialogModule } from './create-app-dialog/create-app-dialog.module';
import { CustomizationEditorComponent } from './customization-editor/customization-editor.component';
import { HomeComponent } from './home/home.component';
import { HubUsersComponent } from './hub-users/hub-users.component';
import { MemberListComponent } from './member-list/member-list.component';
import { FilterCustomizationTypesPipe } from './search-customizations/filter-customization-types.pipe';
import { SearchCustomizationsComponent } from './search-customizations/search-customizations.component';

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,

    RouterModule.forRoot([
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule)
      },
      { path: 'users', component: BridgeUserListComponent, canActivate: [AuthGuard(true)] },
      {
        path: 'searchCustomizations',
        component: SearchCustomizationsComponent,
        canActivate: [AuthGuard(true)]
      },
      {
        path: 'hubUsers',
        component: HubUsersComponent
      },
      {
        path: 'apps',
        children: [
          { path: '', component: AngularjsComponent, canActivate: [AuthGuard(true)] },
          {
            path: ':campaignId',
            children: [
              { path: '', redirectTo: 'settings', pathMatch: 'full' },
              { path: 'settings', component: AppSettingsComponent },
              { path: 'members', component: MemberListComponent },
              { path: 'channels', component: AngularjsComponent },
              {
                path: 'menu',
                loadComponent: () => import('@ih/admin').then((m) => m.MenuEditorPageComponent)
              },
              { path: 'customCss', component: AppCustomCssComponent },
              { path: 'trackingCode', component: AppTrackingCodeComponent },
              { path: 'froalaOptions', component: AppFroalaOptionsComponent },
              { path: 'eula', component: AppEulaComponent },
              { path: 'sms', component: AngularjsComponent },
              {
                path: 'assignBridgeUsers',
                component: AssignBridgeUserListComponent
              }
            ]
          }
        ],
        canActivate: [AuthGuard(true)]
      },
      { path: 'conversations', component: ConversationListComponent, canActivate: [AuthGuard(true)] },
      { path: 'dalle', component: DallEPromptGeneratorComponent, canActivate: [AuthGuard(true)] },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        // enableTracing: !environment.production,
        // match everything else as ng1
        matcher: (url: UrlSegment[]) => ({
          consumed: url
        }),
        component: AngularjsComponent,
        canActivate: [AuthGuard(true), SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      { path: '**', redirectTo: '/home' }
    ]),
    LocationUpgradeModule.config(),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: true
    }),
    StorageModule.forRoot({
      idbName: 'bridge',
      idbStoreName: 'data',
      idbSchemaVersion: 2
    }),
    FirebaseModule.forRoot({
      apiKey: 'AIzaSyCHhrP8cbemEhpGMKE2L8Uigl6PDVde4oE',
      authDomain: 'quantum-gearbox-557.firebaseapp.com',
      databaseURL: 'https://quantum-gearbox-557.firebaseio.com',
      projectId: 'quantum-gearbox-557',
      storageBucket: 'quantum-gearbox-557.appspot.com',
      messagingSenderId: '261205718171',
      appId: '1:261205718171:web:3a57afd49efd33e2e8423e',
      vapid: 'BI5DtqI3h8Db7NQGHawXFQ1TEpKN8GPizj_QN0U2b_WzhNY39ctC4L_Q4z1u6k6sC709W2hYcDZpt5j1UnB37-g',
      apiUrl: '/api/account/pushNotification/fcm',
      measurementId: 'G-3EGFRZW3WQ'
    }),

    BrowserAnimationsModule,

    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,

    ConfirmDialogComponent,
    FilterChipsComponent,
    FroalaComponent,
    ImageComponent,
    InputDialogComponent,
    MemberFilterChipsDirective,
    OptionPickerDialogComponent,

    SafePipe,

    SaveChangesDialogComponent,
    ToSrcSetPipe,

    AddBridgeUserDialogModule,
    AppComponent,
    AppCustomCssComponent,
    AppEulaComponent,
    AppFroalaOptionsComponent,
    AppSettingsComponent,
    AppTrackingCodeComponent,
    AssignBridgeUserListComponent,
    BridgeUserAddAppDialogModule,
    BridgeUserEditorDialogModule,
    BridgeUserListComponent,
    CreateAppDialogModule,
    CustomizationEditorComponent,
    FilterCustomizationTypesPipe,
    HomeComponent,
    InfiniteScrollModule,
    MemberListComponent,
    SearchCustomizationsComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [IconRegistryService, HttpClient, MonitoringService, ConfigService, AuthService, SignalRService]
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: ENVIRONMENT_PRODUCT, useValue: Products.Bridge },
    CheckForUpdateService,
    MonitoringService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule implements DoBootstrap {
  constructor(checkForUpdate: CheckForUpdateService) {
    document.version = window.version;
    console.info('InspireHUB Bridge - Running version: ' + window.version);

    checkForUpdate.init(true);
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(AppComponent);
  }
}

export function initApp(
  iconRegistry: IconRegistryService,
  http: HttpClient,
  monitoring: MonitoringService,
  config: ConfigService<BridgeConfig>,
  auth: AuthService,
  signalr: SignalRService
) {
  iconRegistry.registerIcons();

  return () => {
    config.registerConfigTransformer((bridgeConfig: BridgeConfig) => {
      window.cdnUrl = bridgeConfig.cdnUrl;
      window.campaign = bridgeConfig;
      window.campaign.currentUser = bridgeConfig.currentUser;
      config.config$.next(bridgeConfig);
      auth.currentUser$.next(window.campaign.currentUser);

      monitoring.init(bridgeConfig.keys.aiKey, bridgeConfig.environment.version).subscribe();
      if (bridgeConfig.currentUser) {
        auth.currentUser$.next(bridgeConfig.currentUser as unknown as Account);
        monitoring.setUserId(bridgeConfig.currentUser.email.toString(), 'bridge');
        signalr.connect();
      }

      return of(bridgeConfig);
    });

    return config.syncConfig();
  };
}
