import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';

import { LazySnackBarService, ScriptService } from '@ih/services';
import { BehaviorSubject } from 'rxjs';
import { SideNavService } from '../side-nav.service';

declare const CodeMirror: any;

@Component({
  selector: 'ih-app-custom-css',
  templateUrl: './app-custom-css.component.html',
  styleUrls: ['./app-custom-css.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule]
})
export class AppCustomCssComponent implements AfterViewInit {
  @HostBinding('class.ih-app-custom-css') hostClass = true;

  bridgeSettingsForm = new UntypedFormGroup({
    autoAddAdminToChannel: new UntypedFormControl(false),
    categoryDefaultCollapsed: new UntypedFormControl(false),
    allowUgc: new UntypedFormControl(false),
    allowCommentsWithoutModeration: new UntypedFormControl(false),
    allowAuthorChanges: new UntypedFormControl(false),
    allowSideNavLocking: new UntypedFormControl(false),
    showSlidersOnHome: new UntypedFormControl(false),
    sliderSpeed: new UntypedFormControl(5000),
    defaultChannels: new UntypedFormControl([]),
    recommendedChannels: new UntypedFormControl([]),
    showPopular: new UntypedFormControl(false),
    includeProfileTagsInReg: new UntypedFormControl(false),
    autoIncludeContentInDigest: new UntypedFormControl(false),
    digestArticleLimit: new UntypedFormControl(5),
    useFirstPostAsDigestTitle: new UntypedFormControl(false),
    digestDay: new UntypedFormControl(0),

    primaryColor: new UntypedFormControl(),
    primaryColorFont: new UntypedFormControl(),
    accentColor: new UntypedFormControl(),
    accentColorFont: new UntypedFormControl(),
    navTextColor: new UntypedFormControl(),
    smsEnabled: new UntypedFormControl(),
    upperHtml: new UntypedFormControl(),
    lowerHtml: new UntypedFormControl(),
    logoUrls: new UntypedFormControl(),
    logoImage: new UntypedFormControl(),
    iconImage: new UntypedFormControl(),
    icons: new UntypedFormControl(),
    customCss: new UntypedFormControl(),
    trackingScript: new UntypedFormControl(),
    sideMenu: new UntypedFormControl(),
    bridgeFooterCode: new UntypedFormControl(),
    fullWidthSlider: new UntypedFormControl(),
    vanityUrl: new UntypedFormControl()
  });

  campaignId: number;
  campaign: any;

  savingSettings$ = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sideNavService: SideNavService,
    private script: ScriptService,
    private snackbar: LazySnackBarService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.campaignId = this.route.snapshot.params.campaignId;
    this.sideNavService.setCurrentApp(this.campaignId);
    this.http.get('/api/campaigns/' + this.campaignId).subscribe((data) => {
      this.campaign = data;
      this.cd.markForCheck();

      requestAnimationFrame(() => {
        this.script.loadCodeMirror().subscribe(() => {
          this.initCodeEditor();
        });
      });
    });
  }

  private initCodeEditor(): void {
    // setup css editor
    const cssEditor = CodeMirror.fromTextArea(document.getElementById('cssEditor'), {
      extraKeys: { 'Ctrl-Space': 'autocomplete' },
      lineNumbers: true,
      lineWrapping: true,
      autoCloseBrackets: true,
      mode: 'css'
    });
    cssEditor.on('change', (editor) => {
      this.campaign.bridgeCustomCss = editor.getValue();
    });

    cssEditor.setValue(this.campaign.bridgeCustomCss || '');

    cssEditor.refresh();
  }

  saveSettings(): void {
    this.savingSettings$.next(true);
    this.http
      .put('api/campaigns/' + this.campaign.campaign_id + '/cssAndTracking', {
        bridgeTrackingCode: this.campaign.bridgeTrackingCode,
        bridgeCustomCss: this.campaign.bridgeCustomCss,
        froalaOptions: this.campaign.froalaOptions
      })
      .subscribe(
        () => {
          this.snackbar.open('Settings have successfully saved');

          this.savingSettings$.next(false);
        },
        (resp) => {
          // ignore cancelled
          if (resp.xhrStatus === 'abort') {
            return;
          }

          this.savingSettings$.next(false);

          this.snackbar.open('Unable to save settings');
        }
      );
  }
}
