"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
// @ts-nocheck
var events_1 = require("events");
var C = require("./constants");
var errors_1 = require("./errors");
var log_1 = require("./log");
var wstransport_1 = require("./wstransport");
var PSTREAM_VERSION = '1.6';
// In seconds
var MAX_RECONNECT_TIMEOUT_ALLOWED = 30;
/**
 * Constructor for PStream objects.
 *
 * @exports PStream as Twilio.PStream
 * @memberOf Twilio
 * @borrows EventEmitter#addListener as #addListener
 * @borrows EventEmitter#removeListener as #removeListener
 * @borrows EventEmitter#emit as #emit
 * @borrows EventEmitter#hasListener as #hasListener
 * @constructor
 * @param {string} token The Twilio capabilities JWT
 * @param {string[]} uris An array of PStream endpoint URIs
 * @param {object} [options]
 * @config {boolean} [options.backoffMaxMs=20000] Enable debugging
 */
var PStream = /** @class */function (_super) {
  __extends(PStream, _super);
  function PStream(token, uris, options) {
    var _this = _super.call(this) || this;
    if (!(_this instanceof PStream)) {
      return new PStream(token, uris, options);
    }
    var defaults = {
      TransportFactory: wstransport_1.default
    };
    options = options || {};
    for (var prop in defaults) {
      if (prop in options) {
        continue;
      }
      options[prop] = defaults[prop];
    }
    _this.options = options;
    _this.token = token || '';
    _this.status = 'disconnected';
    _this.gateway = null;
    _this.region = null;
    _this._messageQueue = [];
    _this._preferredUri = null;
    _this._uris = uris;
    _this._handleTransportClose = _this._handleTransportClose.bind(_this);
    _this._handleTransportError = _this._handleTransportError.bind(_this);
    _this._handleTransportMessage = _this._handleTransportMessage.bind(_this);
    _this._handleTransportOpen = _this._handleTransportOpen.bind(_this);
    _this._log = new log_1.default('PStream');
    // NOTE(mroberts): EventEmitter requires that we catch all errors.
    _this.on('error', function () {
      _this._log.warn('Unexpected error handled in pstream');
    });
    /*
     *events used by device
     *'invite',
     *'ready',
     *'error',
     *'offline',
     *
     *'cancel',
     *'presence',
     *'roster',
     *'answer',
     *'candidate',
     *'hangup'
     */
    var self = _this;
    _this.addListener('ready', function () {
      self.status = 'ready';
    });
    _this.addListener('offline', function () {
      self.status = 'offline';
    });
    _this.addListener('close', function () {
      self._log.info('Received "close" from server. Destroying PStream...');
      self._destroy();
    });
    _this.transport = new _this.options.TransportFactory(_this._uris, {
      backoffMaxMs: _this.options.backoffMaxMs,
      maxPreferredDurationMs: _this.options.maxPreferredDurationMs
    });
    Object.defineProperties(_this, {
      uri: {
        enumerable: true,
        get: function () {
          return this.transport.uri;
        }
      }
    });
    _this.transport.on('close', _this._handleTransportClose);
    _this.transport.on('error', _this._handleTransportError);
    _this.transport.on('message', _this._handleTransportMessage);
    _this.transport.on('open', _this._handleTransportOpen);
    _this.transport.open();
    return _this;
  }
  return PStream;
}(events_1.EventEmitter);
PStream.prototype._handleTransportClose = function () {
  this.emit('transportClose');
  if (this.status !== 'disconnected') {
    if (this.status !== 'offline') {
      this.emit('offline', this);
    }
    this.status = 'disconnected';
  }
};
PStream.prototype._handleTransportError = function (error) {
  if (!error) {
    this.emit('error', {
      error: {
        code: 31000,
        message: 'Websocket closed without a provided reason',
        twilioError: new errors_1.SignalingErrors.ConnectionDisconnected()
      }
    });
    return;
  }
  // We receive some errors without call metadata (just the error). We need to convert these
  // to be contained within the 'error' field so that these errors match the expected format.
  this.emit('error', typeof error.code !== 'undefined' ? {
    error: error
  } : error);
};
PStream.prototype._handleTransportMessage = function (msg) {
  if (!msg || !msg.data || typeof msg.data !== 'string') {
    return;
  }
  var _a = JSON.parse(msg.data),
    type = _a.type,
    _b = _a.payload,
    payload = _b === void 0 ? {} : _b;
  this.gateway = payload.gateway || this.gateway;
  this.region = payload.region || this.region;
  if (type === 'error' && payload.error) {
    payload.error.twilioError = new errors_1.SignalingErrors.ConnectionError();
  }
  this.emit(type, payload);
};
PStream.prototype._handleTransportOpen = function () {
  var _this = this;
  this.status = 'connected';
  this.setToken(this.token);
  this.emit('transportOpen');
  var messages = this._messageQueue.splice(0, this._messageQueue.length);
  messages.forEach(function (message) {
    return _this._publish.apply(_this, message);
  });
};
/**
 * @return {string}
 */
PStream.toString = function () {
  return '[Twilio.PStream class]';
};
PStream.prototype.toString = function () {
  return '[Twilio.PStream instance]';
};
PStream.prototype.setToken = function (token) {
  this._log.info('Setting token and publishing listen');
  this.token = token;
  var reconnectTimeout = 0;
  var t = this.options.maxPreferredDurationMs;
  this._log.info("maxPreferredDurationMs:" + t);
  if (typeof t === 'number' && t >= 0) {
    reconnectTimeout = Math.min(Math.ceil(t / 1000), MAX_RECONNECT_TIMEOUT_ALLOWED);
  }
  this._log.info("reconnectTimeout:" + reconnectTimeout);
  var payload = {
    browserinfo: getBrowserInfo(),
    reconnectTimeout: reconnectTimeout,
    token: token
  };
  this._publish('listen', payload);
};
PStream.prototype.sendMessage = function (callsid, content, contenttype, messagetype, voiceeventsid) {
  if (contenttype === void 0) {
    contenttype = 'application/json';
  }
  var payload = {
    callsid: callsid,
    content: content,
    contenttype: contenttype,
    messagetype: messagetype,
    voiceeventsid: voiceeventsid
  };
  this._publish('message', payload, true);
};
PStream.prototype.register = function (mediaCapabilities) {
  var regPayload = {
    media: mediaCapabilities
  };
  this._publish('register', regPayload, true);
};
PStream.prototype.invite = function (sdp, callsid, params) {
  var payload = {
    callsid: callsid,
    sdp: sdp,
    twilio: params ? {
      params: params
    } : {}
  };
  this._publish('invite', payload, true);
};
PStream.prototype.reconnect = function (sdp, callsid, reconnect) {
  var payload = {
    callsid: callsid,
    reconnect: reconnect,
    sdp: sdp,
    twilio: {}
  };
  this._publish('invite', payload, true);
};
PStream.prototype.answer = function (sdp, callsid) {
  this._publish('answer', {
    sdp: sdp,
    callsid: callsid
  }, true);
};
PStream.prototype.dtmf = function (callsid, digits) {
  this._publish('dtmf', {
    callsid: callsid,
    dtmf: digits
  }, true);
};
PStream.prototype.hangup = function (callsid, message) {
  var payload = message ? {
    callsid: callsid,
    message: message
  } : {
    callsid: callsid
  };
  this._publish('hangup', payload, true);
};
PStream.prototype.reject = function (callsid) {
  this._publish('reject', {
    callsid: callsid
  }, true);
};
PStream.prototype.reinvite = function (sdp, callsid) {
  this._publish('reinvite', {
    sdp: sdp,
    callsid: callsid
  }, false);
};
PStream.prototype._destroy = function () {
  this.transport.removeListener('close', this._handleTransportClose);
  this.transport.removeListener('error', this._handleTransportError);
  this.transport.removeListener('message', this._handleTransportMessage);
  this.transport.removeListener('open', this._handleTransportOpen);
  this.transport.close();
  this.emit('offline', this);
};
PStream.prototype.destroy = function () {
  this._log.info('PStream.destroy() called...');
  this._destroy();
  return this;
};
PStream.prototype.updatePreferredURI = function (uri) {
  this._preferredUri = uri;
  this.transport.updatePreferredURI(uri);
};
PStream.prototype.updateURIs = function (uris) {
  this._uris = uris;
  this.transport.updateURIs(this._uris);
};
PStream.prototype.publish = function (type, payload) {
  return this._publish(type, payload, true);
};
PStream.prototype._publish = function (type, payload, shouldRetry) {
  var msg = JSON.stringify({
    payload: payload,
    type: type,
    version: PSTREAM_VERSION
  });
  var isSent = !!this.transport.send(msg);
  if (!isSent) {
    this.emit('error', {
      error: {
        code: 31009,
        message: 'No transport available to send or receive messages',
        twilioError: new errors_1.GeneralErrors.TransportError()
      }
    });
    if (shouldRetry) {
      this._messageQueue.push([type, payload, true]);
    }
  }
};
function getBrowserInfo() {
  var nav = typeof navigator !== 'undefined' ? navigator : {};
  var info = {
    browser: {
      platform: nav.platform || 'unknown',
      userAgent: nav.userAgent || 'unknown'
    },
    p: 'browser',
    plugin: 'rtc',
    v: C.RELEASE_VERSION
  };
  return info;
}
exports.default = PStream;
