"use strict";

/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logger = void 0;
var LogLevelModule = require("loglevel");
var constants_1 = require("./constants");
/**
 * {@link Log} provides logging features throughout the sdk using loglevel module
 * See https://github.com/pimterry/loglevel for documentation
 * @private
 */
var Log = /** @class */function () {
  /**
   * @constructor
   * @param [tag] - tag name for the logs
   * @param [options] - Optional settings
   */
  function Log(tag, options) {
    this._log = Log.getLogLevelInstance(options);
    this._prefix = "[TwilioVoice][" + tag + "]";
  }
  /**
   * Return the `loglevel` instance maintained internally.
   * @param [options] - Optional settings
   * @returns The `loglevel` instance.
   */
  Log.getLogLevelInstance = function (options) {
    if (!Log.loglevelInstance) {
      try {
        Log.loglevelInstance = (options && options.LogLevelModule ? options.LogLevelModule : LogLevelModule).getLogger(constants_1.PACKAGE_NAME);
      } catch (_a) {
        // tslint:disable-next-line
        console.warn('Cannot create custom logger');
        Log.loglevelInstance = console;
      }
    }
    return Log.loglevelInstance;
  };
  /**
   * Log a debug message
   * @param args - Any number of arguments to be passed to loglevel.debug
   */
  Log.prototype.debug = function () {
    var _a;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    (_a = this._log).debug.apply(_a, __spreadArrays([this._prefix], args));
  };
  /**
   * Log an error message
   * @param args - Any number of arguments to be passed to loglevel.error
   */
  Log.prototype.error = function () {
    var _a;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    (_a = this._log).error.apply(_a, __spreadArrays([this._prefix], args));
  };
  /**
   * Log an info message
   * @param args - Any number of arguments to be passed to loglevel.info
   */
  Log.prototype.info = function () {
    var _a;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    (_a = this._log).info.apply(_a, __spreadArrays([this._prefix], args));
  };
  /**
   * Set a default log level to disable all logging below the given level
   */
  Log.prototype.setDefaultLevel = function (level) {
    if (this._log.setDefaultLevel) {
      this._log.setDefaultLevel(level);
    } else {
      // tslint:disable-next-line
      console.warn('Logger cannot setDefaultLevel');
    }
  };
  /**
   * Log a warning message
   * @param args - Any number of arguments to be passed to loglevel.warn
   */
  Log.prototype.warn = function () {
    var _a;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    (_a = this._log).warn.apply(_a, __spreadArrays([this._prefix], args));
  };
  /**
   * Log levels
   */
  Log.levels = LogLevelModule.levels;
  return Log;
}();
exports.Logger = Log.getLogLevelInstance();
exports.default = Log;
