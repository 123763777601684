<mat-drawer-container *ngIf="{ drawerMode: drawerMode$ | async } as opts">
  <mat-drawer
    #drawer
    [mode]="opts.drawerMode!"
    [opened]="opts.drawerMode === 'side'"
    class="conversation-list-container"
  >
    <div class="conversation-list-header">
      <button class="new-chat-button" mat-stroked-button (click)="createConversation()">
        <mat-icon matPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>plus</title>
            <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </mat-icon>
        <span>New chat</span>
      </button>
    </div>
    <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true">
      <mat-list-option *ngFor="let conversation of conversations$ | async" (click)="selectConversation(conversation)">
        {{ conversation.title }}
      </mat-list-option>
    </mat-selection-list>
  </mat-drawer>
  <mat-drawer-content>
    <ih-conversation (toggleMenu)="toggleMenu()" (deleted)="deleted($event)"></ih-conversation>
  </mat-drawer-content>
</mat-drawer-container>
