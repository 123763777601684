import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { Conversation, ConversationMessage } from '@ih/interfaces';
import { LbToBrPipe } from '@ih/pipes';
import { SafePipe } from '@ih/safe-pipe';
import { BehaviorSubject } from 'rxjs';
import { ChatService } from '../../chat.service';
import { ConversationMessageComponent } from '../conversation-message/conversation-message.component';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    NgClass,
    NgForOf,
    NgIf,

    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,

    AnimatedSaveButtonComponent,
    LbToBrPipe,

    SafePipe,

    ConversationMessageComponent
  ],
  selector: 'ih-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements AfterViewInit, OnDestroy {
  private chatService = inject(ChatService);

  @ViewChild('messagesContainer') messagesContainer!: ElementRef<HTMLDivElement>;
  @Output() toggleMenu = new EventEmitter<void>();
  @Output() deleted = new EventEmitter<Conversation>();

  editingMessage: ConversationMessage | undefined;
  selectedConversation$ = new BehaviorSubject<Conversation | null>(null);
  selectedMessage: ConversationMessage | null = null;
  editing: boolean = false;

  regeneratingResponse$ = new BehaviorSubject<boolean>(false);
  sending$ = new BehaviorSubject<boolean>(false);

  private shouldScroll: boolean = true;

  editMessage = new FormControl('', Validators.required);

  newMessageForm = new FormGroup({
    text: new FormControl('')
  });

  ngAfterViewInit(): void {
    this.chatService.selectedConversation$.subscribe((conversation) => {
      if (!conversation) {
        return;
      }

      if (this.shouldScroll) {
        // wait for dom to render
        setTimeout(() => {
          if (this.messagesContainer) {
            this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
          }
        });
      }

      // wait for dom to render
      setTimeout(() => {
        if (this.messagesContainer) {
          this.messagesContainer.nativeElement.addEventListener('scroll', this.handleScroll, { passive: true });
        }
      });
      // scroll to the bottom of the chat
      this.selectedConversation$.next(conversation);
      if (conversation?.messages.length > 0) {
        this.selectedMessage = conversation.messages[0]; // Select the first message by default
      }
    });
  }

  ngOnDestroy(): void {
    this.messagesContainer.nativeElement.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll = () => {
    // The user has manually scrolled if they are not currently at the bottom of the chat box
    this.shouldScroll =
      this.messagesContainer.nativeElement.scrollTop + this.messagesContainer.nativeElement.clientHeight >=
      this.messagesContainer.nativeElement.scrollHeight;
  };

  selectMessage(id: string): void {
    this.selectedMessage =
      this.selectedConversation$.value?.messages.find((message) => message.conversationMessageId === id) || null;
  }

  startEditMessage(message: ConversationMessage) {
    this.editingMessage = message;
  }

  submitEditMessage() {
    if (this.editingMessage) {
      this.chatService
        .editMessage(
          this.selectedConversation$.value!.conversationId,
          this.editingMessage.conversationMessageId,
          this.editingMessage.content
        )
        .subscribe(
          () => (this.editingMessage = undefined),
          (error) => console.error(error)
        );
    }
  }

  cancelEditMessage() {
    this.editingMessage = undefined;
  }

  switchEditMessage(messageId: string) {
    const message = this.selectedConversation$.value!.messages.find((m) => m.conversationMessageId === messageId);
    if (message) {
      this.editingMessage = message;
    }
  }

  saveConversation(): void {
    this.chatService.saveConversation(this.selectedConversation$.value!).subscribe();
  }

  submit(): void {
    if (!this.newMessageForm.value.text) {
      return;
    }

    this.sending$.next(true);

    const newMessage: ConversationMessage = {
      conversationId: this.selectedConversation$.value?.conversationId || '',
      conversationMessageId: crypto.randomUUID(),
      parentId: this.selectedMessage?.conversationMessageId,
      content: this.newMessageForm.value.text!,
      role: 'user', // Assuming the user is the one submitting messages
      timestamp: new Date(),
      children: []
    };
    this.chatService.addMessageToConversation(newMessage).subscribe(() => {
      this.sending$.next(false);
    });
    this.newMessageForm.setValue({ text: '' });
    this.selectedMessage = newMessage;

    requestAnimationFrame(() => {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    });
  }

  regenerateResponse(): void {
    this.regeneratingResponse$.next(true);
    this.chatService.regenerateResponse(this.selectedConversation$.value!).subscribe(() => {
      this.regeneratingResponse$.next(false);
    });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'Enter') {
      // Ctrl + Enter pressed, add a new line
      const currentValue = this.newMessageForm.value.text;
      this.newMessageForm.setValue({ text: `${currentValue}\n` });
    } else if (event.key === 'Enter') {
      // Just Enter pressed, submit the form
      event.preventDefault();
      this.submit();
    }
  }
}
