"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
// @ts-nocheck
var events_1 = require("events");
var EventTarget = /** @class */function () {
  function EventTarget() {
    this._eventEmitter = new events_1.EventEmitter();
  }
  EventTarget.prototype.addEventListener = function (name, handler) {
    return this._eventEmitter.addListener(name, handler);
  };
  EventTarget.prototype.dispatchEvent = function (name) {
    var _a;
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    return (_a = this._eventEmitter).emit.apply(_a, __spreadArrays([name], args));
  };
  EventTarget.prototype.removeEventListener = function (name, handler) {
    return this._eventEmitter.removeListener(name, handler);
  };
  return EventTarget;
}();
exports.default = EventTarget;
