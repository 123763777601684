<section class="app-froala-options">
  <header class="app-froala-options-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="app-froala-options-icon">
          <svg viewBox="0 0 24 24">
            <path fill="currentColor" d="M10,11A4,4 0 0,1 6,7A4,4 0 0,1 10,3H18V5H16V21H14V5H12V21H10V11Z" />
          </svg>
        </mat-icon>
        <div class="app-froala-options-title">Froala options</div>
      </div>
      <div class="save-btn-div fx-layout-align-end-center">
        <button class="save-btn" mat-raised-button color="primary" (click)="saveSettings()" [disabled]="savingSettings">
          Save
        </button>
      </div>
    </div>
  </header>

  <div class="app-froala-options-form-container">
    <form [formGroup]="bridgeSettingsForm" novalidate *ngIf="campaign">
      <div class="fx-layout-column fx-layout-align-start-stretch">
        <!--Froala Options Section-->
        <div class="app-froala-options section-block fx-layout-column fx-layout-align-start-stretch">
          <div class="app-froala-options-textarea">
            <textarea
              id="froalaOptionsEditor"
              formControlName="froalaOptions"
              placeholder="The classes should be defined in CSS, otherwise no changes will be visible on the paragraph's appearance."
              data-lpignore="true"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
