"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PreflightTest = void 0;
/**
 * @packageDocumentation
 * @module Voice
 * @preferred
 * @publicapi
 */
var events_1 = require("events");
var call_1 = require("../call");
var device_1 = require("../device");
var errors_1 = require("../errors");
var log_1 = require("../log");
var stats_1 = require("../rtc/stats");
var constants_1 = require("../constants");
/**
 * Runs some tests to identify issues, if any, prohibiting successful calling.
 */
var PreflightTest = /** @class */function (_super) {
  __extends(PreflightTest, _super);
  /**
   * Construct a {@link PreflightTest} instance.
   * @constructor
   * @param token - A Twilio JWT token string.
   * @param options
   */
  function PreflightTest(token, options) {
    var _this = _super.call(this) || this;
    /**
     * Whether this test has already logged an insights-connection-warning.
     */
    _this._hasInsightsErrored = false;
    /**
     * An instance of Logger to use.
     */
    _this._log = new log_1.default('PreflightTest');
    /**
     * Network related timing measurements for this test
     */
    _this._networkTiming = {};
    /**
     * The options passed to {@link PreflightTest} constructor
     */
    _this._options = {
      codecPreferences: [call_1.default.Codec.PCMU, call_1.default.Codec.Opus],
      edge: 'roaming',
      fakeMicInput: false,
      logLevel: 'error',
      signalingTimeoutMs: 10000
    };
    /**
     * Current status of this test
     */
    _this._status = PreflightTest.Status.Connecting;
    Object.assign(_this._options, options);
    _this._samples = [];
    _this._warnings = [];
    _this._startTime = Date.now();
    _this._initDevice(token, __assign(__assign({}, _this._options), {
      fileInputStream: _this._options.fakeMicInput ? _this._getStreamFromFile() : undefined
    }));
    // Device sets the loglevel so start logging after initializing the device.
    // Then selectively log options that users can modify.
    var userOptions = ['codecPreferences', 'edge', 'fakeMicInput', 'logLevel', 'signalingTimeoutMs'];
    var userOptionOverrides = ['audioContext', 'deviceFactory', 'fileInputStream', 'getRTCIceCandidateStatsReport', 'iceServers', 'rtcConfiguration'];
    if (typeof options === 'object') {
      var toLog_1 = __assign({}, options);
      Object.keys(toLog_1).forEach(function (key) {
        if (!userOptions.includes(key) && !userOptionOverrides.includes(key)) {
          delete toLog_1[key];
        }
        if (userOptionOverrides.includes(key)) {
          toLog_1[key] = true;
        }
      });
      _this._log.debug('.constructor', JSON.stringify(toLog_1));
    }
    return _this;
  }
  /**
   * Stops the current test and raises a failed event.
   */
  PreflightTest.prototype.stop = function () {
    var _this = this;
    this._log.debug('.stop');
    var error = new errors_1.GeneralErrors.CallCancelledError();
    if (this._device) {
      this._device.once(device_1.default.EventName.Unregistered, function () {
        return _this._onFailed(error);
      });
      this._device.destroy();
    } else {
      this._onFailed(error);
    }
  };
  /**
   * Emit a {PreflightTest.Warning}
   */
  PreflightTest.prototype._emitWarning = function (name, description, rtcWarning) {
    var warning = {
      name: name,
      description: description
    };
    if (rtcWarning) {
      warning.rtcWarning = rtcWarning;
    }
    this._warnings.push(warning);
    this._log.debug("#" + PreflightTest.Events.Warning, JSON.stringify(warning));
    this.emit(PreflightTest.Events.Warning, warning);
  };
  /**
   * Returns call quality base on the RTC Stats
   */
  PreflightTest.prototype._getCallQuality = function (mos) {
    if (mos > 4.2) {
      return PreflightTest.CallQuality.Excellent;
    } else if (mos >= 4.1 && mos <= 4.2) {
      return PreflightTest.CallQuality.Great;
    } else if (mos >= 3.7 && mos <= 4) {
      return PreflightTest.CallQuality.Good;
    } else if (mos >= 3.1 && mos <= 3.6) {
      return PreflightTest.CallQuality.Fair;
    } else {
      return PreflightTest.CallQuality.Degraded;
    }
  };
  /**
   * Returns the report for this test.
   */
  PreflightTest.prototype._getReport = function () {
    var _a, _b, _c;
    var stats = this._getRTCStats();
    var testTiming = {
      start: this._startTime
    };
    if (this._endTime) {
      testTiming.end = this._endTime;
      testTiming.duration = this._endTime - this._startTime;
    }
    var report = {
      callSid: this._callSid,
      edge: this._edge,
      iceCandidateStats: (_b = (_a = this._rtcIceCandidateStatsReport) === null || _a === void 0 ? void 0 : _a.iceCandidateStats) !== null && _b !== void 0 ? _b : [],
      networkTiming: this._networkTiming,
      samples: this._samples,
      selectedEdge: this._options.edge,
      stats: stats,
      testTiming: testTiming,
      totals: this._getRTCSampleTotals(),
      warnings: this._warnings
    };
    var selectedIceCandidatePairStats = (_c = this._rtcIceCandidateStatsReport) === null || _c === void 0 ? void 0 : _c.selectedIceCandidatePairStats;
    if (selectedIceCandidatePairStats) {
      report.selectedIceCandidatePairStats = selectedIceCandidatePairStats;
      report.isTurnRequired = selectedIceCandidatePairStats.localCandidate.candidateType === 'relay' || selectedIceCandidatePairStats.remoteCandidate.candidateType === 'relay';
    }
    if (stats) {
      report.callQuality = this._getCallQuality(stats.mos.average);
    }
    return report;
  };
  /**
   * Returns RTC stats totals for this test
   */
  PreflightTest.prototype._getRTCSampleTotals = function () {
    if (!this._latestSample) {
      return;
    }
    return __assign({}, this._latestSample.totals);
  };
  /**
   * Returns RTC related stats captured during the test call
   */
  PreflightTest.prototype._getRTCStats = function () {
    var firstMosSampleIdx = this._samples.findIndex(function (sample) {
      return typeof sample.mos === 'number' && sample.mos > 0;
    });
    var samples = firstMosSampleIdx >= 0 ? this._samples.slice(firstMosSampleIdx) : [];
    if (!samples || !samples.length) {
      return;
    }
    return ['jitter', 'mos', 'rtt'].reduce(function (statObj, stat) {
      var _a;
      var values = samples.map(function (s) {
        return s[stat];
      });
      return __assign(__assign({}, statObj), (_a = {}, _a[stat] = {
        average: Number((values.reduce(function (total, value) {
          return total + value;
        }) / values.length).toPrecision(5)),
        max: Math.max.apply(Math, values),
        min: Math.min.apply(Math, values)
      }, _a));
    }, {});
  };
  /**
   * Returns a MediaStream from a media file
   */
  PreflightTest.prototype._getStreamFromFile = function () {
    var audioContext = this._options.audioContext;
    if (!audioContext) {
      throw new errors_1.NotSupportedError('Cannot fake input audio stream: AudioContext is not supported by this browser.');
    }
    var audioEl = new Audio(constants_1.COWBELL_AUDIO_URL);
    audioEl.addEventListener('canplaythrough', function () {
      return audioEl.play();
    });
    if (typeof audioEl.setAttribute === 'function') {
      audioEl.setAttribute('crossorigin', 'anonymous');
    }
    var src = audioContext.createMediaElementSource(audioEl);
    var dest = audioContext.createMediaStreamDestination();
    src.connect(dest);
    return dest.stream;
  };
  /**
   * Initialize the device
   */
  PreflightTest.prototype._initDevice = function (token, options) {
    var _this = this;
    try {
      this._device = new (options.deviceFactory || device_1.default)(token, {
        codecPreferences: options.codecPreferences,
        edge: options.edge,
        fileInputStream: options.fileInputStream,
        logLevel: options.logLevel,
        preflight: true
      });
      this._device.once(device_1.default.EventName.Registered, function () {
        _this._onDeviceRegistered();
      });
      this._device.once(device_1.default.EventName.Error, function (error) {
        _this._onDeviceError(error);
      });
      this._device.register();
    } catch (error) {
      // We want to return before failing so the consumer can capture the event
      setTimeout(function () {
        _this._onFailed(error);
      });
      return;
    }
    this._signalingTimeoutTimer = setTimeout(function () {
      _this._onDeviceError(new errors_1.SignalingErrors.ConnectionError('WebSocket Connection Timeout'));
    }, options.signalingTimeoutMs);
  };
  /**
   * Called on {@link Device} error event
   * @param error
   */
  PreflightTest.prototype._onDeviceError = function (error) {
    this._device.destroy();
    this._onFailed(error);
  };
  /**
   * Called on {@link Device} ready event
   */
  PreflightTest.prototype._onDeviceRegistered = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, audio, publisher;
      var _this = this;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            clearTimeout(this._echoTimer);
            clearTimeout(this._signalingTimeoutTimer);
            _a = this;
            return [4 /*yield*/, this._device.connect({
              rtcConfiguration: this._options.rtcConfiguration
            })];
          case 1:
            _a._call = _b.sent();
            this._networkTiming.signaling = {
              start: Date.now()
            };
            this._setupCallHandlers(this._call);
            this._edge = this._device.edge || undefined;
            if (this._options.fakeMicInput) {
              this._echoTimer = setTimeout(function () {
                return _this._device.disconnectAll();
              }, constants_1.ECHO_TEST_DURATION);
              audio = this._device.audio;
              if (audio) {
                audio.disconnect(false);
                audio.outgoing(false);
              }
            }
            this._call.once('disconnect', function () {
              _this._device.once(device_1.default.EventName.Unregistered, function () {
                return _this._onUnregistered();
              });
              _this._device.destroy();
            });
            publisher = this._call['_publisher'];
            publisher.on('error', function () {
              if (!_this._hasInsightsErrored) {
                _this._emitWarning('insights-connection-error', 'Received an error when attempting to connect to Insights gateway');
              }
              _this._hasInsightsErrored = true;
            });
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * Called when there is a fatal error
   * @param error
   */
  PreflightTest.prototype._onFailed = function (error) {
    clearTimeout(this._echoTimer);
    clearTimeout(this._signalingTimeoutTimer);
    this._releaseHandlers();
    this._endTime = Date.now();
    this._status = PreflightTest.Status.Failed;
    this._log.debug("#" + PreflightTest.Events.Failed, error);
    this.emit(PreflightTest.Events.Failed, error);
  };
  /**
   * Called when the device goes offline.
   * This indicates that the test has been completed, but we won't know if it failed or not.
   * The onError event will be the indicator whether the test failed.
   */
  PreflightTest.prototype._onUnregistered = function () {
    var _this = this;
    // We need to make sure we always execute preflight.on('completed') last
    // as client SDK sometimes emits 'offline' event before emitting fatal errors.
    setTimeout(function () {
      if (_this._status === PreflightTest.Status.Failed) {
        return;
      }
      clearTimeout(_this._echoTimer);
      clearTimeout(_this._signalingTimeoutTimer);
      _this._releaseHandlers();
      _this._endTime = Date.now();
      _this._status = PreflightTest.Status.Completed;
      _this._report = _this._getReport();
      _this._log.debug("#" + PreflightTest.Events.Completed, JSON.stringify(_this._report));
      _this.emit(PreflightTest.Events.Completed, _this._report);
    }, 10);
  };
  /**
   * Clean up all handlers for device and call
   */
  PreflightTest.prototype._releaseHandlers = function () {
    [this._device, this._call].forEach(function (emitter) {
      if (emitter) {
        emitter.eventNames().forEach(function (name) {
          return emitter.removeAllListeners(name);
        });
      }
    });
  };
  /**
   * Setup the event handlers for the {@link Call} of the test call
   * @param call
   */
  PreflightTest.prototype._setupCallHandlers = function (call) {
    var _this = this;
    if (this._options.fakeMicInput) {
      // When volume events start emitting, it means all audio outputs have been created.
      // Let's mute them if we're using fake mic input.
      call.once('volume', function () {
        call['_mediaHandler'].outputs.forEach(function (output) {
          return output.audio.muted = true;
        });
      });
    }
    call.on('warning', function (name, data) {
      _this._emitWarning(name, 'Received an RTCWarning. See .rtcWarning for the RTCWarning', data);
    });
    call.once('accept', function () {
      _this._callSid = call['_mediaHandler'].callSid;
      _this._status = PreflightTest.Status.Connected;
      _this._log.debug("#" + PreflightTest.Events.Connected);
      _this.emit(PreflightTest.Events.Connected);
    });
    call.on('sample', function (sample) {
      return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              if (!!this._latestSample) return [3 /*break*/, 2];
              _a = this;
              return [4 /*yield*/, (this._options.getRTCIceCandidateStatsReport || stats_1.getRTCIceCandidateStatsReport)(call['_mediaHandler'].version.pc)];
            case 1:
              _a._rtcIceCandidateStatsReport = _b.sent();
              _b.label = 2;
            case 2:
              this._latestSample = sample;
              this._samples.push(sample);
              this._log.debug("#" + PreflightTest.Events.Sample, JSON.stringify(sample));
              this.emit(PreflightTest.Events.Sample, sample);
              return [2 /*return*/];
          }
        });
      });
    });
    // TODO: Update the following once the SDK supports emitting these events
    // Let's shim for now
    [{
      reportLabel: 'peerConnection',
      type: 'pcconnection'
    }, {
      reportLabel: 'ice',
      type: 'iceconnection'
    }, {
      reportLabel: 'dtls',
      type: 'dtlstransport'
    }, {
      reportLabel: 'signaling',
      type: 'signaling'
    }].forEach(function (_a) {
      var type = _a.type,
        reportLabel = _a.reportLabel;
      var handlerName = "on" + type + "statechange";
      var originalHandler = call['_mediaHandler'][handlerName];
      call['_mediaHandler'][handlerName] = function (state) {
        var timing = _this._networkTiming[reportLabel] = _this._networkTiming[reportLabel] || {
          start: 0
        };
        if (state === 'connecting' || state === 'checking') {
          timing.start = Date.now();
        } else if ((state === 'connected' || state === 'stable') && !timing.duration) {
          timing.end = Date.now();
          timing.duration = timing.end - timing.start;
        }
        originalHandler(state);
      };
    });
  };
  Object.defineProperty(PreflightTest.prototype, "callSid", {
    /**
     * The callsid generated for the test call.
     */
    get: function () {
      return this._callSid;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PreflightTest.prototype, "endTime", {
    /**
     * A timestamp in milliseconds of when the test ended.
     */
    get: function () {
      return this._endTime;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PreflightTest.prototype, "latestSample", {
    /**
     * The latest WebRTC sample collected.
     */
    get: function () {
      return this._latestSample;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PreflightTest.prototype, "report", {
    /**
     * The report for this test.
     */
    get: function () {
      return this._report;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PreflightTest.prototype, "startTime", {
    /**
     * A timestamp in milliseconds of when the test started.
     */
    get: function () {
      return this._startTime;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PreflightTest.prototype, "status", {
    /**
     * The status of the test.
     */
    get: function () {
      return this._status;
    },
    enumerable: false,
    configurable: true
  });
  return PreflightTest;
}(events_1.EventEmitter);
exports.PreflightTest = PreflightTest;
(function (PreflightTest) {
  /**
   * The quality of the call determined by different mos ranges.
   * Mos is calculated base on the WebRTC stats - rtt, jitter, and packet lost.
   */
  var CallQuality;
  (function (CallQuality) {
    /**
     * If the average mos is over 4.2.
     */
    CallQuality["Excellent"] = "excellent";
    /**
     * If the average mos is between 4.1 and 4.2 both inclusive.
     */
    CallQuality["Great"] = "great";
    /**
     * If the average mos is between 3.7 and 4.0 both inclusive.
     */
    CallQuality["Good"] = "good";
    /**
     * If the average mos is between 3.1 and 3.6 both inclusive.
     */
    CallQuality["Fair"] = "fair";
    /**
     * If the average mos is 3.0 or below.
     */
    CallQuality["Degraded"] = "degraded";
  })(CallQuality = PreflightTest.CallQuality || (PreflightTest.CallQuality = {}));
  /**
   * Possible events that a [[PreflightTest]] might emit.
   */
  var Events;
  (function (Events) {
    /**
     * See [[PreflightTest.completedEvent]]
     */
    Events["Completed"] = "completed";
    /**
     * See [[PreflightTest.connectedEvent]]
     */
    Events["Connected"] = "connected";
    /**
     * See [[PreflightTest.failedEvent]]
     */
    Events["Failed"] = "failed";
    /**
     * See [[PreflightTest.sampleEvent]]
     */
    Events["Sample"] = "sample";
    /**
     * See [[PreflightTest.warningEvent]]
     */
    Events["Warning"] = "warning";
  })(Events = PreflightTest.Events || (PreflightTest.Events = {}));
  /**
   * Possible status of the test.
   */
  var Status;
  (function (Status) {
    /**
     * Call to Twilio has initiated.
     */
    Status["Connecting"] = "connecting";
    /**
     * Call to Twilio has been established.
     */
    Status["Connected"] = "connected";
    /**
     * The connection to Twilio has been disconnected and the test call has completed.
     */
    Status["Completed"] = "completed";
    /**
     * The test has stopped and failed.
     */
    Status["Failed"] = "failed";
  })(Status = PreflightTest.Status || (PreflightTest.Status = {}));
})(PreflightTest = exports.PreflightTest || (exports.PreflightTest = {}));
exports.PreflightTest = PreflightTest;
