import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FilterQueryService {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  // Update URL with filter parameters
  updateQueryParams(filters: Record<string, string>): void {
    // Remove null, undefined, or empty string values
    const cleanFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    // Update URL without reloading the page
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: cleanFilters,
      queryParamsHandling: 'merge' // Preserve existing query params
    });
  }

  // Get current filter values from URL
  getQueryParams(): Promise<Record<string, string>> {
    return new Promise((resolve) => {
      this.activatedRoute.queryParams.subscribe((params) => {
        resolve(params);
      });
    });
  }

  // Clear all filter parameters from URL
  clearQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {}
    });
  }
}
