"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PeerConnection = exports.getMediaEngine = exports.enabled = void 0;
/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
// @ts-nocheck
var peerconnection_1 = require("./peerconnection");
exports.PeerConnection = peerconnection_1.default;
var rtcpc_1 = require("./rtcpc");
function enabled() {
  return rtcpc_1.default.test();
}
exports.enabled = enabled;
function getMediaEngine() {
  return typeof RTCIceGatherer !== 'undefined' ? 'ORTC' : 'WebRTC';
}
exports.getMediaEngine = getMediaEngine;
