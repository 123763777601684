<ng-container *ngIf="{ codeVisible: codeVisible$ | async } as opts">
  <div class="hover-container" *ngIf="!opts.codeVisible" (click)="showEditor()">
    <div class="hover-overlay">
      <mat-icon *ngIf="excerpt" svgIcon="pencil"></mat-icon>
    </div>
    <div *ngIf="!excerpt && !value" class="add-customization">
      <mat-icon svgIcon="plus"></mat-icon>
      New customization
    </div>
    <div *ngIf="excerpt" class="code-mirror-excerpt" [innerHtml]="excerpt | safe"></div>
  </div>
  <ng-container *ngIf="opts.codeVisible">
    <textarea class="code-mirror-text-area" [placeholder]="placeholder" data-lpignore="true"></textarea>
    <div class="action-buttons">
      <button mat-button (click)="hideEditor()">Cancel</button>
      <button mat-flat-button color="primary" (click)="update()">Save</button>
    </div>
  </ng-container>
</ng-container>
