import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppMember, Member, UserRole } from '@ih/interfaces';
import { LazySnackBarService } from '@ih/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'ih-bridge-user-editor',
  templateUrl: './bridge-user-editor.component.html',
  styleUrls: ['./bridge-user-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    ReactiveFormsModule
  ]
})
export class BridgeUserEditorComponent implements OnInit, OnDestroy {
  @HostBinding('class.ih-bridge-user-editor') hostClass = true;

  editBridgeUserForm = new UntypedFormGroup({
    fullName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', Validators.required),
    userRoleId: new UntypedFormControl(null, Validators.required)
  });

  private destroy$ = new Subject<void>();
  saving$ = new BehaviorSubject(false);
  loading = false;
  member$: Observable<Member>;

  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public campaignUserId: number,
    public mdDialogRef: MatDialogRef<BridgeUserEditorComponent>,
    private snackbar: LazySnackBarService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.loading = true;
    this.http.get<AppMember>('/api/users/' + this.campaignUserId).subscribe((user) => {
      this.editBridgeUserForm.setValue({ fullName: user.fullName, email: user.email, userRoleId: user.userRoleId });
    });
  }

  cancel(): void {
    this.mdDialogRef.close();
  }

  compareRole(o1: UserRole, o2: UserRole): boolean {
    return o1 && o2 ? o1.userRoleId === o2.userRoleId && o1.immutable === o2.immutable : o1 === o2;
  }

  forgotPassword(): void {
    this.saving$.next(true);
    this.http
      .post('/api/users/' + this.campaignUserId + '/forgotPassword', this.editBridgeUserForm.controls.email.value)
      .subscribe(
        () => {
          this.saving$.next(false);
          this.snackbar.open(`Sent forgot password email to ${this.editBridgeUserForm.controls.email.value}`);
        },
        () => {
          this.saving$.next(false);
          this.snackbar
            .open('Unable to send forgot password', 'TRY AGAIN')
            .then((ref) => ref.onAction().subscribe(() => this.forgotPassword()));
        }
      );
  }

  save(): void {
    if (this.editBridgeUserForm.valid) {
      this.http
        .put(`/api/users/${this.campaignUserId}`, this.editBridgeUserForm.getRawValue())
        .pipe(
          catchError((err, caught) => {
            this.snackbar
              .open('Unable to save your settings', 'TRY AGAIN')
              .then((ref) => ref.onAction().subscribe(() => caught));
            throw err;
          })
        )
        .subscribe(() => {
          this.snackbar.open('User updated ' + this.editBridgeUserForm.controls.email.value);
          this.mdDialogRef.close(this.editBridgeUserForm.value);
        });
    }
  }
}
