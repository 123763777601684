import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';

import { FroalaComponent } from '@ih/froala';
import { LazySnackBarService } from '@ih/services';
import { SideNavService } from '../side-nav.service';

@Component({
  selector: 'ih-app-eula',
  templateUrl: './app-eula.component.html',
  styleUrls: ['./app-eula.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule, FroalaComponent]
})
export class AppEulaComponent implements OnInit {
  @HostBinding('class.ih-app-eula') hostClass = true;

  campaignId: number;
  savingSettings = false;

  eula = new UntypedFormControl();

  froalaConfig: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private snackbar: LazySnackBarService,
    private sideNavService: SideNavService
  ) {}

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.params.campaignId;
    this.sideNavService.setCurrentApp(this.campaignId);
    this.http.get<any>('/api/campaigns/' + this.campaignId).subscribe((campaign) => {
      this.froalaConfig = {
        fileUploadURL: `/api/campaign/${campaign.campaignId}/${campaign.slug}/froala/file`,
        videoUploadURL: `/api/campaign/${campaign.campaignId}/${campaign.slug}/froala/file`,
        imageUploadURL: `/api/campaign/${campaign.campaignId}/${campaign.slug}/froala/image`
      };
      this.eula.setValue(campaign.customEula);
    });
  }

  saveEula(): void {
    this.savingSettings = true;
    this.http
      .request('PUT', 'api/campaigns/' + this.campaignId + '/customEula', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.eula.value)
      })
      .subscribe(
        () => {
          this.snackbar.open('Eula updated');

          this.savingSettings = false;
        },
        (resp) => {
          // ignore cancelled
          if (resp.xhrStatus === 'abort') {
            return;
          }

          this.savingSettings = false;
          this.snackbar.open('Unable to save eula');
        }
      );
  }
}
