import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { BridgeUserEditorComponent } from './bridge-user-editor.component';

@Injectable({
  providedIn: 'root'
})
export class BridgeUserEditorDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(campaignUserId: number): Promise<MatDialogRef<BridgeUserEditorComponent>> {
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(BridgeUserEditorComponent, {
      data: campaignUserId,
      panelClass: ['bridge-user-editor-dialog', 'dialog-no-padding', 'dialog-fullscreen'],
      maxWidth: undefined,
      minHeight: 'unset'
    });
  }
}
