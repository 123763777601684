<section class="app-custom-css">
  <header class="app-custom-css-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="app-custom-css-icon">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8,3A2,2 0 0,0 6,5V9A2,2 0 0,1 4,11H3V13H4A2,2 0 0,1 6,15V19A2,2 0 0,0 8,21H10V19H8V14A2,2 0 0,0 6,12A2,2 0 0,0 8,10V5H10V3M16,3A2,2 0 0,1 18,5V9A2,2 0 0,0 20,11H21V13H20A2,2 0 0,0 18,15V19A2,2 0 0,1 16,21H14V19H16V14A2,2 0 0,1 18,12A2,2 0 0,1 16,10V5H14V3H16Z"
            />
          </svg>
        </mat-icon>
        <div class="app-custom-css-title">Custom CSS</div>
      </div>
      <div class="save-btn-div fx-layout-align-end-center">
        <button
          class="save-btn"
          mat-raised-button
          color="primary"
          (click)="saveSettings()"
          [disabled]="savingSettings$ | async"
        >
          Save
        </button>
      </div>
    </div>
  </header>

  <div class="app-custom-css-form-container">
    <form [formGroup]="bridgeSettingsForm" novalidate *ngIf="campaign">
      <div class="fx-layout-column fx-layout-align-start-stretch">
        <!--Custom CSS Section-->
        <div class="app-custom-css section-block fx-layout-column fx-layout-align-start-stretch">
          <div class="app-custom-css-textarea">
            <textarea
              id="cssEditor"
              formControlName="customCss"
              placeholder="a { color:#FF0000; }"
              data-lpignore="true"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
