<section class="app-custom-eula">
  <header class="app-custom-eula-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="app-custom-eula-icon">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z"
            />
          </svg>
        </mat-icon>
        <div class="app-custom-eula-title">Custom EULA</div>
      </div>
      <div class="save-btn-div fx-layout-align-end-center">
        <button class="save-btn" mat-raised-button color="primary" (click)="saveEula()" [disabled]="savingSettings">
          Save
        </button>
      </div>
    </div>
  </header>

  <div class="app-custom-eula-form-container">
    <ih-froala
      contentType="eula"
      name="body"
      [formControl]="eula"
      [config]="froalaConfig"
      [toolbarStickyOffset]="118"
      [allowFileUpload]="false"
      [allowImageUpload]="false"
      [allowVideoUpload]="false"
    ></ih-froala>
  </div>
</section>
