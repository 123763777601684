import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type OwnerTransferDialogComponent } from './owner-transfer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class OwnerTransferDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(campaign: any): Promise<MatDialogRef<OwnerTransferDialogComponent>> {
    const ownerTransferDialogComponent = await import('./owner-transfer-dialog.component').then(
      (m) => m.OwnerTransferDialogComponent
    );
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(ownerTransferDialogComponent, {
      panelClass: ['owner-transfer-dialog', 'dialog-no-padding'],
      data: campaign,
      maxWidth: undefined
    });
  }
}
