"use strict";

/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IceCandidate = void 0;
/**
 * {@link RTCIceCandidate} parses an ICE candidate gathered by the browser
 * and returns a IceCandidate object
 */
var IceCandidate = /** @class */function () {
  /**
   * @constructor
   * @param iceCandidate RTCIceCandidate coming from the browser
   */
  function IceCandidate(iceCandidate, isRemote) {
    if (isRemote === void 0) {
      isRemote = false;
    }
    /**
     * Whether this is deleted from the list of candidate gathered
     */
    this.deleted = false;
    var cost;
    var parts = iceCandidate.candidate.split('network-cost ');
    if (parts[1]) {
      cost = parseInt(parts[1], 10);
    }
    this.candidateType = iceCandidate.type;
    this.ip = iceCandidate.ip || iceCandidate.address;
    this.isRemote = isRemote;
    this.networkCost = cost;
    this.port = iceCandidate.port;
    this.priority = iceCandidate.priority;
    this.protocol = iceCandidate.protocol;
    this.relatedAddress = iceCandidate.relatedAddress;
    this.relatedPort = iceCandidate.relatedPort;
    this.tcpType = iceCandidate.tcpType;
    this.transportId = iceCandidate.sdpMid;
  }
  /**
   * Get the payload object for insights
   */
  IceCandidate.prototype.toPayload = function () {
    return {
      'candidate_type': this.candidateType,
      'deleted': this.deleted,
      'ip': this.ip,
      'is_remote': this.isRemote,
      'network-cost': this.networkCost,
      'port': this.port,
      'priority': this.priority,
      'protocol': this.protocol,
      'related_address': this.relatedAddress,
      'related_port': this.relatedPort,
      'tcp_type': this.tcpType,
      'transport_id': this.transportId
    };
  };
  return IceCandidate;
}();
exports.IceCandidate = IceCandidate;
