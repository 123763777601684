"use strict";

/**
 * @packageDocumentation
 * @module Voice
 * @internalapi
 */
var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AudioProcessorEventObserver = void 0;
var events_1 = require("events");
var log_1 = require("./log");
/**
 * AudioProcessorEventObserver observes {@link AudioProcessor}
 * related operations and re-emits them as generic events.
 * @private
 */
var AudioProcessorEventObserver = /** @class */function (_super) {
  __extends(AudioProcessorEventObserver, _super);
  function AudioProcessorEventObserver() {
    var _this = _super.call(this) || this;
    _this._log = new log_1.default('AudioProcessorEventObserver');
    _this._log.info('Creating AudioProcessorEventObserver instance');
    _this.on('enabled', function () {
      return _this._reEmitEvent('enabled');
    });
    _this.on('add', function () {
      return _this._reEmitEvent('add');
    });
    _this.on('remove', function () {
      return _this._reEmitEvent('remove');
    });
    _this.on('create', function () {
      return _this._reEmitEvent('create-processed-stream');
    });
    _this.on('destroy', function () {
      return _this._reEmitEvent('destroy-processed-stream');
    });
    return _this;
  }
  AudioProcessorEventObserver.prototype.destroy = function () {
    this.removeAllListeners();
  };
  AudioProcessorEventObserver.prototype._reEmitEvent = function (name) {
    this._log.info("AudioProcessor:" + name);
    this.emit('event', {
      name: name,
      group: 'audio-processor'
    });
  };
  return AudioProcessorEventObserver;
}(events_1.EventEmitter);
exports.AudioProcessorEventObserver = AudioProcessorEventObserver;
