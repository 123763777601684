import { Injectable } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { NG1_MODULE_NAME } from '../ng1/app.module.ng1';

@Injectable({
  providedIn: 'root'
})
export class AngularjsLazyLoaderService {
  private app: angular.auto.IInjectorService;

  constructor(private upgrade: UpgradeModule) {}

  load(el: HTMLElement): void {
    import('../ng1/app.module.ng1').then(() => {
      try {
        this.upgrade.bootstrap(el, [NG1_MODULE_NAME], { strictDi: true });
        this.app = this.upgrade.$injector;
      } catch (e) {
        console.error(e);
      }
    });
  }

  destroy() {
    if (this.app) {
      this.app.get('$rootScope').$destroy();
    }
  }
}
