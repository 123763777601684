<section class="wrapper site-min-height bridge-user-editor">
  <mat-toolbar>
    <div class="mat-toolbar-tools fx-layout-row fx-layout-align-start-center fx-flex">
      <div class="fx-layout-row fx-layout-align-start-center">
        <button mat-icon-button class="bridge-user-editor-close-btn" (click)="cancel()">
          <mat-icon>
            <svg id="close" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </mat-icon>
        </button>
      </div>

      <div class="bridge-user-editor-title">
        <h2>Edit bridge user</h2>
      </div>

      <span class="fx-flex"></span>

      <button
        mat-flat-button
        form="editBridgeUserForm"
        type="submit"
        class="bridge-user-editor-save-btn"
        [disabled]="saving$ | async"
      >
        SAVE
      </button>
    </div>
  </mat-toolbar>

  <div class="row">
    <div class="fx-layout-row fx-layout-align-center" *ngIf="!loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <form
    class="bridge-user-editor-form"
    [formGroup]="editBridgeUserForm"
    novalidate
    id="editBridgeUserForm"
    method="POST"
    (ngSubmit)="save()"
  >
    <div class="bridge-user-editor-fields">
      <div class="bridge-user-editor-fullname">
        <mat-form-field appearance="outline" class="bridge-user-editor-form-field">
          <mat-label>Full name</mat-label>
          <input matInput type="text" formControlName="fullName" />
          <mat-error *ngIf="editBridgeUserForm.get('fullName').hasError('required')">
            Please enter the user's name
          </mat-error>
        </mat-form-field>
      </div>

      <div class="bridge-user-editor-email">
        <mat-form-field appearance="outline" class="bridge-user-editor-form-field">
          <mat-label>Email</mat-label>
          <input
            matInput
            name="email"
            type="email"
            placeholder="Email"
            required
            formControlName="email"
            maxlength="255"
          />
        </mat-form-field>
      </div>

      <div class="bridge-user-editor-user-hub-role">
        <mat-form-field appearance="outline" class="bridge-user-editor-form-field">
          <mat-label>Bridge user role</mat-label>
          <mat-select
            formControlName="userRoleId"
            aria-label="Select a role for {{ editBridgeUserForm.value.fullName }}"
          >
            <mat-optgroup label="Bridge user role">
              <mat-option [value]="-2"> Bridge officer </mat-option>
              <mat-option [value]="-1"> Bridge crew </mat-option>
              <mat-option [value]="999"> Normal user </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</section>
