import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LazySnackBarService, ScriptService } from '@ih/services';
import { encodeHtml, flattenObj, getPropertyValue } from '@ih/utilities';
import { BehaviorSubject, Subject, merge, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomizationEditorComponent } from '../customization-editor/customization-editor.component';

interface ChannelMatch {
  channelId: number;
  name: string;
  handle: string;
  upperHtml: string;
  upperHtmlExcerpt: string;
  lowerHtml: string;
  lowerHtmlExcerpt: string;
}

export interface CodeProperties {
  prettyName: string;
  type: string;
  mode: string;
  placeholder: string;
  update: any; // should be a method
}

export interface CustomizationSearchResult {
  campaignId: number;
  hubName: string;
  slug: string;
  isActive: boolean;
  isArchived: boolean;
  isDemo: boolean;
  isTestApp: boolean;
  isPrivate: boolean;
  url?: string;
  trackingCodeExcerpt: string;
  trackingCode: string;
  customCssExcerpt: string;
  customCss: string;
  bridgeTrackingCodeExcerpt: string;
  bridgeTrackingCode: string;
  bridgeCustomCssExcerpt: string;
  bridgeCustomCss: string;
  channels?: ChannelMatch[];
  numberOfCustomizations?: number;
}

@Component({
  selector: 'ih-search-customizations',
  templateUrl: './search-customizations.component.html',
  styleUrls: ['./search-customizations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,

    FormsModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,

    CustomizationEditorComponent
  ]
})
export class SearchCustomizationsComponent implements OnDestroy, AfterViewInit {
  @HostBinding('ih-search-customizations') hostClass = true;
  codeProperties: CodeProperties[] = [
    {
      prettyName: 'App Custom CSS',
      type: 'customCss',
      mode: 'css',
      placeholder: 'a { color:#FF0000; }',
      update: (campaignId: number, customCss: string): void => {
        const results = [...this.results$.value];
        const campaign = results.find((c) => c.campaignId === campaignId);

        campaign.customCss = customCss;
        campaign.customCssExcerpt = encodeHtml(customCss.substring(0, 100));
        this.results$.next(results);

        this.updateCustomization(campaignId, customCss, 'customCss');
      }
    },
    {
      prettyName: 'App Tracking Code',
      type: 'trackingCode',
      mode: 'htmlmixed',
      placeholder: '<script></script>',
      update: (campaignId: number, trackingCode: string): void => {
        const results = [...this.results$.value];
        const campaign = results.find((c) => c.campaignId === campaignId);

        campaign.trackingCode = trackingCode;
        campaign.trackingCodeExcerpt = encodeHtml(trackingCode.substring(0, 100));
        this.results$.next(results);

        this.updateCustomization(campaignId, trackingCode, 'trackingCode');
      }
    },
    {
      prettyName: 'Bridge Custom CSS',
      type: 'bridgeCustomCss',
      mode: 'css',
      placeholder: 'a { color:#FF0000; }',
      update: (campaignId: number, bridgeCustomCss: string): void => {
        const results = [...this.results$.value];
        const campaign = results.find((c) => c.campaignId === campaignId);

        campaign.bridgeCustomCss = bridgeCustomCss;
        campaign.bridgeCustomCssExcerpt = encodeHtml(bridgeCustomCss.substring(0, 100));
        this.results$.next(results);

        this.updateCustomization(campaignId, bridgeCustomCss, 'bridgeCustomCss');
      }
    },
    {
      prettyName: 'Bridge Tracking Code',
      type: 'bridgeTrackingCode',
      mode: 'htmlmixed',
      placeholder: '<script></script>',
      update: (campaignId: number, bridgeTrackingCode: string): void => {
        const results = [...this.results$.value];
        const campaign = results.find((c) => c.campaignId === campaignId);

        campaign.bridgeTrackingCode = bridgeTrackingCode;
        campaign.bridgeTrackingCodeExcerpt = encodeHtml(bridgeTrackingCode.substring(0, 100));
        this.results$.next(results);

        this.updateCustomization(campaignId, bridgeTrackingCode, 'bridgeTrackingCode');
      }
    },
    {
      prettyName: 'Channel Customization',
      type: 'channels',
      mode: 'htmlmixed',
      placeholder: '<script></script>',
      update: {
        upperHtml: (campaignId: number, upperHtml: string, channelId: number): void => {
          const results = [...this.results$.value];
          const campaign = results.find((c) => c.campaignId === campaignId);
          const channel = campaign.channels.find((c) => c.channelId === channelId);

          channel.upperHtml = upperHtml;
          channel.upperHtmlExcerpt = encodeHtml(upperHtml.substring(0, 100));
          this.results$.next(results);

          this.updateCustomization(campaignId, upperHtml, `channels/${channelId}/upperHtml`);
        },
        lowerHtml: (campaignId: number, lowerHtml: string, channelId: number): void => {
          const results = [...this.results$.value];
          const campaign = results.find((c) => c.campaignId === campaignId);
          const channel = campaign.channels.find((c) => c.channelId === channelId);

          channel.lowerHtml = lowerHtml;
          channel.lowerHtmlExcerpt = encodeHtml(lowerHtml.substring(0, 100));
          this.results$.next(results);

          this.updateCustomization(campaignId, lowerHtml, `channels/${channelId}/lowerHtml`);
        }
      }
    }
  ];

  query = new UntypedFormControl(''); // the search string control
  refresh$ = new Subject<void>(); // tied to the refresh list button
  loading$ = new BehaviorSubject<boolean>(false); // used for showing the loader

  results$ = new BehaviorSubject<CustomizationSearchResult[]>([]); // result that comes back from the server when searching
  hasResults$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  getPropertyValue = getPropertyValue; // mapping the method because it's used in the html
  constructor(
    private http: HttpClient,
    private snackbar: LazySnackBarService,
    private script: ScriptService
  ) {}

  ngAfterViewInit(): void {
    this.script.loadCodeMirror().subscribe(() => {
      merge(this.query.valueChanges.pipe(distinctUntilChanged(), debounceTime(600)), this.refresh$)
        .pipe(
          tap(() => this.loading$.next(true)),
          switchMap(() => {
            if (!this.query.value) {
              return of([]);
            }

            return this.http
              .get<CustomizationSearchResult[]>('/api/search/customizations', { params: { q: this.query.value } })
              .pipe(
                map((results) => {
                  results.forEach((result) => {
                    result.url = `https://${result.slug}${window.campaign.environment.urlPrefix}.${window.campaign.domains.app}`;
                    result.numberOfCustomizations = 0;
                    Object.keys(flattenObj(result)).forEach((key) => {
                      if (key.includes('Excerpt')) {
                        const property = key.replace('Excerpt', '');
                        // check if the excerpt is set
                        if (result[key]) {
                          // count it
                          ++result.numberOfCustomizations;
                        } else if (result[property]) {
                          // fall the excerpt back to the original value truncated to 100 characters
                          result[key] =
                            encodeHtml(result[property].substring(0, 100)) +
                            (result[property].length > 100 ? '...' : '');
                        }
                      }
                    });

                    if (result.channels) {
                      result.channels.forEach((channel) => {
                        // check if the excerpt is set
                        if (channel.upperHtmlExcerpt) {
                          // count it
                          ++result.numberOfCustomizations;
                        } else if (channel.upperHtml) {
                          // fall the excerpt back to the original value truncated to 100 characters
                          channel.upperHtmlExcerpt =
                            encodeHtml(channel.upperHtml.substring(0, 100)) +
                            (channel.upperHtml.length > 100 ? '...' : '');
                        }
                        // check if the excerpt is set
                        if (channel.lowerHtmlExcerpt) {
                          // count it
                          ++result.numberOfCustomizations;
                        } else if (channel.lowerHtml) {
                          // fall the excerpt back to the original value truncated to 100 characters
                          channel.lowerHtmlExcerpt =
                            encodeHtml(channel.lowerHtml.substring(0, 100)) +
                            (channel.lowerHtml.length > 100 ? '...' : '');
                        }
                      });
                    }
                  });

                  return results;
                })
              );
          }),
          catchError((err, caught) => {
            console.error(err);

            this.snackbar
              .open('Unable to load customizations', 'TRY AGAIN')
              .then((ref) => ref.onAction().subscribe(() => caught));

            return of([]);
          }),
          tap((results) => {
            const hasResults = results.length > 0;
            this.hasResults$.next(hasResults);

            this.loading$.next(false);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((results) => {
          this.results$.next(results);
        });
    });
  }

  updateCustomization(campaignId: number, code: string, url: string): void {
    this.http
      .put(`/api/campaigns/${campaignId}/${url}`, JSON.stringify(code), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .subscribe(() => {
        this.snackbar.open('Code updated');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  clearQuery(): void {
    this.query.setValue('');
  }
}
