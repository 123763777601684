<!-- user-list.component.html -->
<div class="user-list-container">
  <div class="search-container fx-layout-row fx-layout-align-start-center">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="search-input">
      <mat-label>Search users...</mat-label>
      <input matInput [formControl]="query" />

      @if (query.value) {
        <button mat-button *ngIf="query.value" matSuffix mat-icon-button (click)="clearSearch()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      } @else {
        <mat-icon matSuffix svgIcon="magnify"></mat-icon>
      }
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Start index</mat-label>
      <input matInput type="number" [formControl]="skipIndex" />
    </mat-form-field>
    <mat-slide-toggle [formControl]="showSpam">Show spam</mat-slide-toggle>
    <mat-slide-toggle [formControl]="hasBackground">Has Cvr</mat-slide-toggle>
    <mat-slide-toggle [formControl]="hasImage">Has Prf</mat-slide-toggle>
  </div>

  <cdk-virtual-scroll-viewport
    class="user-list"
    [itemSize]="200"
    [minBufferPx]="400"
    [maxBufferPx]="800"
    (scrolled)="onScroll()"
  >
    @if (users().length === 0 && !loading()) {
      <div class="no-results">
        <mat-icon svgIcon="magnify"></mat-icon>
        <p>No users found</p>
      </div>
    } @else {
      <div
        class="user-card-row fx-layout-row fx-layout-align-start-center"
        [class.is-spam]="user.isSpam"
        *cdkVirtualFor="let user of users()"
      >
        <div class="user-card">
          <ih-image class="cover-image" [imageInfo]="user.backgroundImage" [visible]="true"></ih-image>

          <div class="user-info">
            <div class="profile-image">
              <ih-image [imageInfo]="user.image" [aspectRatio]="1" [visible]="true"></ih-image>
            </div>

            <div class="user-details">
              <h3 class="full-name">{{ user.fullName }}</h3>
              <div class="job-title">{{ user.jobTitle || 'N/A' }}</div>
              <div class="email">{{ user.email }}</div>
            </div>
          </div>
        </div>

        <div class="user-metadata fx-flex">
          <div class="user-metadata-inner fx-layout-row">
            <div class="labels">
              <div class="business-name-label">Hub:</div>
              <div class="about-label">About:</div>
            </div>
            <div class="values">
              <div class="business-name">
                <a href="{{ user.slug }}.ihub.app" target="_blank" class="fx-layout-row fx-layout-align-start-center">
                  <span [class.no-business-name]="!user.businessName">{{ user.businessName || 'N/A' }}</span>
                  <span>&nbsp;({{ user.slug }})</span>
                  <mat-icon svgIcon="open-in-new"></mat-icon>
                </a>
              </div>
              <div class="about" [attr.title]="user.about">
                {{ user.about || 'N/A' | slice: 0 : 150 }}
                @if (user.about?.length > 200) {
                  <span class="about">...</span>
                }
              </div>
            </div>
          </div>
          <div class="fx-layout-row fx-layout-align-end">
            <button
              mat-flat-button
              [color]="user.isSpam ? 'primary' : 'warn'"
              class="toggle-spam-button"
              (click)="toggleSpamUser(user)"
            >
              @if (user.isSpam) {
                <mat-icon svgIcon="flag-off"></mat-icon>
              } @else {
                <mat-icon svgIcon="flag"></mat-icon>
              }
              <span>{{ user.isSpam ? 'Unmark as spam' : 'Mark as spam' }}</span>
            </button>
          </div>
        </div>
      </div>
    }

    @if (loading()) {
      <div class="loading-spinner">
        <mat-spinner diameter="32"></mat-spinner>
      </div>
    }
  </cdk-virtual-scroll-viewport>
</div>
