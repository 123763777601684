<section class="search-customizations">
  <header class="search-customizations-header bridge-header sticky-header fx-layout-row fx-layout-align-start-center">
    <mat-icon class="search-customizations-icon">
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
        />
      </svg>
    </mat-icon>
    <div class="search-customizations-title fx-layout-row">Edit Customizations</div>
  </header>

  <!-- search for customizations -->
  <div class="search-customizations-container fx-layout-column">
    <div
      class="search-customizations-search-container fx-layout-row fx-layout-align-start-center"
      [class.has-results]="hasResults$ | async"
    >
      <mat-form-field class="fx-flex">
        <input matInput type="search" [formControl]="query" placeholder="Search..." />
        <button [disabled]="!query.value" mat-icon-button matSuffix (click)="clearQuery()">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </mat-icon>
        </button>
      </mat-form-field>
      <button [disabled]="!(hasResults$ | async)" class="refresh-btn" mat-icon-button (click)="refresh$.next()">
        <mat-icon>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z"
            />
          </svg>
        </mat-icon>
      </button>
    </div>

    <ng-container *ngIf="loading$ | async">
      <div class="loading-container fx-layout-row fx-layout-align-center-center">
        <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
      </div>
    </ng-container>

    <!-- Search customization results -->
    <mat-accordion>
      <mat-expansion-panel class="{{ app.slug }}" *ngFor="let app of results$ | async">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ app.hubName }} ({{ app.slug }})
            <a class="open-app" mat-icon-button [href]="app.url" target="_blank">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"
                  />
                </svg>
              </mat-icon>
            </a>
          </mat-panel-title>
          <mat-panel-description class="fx-layout-align-end-center fx-flex">
            <mat-chip-listbox>
              <mat-chip-option class="app-info-chip inactive-app-flag" *ngIf="!app.isActive">Inactive</mat-chip-option>
              <mat-chip-option class="app-info-chip archived-app-flag" *ngIf="app.isArchived">Archived</mat-chip-option>
              <mat-chip-option class="app-info-chip demo-app-flag" *ngIf="app.isDemo">Demo</mat-chip-option>
              <mat-chip-option class="app-info-chip private-app-flag" *ngIf="app.isPrivate">Private</mat-chip-option>
              <mat-chip-option class="app-info-chip test-app-flag" *ngIf="app.isTestApp">Test app</mat-chip-option>
            </mat-chip-listbox>
            <span class="match-label"> Matches:&nbsp;</span>
            <span class="match-count">{{ app.numberOfCustomizations }}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div *ngFor="let code of codeProperties">
            <ng-container
              *ngIf="code.type === 'channels' && app.channels && app.channels.length > 0; else singleCustomization"
            >
              <ng-container *ngFor="let channel of app.channels; index as i">
                <div mat-subheader>
                  Channel:
                  <strong
                    >{{ channel.name }} <span *ngIf="channel.handle">({{ channel.handle }})</span></strong
                  >
                </div>
                <div class="channel-match-container">
                  <div class="secondary upper-html" mat-subheader>UpperHTML</div>
                  <ih-customization-editor
                    class="upper-html-editor"
                    [excerpt]="app.channels[i].upperHtmlExcerpt"
                    [value]="app.channels[i].upperHtml"
                    [mode]="code.mode"
                    [placeholder]="code.placeholder"
                    (onSave)="code.update.upperHtml(app.campaignId, $event, channel.channelId)"
                  >
                  </ih-customization-editor>
                  <div class="secondary lower-html" mat-subheader>LowerHTML</div>
                  <ih-customization-editor
                    class="lower-html-editor"
                    [excerpt]="app.channels[i].lowerHtmlExcerpt"
                    [value]="app.channels[i].lowerHtml"
                    [mode]="code.mode"
                    [placeholder]="code.placeholder"
                    (onSave)="code.update.lowerHtml(app.campaignId, $event, channel.channelId)"
                  >
                  </ih-customization-editor>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #singleCustomization>
              <ng-container *ngIf="code.type !== 'channels'">
                <div mat-subheader>
                  {{ code.prettyName }}
                </div>
                <ih-customization-editor
                  class="{{ code.type }}-editor"
                  [excerpt]="getPropertyValue(app, code.type + 'Excerpt')"
                  [value]="getPropertyValue(app, code.type)"
                  [mode]="code.mode"
                  [placeholder]="code.placeholder"
                  (onSave)="code.update(app.campaignId, $event)"
                >
                </ih-customization-editor>
              </ng-container>
            </ng-template>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
